<div class="collector-list">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ collectorListData.infoType }} update/s for {{ dCollector }}( v{{
          collectorListData.version
          }}
          )
        </h5>
        <button type="button" class="close" (click)="closeCollectorListModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Site Name</th>
              <th scope="col">Host</th>
              <th scope="col">Host IP</th>
              <th scope="col">{{ dCollector }} Version</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of collectorList">
              <td>{{ c.site_name }}</td>
              <td>{{ c.host_name }}</td>
              <td>{{ c.ip_address }}</td>
              <td>{{ c.version }}</td>
              <td>
                <div [ngClass]="{
                    'status-red': c.status === -1,
                    'status-grey': c.status === 0,
                    'status-green': c.status === 1
                  }"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>