<div class="card-detail-bm info-details-components-container">
  <ng-container *ngIf="siteInformation && siteInformation[0]">
    <h4 class="card-title">{{ siteInformation[0]?.company_name }}</h4>
    <p *ngIf="!siteInformation[0].address1" class="card-text">*No Address*</p>
    <p *ngIf="!siteInformation[0].city" class="card-text">*No Address*</p>
    <p *ngIf="siteInformation[0].address1" class="card-text">
      {{ siteInformation[0]?.address1 }}, {{ siteInformation[0]?.address2 }}
    </p>
    <p *ngIf="siteInformation[0].city" class="card-text">
      {{ siteInformation[0]?.city }}, {{ siteInformation[0]?.state }} {{ siteInformation[0]?.zip }}
    </p>
    <p class="card-text">
      Contact Name: {{ siteInformation[0]?.first_name }} {{ siteInformation[0]?.last_name }}
    </p>
    <p class="card-text">
      Contact Phone:
      {{
        siteInformation[0] && siteInformation[0].phone
          ? siteInformation[0]?.phone.replace('$$', '').replace('$$', '')
          : ''
      }}
    </p>
    <p class="card-text">email: {{ siteInformation[0]?.email }}</p>
  </ng-container>
</div>
