import { Component, OnInit, Input } from '@angular/core';
import { PopupTypeService } from '../../../services/popupType.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-info-details',
  templateUrl: './info-details.component.html',
  styleUrls: ['./info-details.component.scss'],
})
export class InfoDetailsComponent implements OnInit {
  @Input() siteInformation: any;

  constructor(private ds: PopupTypeService) {}
  server_url: string = environment.config.serverUrl;

  ngOnInit() {
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);

    this.siteInformation[0] && this.siteInformation[0].phone
      ? this.siteInformation[0].phone.replace(/\$/g, '')
      : '';
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
    console.log(p);
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }
}
