import { Component, OnInit, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'app-table-qos-icon',
  templateUrl: './table-qos-icon.component.html',
  styleUrls: ['./table-qos-icon.component.scss']
})
export class TableQosIconComponent implements ViewCell, OnInit {

  renderValue: any;

  @Input() value: string | number;
  @Input() rowData: any;
  
  ngOnInit() {
    this.renderValue = this.value;
  }

}
