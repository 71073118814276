import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileTypeIcon',
})
export class FileTypeIconPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const iconFolder = '../assets/img/document_center/';

    if (value === 'doc' || value === 'docx') {
      return iconFolder + 'doc.png';
    }
    if (value === 'pdf') {
      return iconFolder + 'pdf.png';
    }
    if (value === 'ppt' || value === 'pptx') {
      return iconFolder + 'ppt.png';
    }
    if (value === 'xls' || value === 'xlsx') {
      return iconFolder + 'xls.png';
    }
    return iconFolder + 'file.png';
  }
}
