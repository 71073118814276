import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GetAppManagerService } from '../../services/get-app-manager.service';

@Component({
  selector: 'app-desktop-plugin-version-list',
  templateUrl: './desktop-plugin-version-list.component.html',
  styleUrls: ['./desktop-plugin-version-list.component.scss'],
})
export class DesktopPluginVersionListComponent implements OnInit {
  @Input() currentUserInfo: any;
  desktopPlugin: String = environment.config.dynamicText.desktopPlugin;
  desktopPluginImg: String = environment.config.dynamicImage.desktopPluginImg;
  zoomDesktopPluginVersionList: any;
  isOpenReleaseNotes = false;
  isOpenZoomDesktopPluginList = false;
  isOpenScheduleUpdate = false;
  rNotes: any;
  scheduleUpdateData: any;
  zoomDesktopPluginListData: any;

  constructor(private appManager: GetAppManagerService) { }

  ngOnInit() {
    this.getZoomDesktopPluginVersionList();
  }

  getZoomDesktopPluginVersionList = () => {
    this.zoomDesktopPluginVersionList = [];
    this.appManager.getZoomDesktopPluginVersionList().subscribe((data) => {
      if (data) {
        console.log('getZoomDesktopPluginVersionList: ', data)
        this.zoomDesktopPluginVersionList = data;
      }
    });
  };

  openReleaseNotes = (pVersion) => {
    const obj = {
      plugin_version_id: pVersion.desktop_plugin_version_id,
      release_notes: pVersion.release_notes,
      version: pVersion.version,
    };
    this.rNotes = obj;
    this.isOpenReleaseNotes = true;
  };

  openPluginList = (pCount, version, infoType) => {
    if (pCount > 0) {
      this.isOpenZoomDesktopPluginList = true;
      this.zoomDesktopPluginListData = {
        infoType,
        version,
      };
    }
  };

  ngOnChange = ($event) => {
    if ($event === 'closeZoomDesktopPluginReleaseNotes') {
      this.isOpenReleaseNotes = false;
      return;
    }

    if ($event === 'closeZoomDesktopPluginScheduleUpdate') {
      this.isOpenScheduleUpdate = false;
      return;
    }

    if ($event === 'closeZoomDesktopPluginList') {
      this.isOpenZoomDesktopPluginList = false;
      return;
    }

    if ($event === 'getZoomDesktopPluginVersionList') {
      this.getZoomDesktopPluginVersionList();
      return;
    }
  };

  openScheduleUpdate = (pluginVersion) => {
    const obj = {
      email_notes: pluginVersion.email_notes,
      include_release_notes: pluginVersion.include_release_notes,
      version: pluginVersion.version,
      update_schedule: pluginVersion.update_schedule,
    };
    this.scheduleUpdateData = obj;
    this.isOpenScheduleUpdate = true;
  };

  isShowUpdatePluginBtn = (status) => {
    if (status === 'COMPLETED') {
      return false;
    }
    return true;
  };

  getUpdateScheduleBtnLabel = (scheduleUpdate) => {
    return scheduleUpdate === null ? 'Schedule Update' : 'Reschedule Update';
  };
}
