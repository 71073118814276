import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wholeNumber'
})
export class WholeNumberPipe implements PipeTransform {

  transform(value: number): any {
    return Math.trunc(value);
  }

}