import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { GetAppManagerService } from '../../services/get-app-manager.service';
import { JwtHelperService } from '@auth0/angular-jwt';
@Component({
  selector: 'app-update-center',
  templateUrl: './update-center.component.html',
  styleUrls: ['./update-center.component.scss'],
})
export class UpdateCenterComponent implements OnInit {
  activeCollectorCount: number = 0;
  activeMacCollectorCount: number = 0;
  activeZoomPluginCount: number = 0;
  activeZoomDesktopPluginCount: number = 0;
  dCollector: String = environment.config.dynamicText.dCollector;
  dPlugin: String = environment.config.dynamicText.dPlugin;
  desktopPlugin: String = environment.config.dynamicText.desktopPlugin;
  collectorImg: String = environment.config.dynamicImage.collectorImg;
  pluginImg: String = environment.config.dynamicImage.pluginImg;
  desktopPluginImg: String = environment.config.dynamicImage.desktopPluginImg;
  updatePanel: number = 1;
  currentUserInfo: any;

  constructor(private Auth: AuthService, private appManager: GetAppManagerService) { }

  ngOnInit() {
    this.getActiveCollectorCount();
    this.getActiveZoomPluginCount();
    this.getActiveZoomDesktopPluginCount();
    this.getCurrentUserInfo();
  }


  // add two new parameters companyId, isMac
  getActiveCollectorCount = () => {
    this.Auth.getActiveCollectorCount().subscribe((data: any) => {
      const [res] = data.response;
      this.activeCollectorCount = res.active_collector_count;
      this.activeMacCollectorCount = res.mac_active_collector_count;
    });
  };

  //name incorrect because we have teams
  getActiveZoomPluginCount = () => {
    this.appManager.getActiveZoomPluginCount().subscribe((data: any) => {
  
      const [res] = data;
      this.activeZoomPluginCount = res.active_plugin_count;

    });
  };

  getActiveZoomDesktopPluginCount = () => {
    this.activeZoomDesktopPluginCount = 0;
    this.appManager.getActiveZoomDesktopPluginCount().subscribe((data: any) => {
      const [res] = data;
      this.activeZoomDesktopPluginCount = res.active_desktop_plugin_count;
    });
  };

  setUpdatePanel = (panel) => {
    this.updatePanel = panel;
  };

  getCurrentUserInfo = () => {
    var token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const userDetails = {
      company_id: decodedToken.company_id,
      user_id: decodedToken.user_id,
    };
    this.Auth.getCurrentUserInfo(userDetails).subscribe((data) => {
      this.currentUserInfo = data.response[0]
    });

  }
}
