import { Component, OnInit, Input, SimpleChanges, EventEmitter } from '@angular/core';
import * as moment from 'moment-timezone';
import { AuthService } from '../../../../app/services/auth.service';

@Component({
  selector: 'app-update-subscription-date',
  templateUrl: './update-subscription-date.component.html',
  styleUrls: ['./update-subscription-date.component.scss']
})
export class UpdateSubscriptionDateComponent implements OnInit {
  @Input() companyId: number;
  @Input() subscriptionInfo: any;
  @Input() loginType: any;
  @Input() refreshCompany: EventEmitter<any> = new EventEmitter<any>();
  @Input() currentUserInfo: any;

  isShowComponent: boolean = false;
  isEnableUpdate: boolean = false;
  oldStartDate: any;
  startDateChanges: any;
  maxDate: any;
  minDate: any;

  constructor(private Auth: AuthService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isShowComponent = false;
    if (this.subscriptionInfo && this.companyId) {
      this.oldStartDate = this.subscriptionInfo.old_start_date;
      this.startDateChanges = this.subscriptionInfo.start_date_changes ? this.subscriptionInfo.start_date_changes : 0;
      this.isShowComponent = this.loginType === 'superAdmin';
      this.isEnableUpdate = this.startDateChanges === 0;

      if (this.isEnableUpdate) {
        this.maxDate = moment().format('YYYY-MM-DD');
        this.minDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
      }
    }
  }

  updateStartDate() {
    if (this.oldStartDate) {
      this.Auth.updateCompanyStartDate(this.companyId, this.oldStartDate).subscribe((data) => {
        this.refreshCompany.emit('refreshCompanyInfo');
      });
    }
    else {
      this.refreshCompany.emit('refreshCompanyInfo');
    }
  }

}
