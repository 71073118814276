import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentCenterService {
  getUrlPath: Function = environment.apiServicePathBuilder('document-center');
  // getUrlPath: Function = environment.apiServicePathBuilder('dev');
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getDocumentsByCompany(body) {
    const url: string = this.getUrlPath(`getDocumentsByCompany/${body.companyId}/${body.root_id}/${body.isFiles}/${body.offset}`);
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  setCompanyStorageLimit(company_id, storage_limit) {
    const url: string = this.getUrlPath('setCompanyStorageLimit');
    const params = {
      company_id,
      storage_limit,
    };

    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }
}
