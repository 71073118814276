import { Component, OnInit, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'app-table-health-icon',
  templateUrl: './table-health-icon.component.html',
  styleUrls: ['./table-health-icon.component.scss']
})
export class TableHealthIconComponent implements ViewCell, OnInit {
  renderValue: any;

  @Input() value: string | number;
  @Input() rowData: any;
  
  ngOnInit() {
    this.renderValue = this.value;
    console.log("this is the value for health icon: ", this.value)
  }

}
