import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-term-and-condition-modal',
  templateUrl: './term-and-condition-modal.component.html',
  styleUrls: ['./term-and-condition-modal.component.scss']
})

export class TermAndConditionModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter<void>();

  constructor() { }

  onCloseModal() {
    this.closeModal.emit();
  }

  ngOnInit(): void {
  }

}
