import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

export interface GAMToken {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  user_guid: string;
}

@Injectable({
  providedIn: 'root',
})
export class SSOService {
  getUrlPath: Function = environment.apiServicePathBuilder('ssogam');

  constructor(private http: HttpClient, private auth: AuthService) {}

  private generateRandomAlphanumericString(len) {
    const dec2hex = (dec) => dec.toString(16).padStart(2, '0');

    const arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
  }

  signIn(): void {
    const { enabled, GAMBaseUri, clientId, redirectURI } = environment.config['SSO'] || {};
    
    if (enabled) {
      const state = this.generateRandomAlphanumericString(30);
      localStorage.setItem('ssoGAMLoginState', state);
      const params = new HttpParams({
        fromObject: {
          oauth: 'auth',
          client_id: clientId,
          redirect_uri: redirectURI,
          scope: 'gam_user_data',
          state,
        },
      });
      window.location.href = `${GAMBaseUri}/signin?${params.toString()}`;
    }
  }

  getTokenFromGAMCode(code: string): Observable<string> {
    const url = this.getUrlPath(`accessUsingGam`);
    const options = {
      headers: new HttpHeaders({
        'Accept': 'text/plain, */*',
        'Content-Type': 'application/json',
      }),
      // responseType: 'text' as 'json',
    };

    return this.http.post<{ token: string; GAM_token: string }>(url, { code }, options).pipe(
      map((tokens: { token: string; GAM_token: string }) => {
        localStorage.setItem('GAMToken', tokens?.GAM_token);
        const data = { response: { token: tokens?.token } };
        this.auth.onSuccessfullLogin(data, true);
        return tokens?.token;
      })
      // catchError((err: string) => {
      //   return throwError(err);
      // })
    );
  }
}
