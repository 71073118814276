import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserInfoService } from '../services/user-info.service';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss'],
  // encapsulation: ViewEncapsulation.ShadowDom
})
export class TwoFactorAuthComponent implements OnInit {
  dashboardLogo = environment.config.dynamicImage.loginImage;
  errorMessage: string = '';
  retryAttemps: number = 0;
  message: string = 'Please check your email/phone for the authentication code.';
  showErrorMessage: boolean = false;
  disableResendButton: boolean = true;
  isBusy: boolean = false;
  code: string = '';
  setTimer: any;
  inputClass: string = 'form-control';

  constructor(
    private router: Router,
    private Auth: AuthService,
    private userInfo: UserInfoService
  ) {
    if (localStorage.getItem('retryAttempts')) {
      this.retryAttemps = parseInt(localStorage.getItem('retryAttempts'));
    }
    if (this.Auth.authOption) {
      const option = this.Auth.authOption === 'sms' ? 'phone' : 'email';
      this.message = `Please check your ${option} for the authentication code.`;
    }
    this.setTimer = setTimeout(() => {
      if (this.retryAttemps < 3) {
        this.disableResendButton = false;
      }
    }, 300000);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  }

  async cancel() {
    try {
      this.isBusy = true;
      localStorage.setItem('verified', 'false');
      // this.router.navigate(['login']);
      // localStorage.removeItem('token');
      // localStorage.setItem('keyDownload', '0');
      // localStorage.setItem('reloadSiteData', '0');
      // localStorage.setItem('showCongrats', '0');
      // localStorage.setItem('runTut', '0');
      // localStorage.setItem('successReg', '0');
      // localStorage.setItem('overLayMembersMove', '0');
      this.isBusy = false;
    } catch (e) {
      this.errorMessage = e;
      console.log(e);
    }
  }

  async resendCode() {
    try {
      this.isBusy = true;
      const token = localStorage.getItem('token');
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      const sendAddress = decodedToken.two_factor_auth_option === 'email' ? decodedToken.email : decodedToken.phone;
      await this.Auth.sendVerificationCode(environment.config.twilioServiceId, sendAddress, decodedToken.two_factor_auth_option);
      this.isBusy = false;
      this.disableResendButton = true;
      this.message = 'Resend Verification Successful. Please check your email again.';
      this.retryAttemps++;
      localStorage.setItem('retryAttempts', this.retryAttemps.toString());
    } catch (e) {
      this.isBusy = false;
      this.errorMessage = e;
      console.log(e);
    }
  }

  async verifyCode() {
    try {
      this.isBusy = true;
      const code = this.code;
      const token = localStorage.getItem('token');
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      const sendAddress = decodedToken.two_factor_auth_option === 'email' ? decodedToken.email : localStorage.getItem('phone');
      const response = await this.Auth.checkVerificationCode(environment.config.twilioServiceId, sendAddress, code);
      if (typeof response === 'string' && response === 'Verification Successful') {
        this.errorMessage = '';
        this.showErrorMessage = false;
        localStorage.setItem('verified', 'true');
        this.router.navigate(['/']);
        this.userInfo.init();
      } else {
        this.showErrorMessage = true;
        this.inputClass = 'form-control red-border'
        this.errorMessage = 'Invalid Code';
        localStorage.setItem('verified', 'false');
      }
      this.isBusy = false;
    } catch (e) {
      this.isBusy = false;
      this.errorMessage = e;
      console.log(e);
    }
    
  };
}