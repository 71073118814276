<div class="update-center">
  <div class="update-center-content" *ngIf="updatePanel === 1">
    <div class="update-center-info-msg">
      <p>
        This is the list of available updates. Push or Schedule a global update to all customers.
      </p>
      <p>The number of {{ dCollector }} installed globally are....</p>
      <p>{{ activeCollectorCount }}</p>
    </div>
    <app-collector-version-list [currentUserInfo]="currentUserInfo"></app-collector-version-list>
  </div>

  <div class="update-center-content" *ngIf="updatePanel === 2">
    <div class="update-center-info-msg">
      <p>
        This is the list of available updates. Push or Schedule a global update to all customers.
      </p>
      <p>The number of Mac {{ dCollector }} installed globally are....</p>
      <p>{{ activeMacCollectorCount }}</p>
    </div>
    <app-mac-collector-version-list [currentUserInfo]="currentUserInfo"></app-mac-collector-version-list>
  </div>

  <div class="update-center-content" *ngIf="updatePanel === 3">
    <div class="update-center-info-msg">
      <p>
        This is the list of available updates. Push or Schedule a global update to all customers.
      </p>
      <p>The number of {{ dPlugin }} installed globally are....</p>
      <p>{{ activeZoomPluginCount }}</p>
      <app-plugin-version-list [currentUserInfo]="currentUserInfo"></app-plugin-version-list>
    </div>
  </div>

  <div class="update-center-content" *ngIf="updatePanel === 4">
    <div class="update-center-info-msg">
      <p>
        This is the list of available updates. Push or Schedule a global update to all customers.
      </p>`
      <p>The number of {{ desktopPlugin }} installed globally are....</p>
      <p>{{ activeZoomDesktopPluginCount }}</p>
      <app-desktop-plugin-version-list [currentUserInfo]="currentUserInfo"></app-desktop-plugin-version-list>
    </div>
  </div>

  <div class="update-center-menu">
    <div class="menu-collector" (click)="setUpdatePanel(1)">
      <img src="{{ collectorImg }}" />
      <p>{{ dCollector }}</p>
    </div>
    <div class="menu-collector" (click)="setUpdatePanel(2)">
      <img src="{{ collectorImg }}" />
      <p>Mac {{ dCollector }}</p>
    </div>

    <div class="menu-collector" (click)="setUpdatePanel(3)">
      <img src="{{ pluginImg }}" />
      <p>{{ dPlugin }}</p>
    </div>
    <div class="menu-collector" (click)="setUpdatePanel(4)">
      <img src="{{ desktopPluginImg }}" />
      <p>{{ desktopPlugin }}</p>
    </div>
  </div>
</div>