import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserInfoService } from '../services/user-info.service';



@Injectable({
  providedIn: 'root',
})
export class GetAppManagerService {
  getUrlPath: Function = environment.apiServicePathBuilder('app-manager');
  // getUrlPath: Function = environment.apiServicePathBuilder('dev');
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient, private userInfo: UserInfoService) { }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const { company_id } = helper.decodeToken(token);
    return company_id;
  };

  getZoomPluginVersionList() {
    const url: string = this.getUrlPath('getZoomPluginVersionList');
    const { loginType } = this.userInfo.getInfo();
    const company_id = loginType == 'superAdmin' ? -1 : this.getCompanyId()
    const params = {
      company_id
    };
    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  getZoomDesktopPluginVersionList() {
    const url: string = this.getUrlPath('getZoomDesktopPluginVersionList');
    const { loginType } = this.userInfo.getInfo();
    const company_id = loginType == 'superAdmin' ? -1 : this.getCompanyId()
    const params = {
      company_id
    };

    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  getActiveZoomPluginCount() {
    const { loginType } = this.userInfo.getInfo();
    const company_id = loginType == 'superAdmin' ? -1 : this.getCompanyId()
    const url: string = this.getUrlPath(`getActiveZoomPluginCount/${company_id}`);
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  getActiveZoomDesktopPluginCount() {
    const { loginType } = this.userInfo.getInfo();
    const company_id = loginType == 'superAdmin' ? -1 : this.getCompanyId()
    const url: string = this.getUrlPath(`getActiveZoomDesktopPluginCount/${company_id}`);
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  updateZoomPluginReleaseNotes(plugin_version_id, release_notes) {
    const url: string = this.getUrlPath('updateZoomPluginReleaseNotes');
    const params = {
      plugin_version_id,
      release_notes: release_notes.trim(),
    };

    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateZoomDesktopPluginReleaseNotes(plugin_version_id, release_notes) {
    const url: string = this.getUrlPath('updateZoomDesktopPluginReleaseNotes');
    const params = {
      plugin_version_id,
      release_notes: release_notes.trim(),
    };

    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateZoomPluginEmailNotes(version, email_notes, include_release_notes) {
    const url: string = this.getUrlPath('updateZoomPluginEmailNotes');
    const params = {
      version,
      email_notes: email_notes.trim(),
      include_release_notes,
    };

    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateZoomDesktopPluginEmailNotes(version, email_notes, include_release_notes) {
    const url: string = this.getUrlPath('updateZoomDesktopPluginEmailNotes');
    const params = {
      version,
      email_notes: email_notes.trim(),
      include_release_notes,
    };

    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  getZoomPluginUpdateInstalledList(version) {
    const { loginType } = this.userInfo.getInfo();


    const company_id = loginType == 'superAdmin' ? -1 : this.getCompanyId()
    const url: string = this.getUrlPath(`getZoomPluginUpdateInstalledList`);
    const params = {
      version,
      company_id
    };

    console.log('getZoomPluginUpdateInstalledList params: ', params)


    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  getZoomDesktopPluginUpdateInstalledList(version) {

    const { loginType } = this.userInfo.getInfo();


    const company_id = loginType == 'superAdmin' ? '-1' : this.getCompanyId()
    const url: string = this.getUrlPath(`getZoomDesktopPluginUpdateInstalledList`);
    const params = {
      version,
      company_id
    };
    console.log('getZoomDesktopPluginUpdateInstalledList params: ', params)


    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  //https://cxdetect-api.visibility.one/app-manager/getZoomDesktopPluginUpdateInstalledList


  getZoomDesktopPluginUpdatePendingList(version) {
    const url: string = this.getUrlPath(`getZoomDesktopPluginUpdatePendingList`);
    const { loginType } = this.userInfo.getInfo();

    const company_id = loginType == 'superAdmin' ? '-1' : this.getCompanyId()

    const params = {
      version,
      company_id
    };
    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
    // return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  getZoomPluginUpdatePendingList(version) {

    const url: string = this.getUrlPath(`getZoomPluginUpdatePendingList`);
    const { loginType } = this.userInfo.getInfo();

    const company_id = loginType == 'superAdmin' ? '-1' : this.getCompanyId()

    const params = {
      version,
      company_id
    };
    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }


  getZoomPluginUpdateFailedList(version) {
    const url: string = this.getUrlPath(`getZoomPluginUpdateFailedList`);
    const { loginType } = this.userInfo.getInfo();

    const company_id = loginType == 'superAdmin' ? '-1' : this.getCompanyId()

    const params = {
      version,
      company_id
    };
    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));

  }

  getZoomDesktopPluginUpdateFailedList(version) {
    const url: string = this.getUrlPath(`getZoomDesktopPluginUpdateFailedList`);
    const { loginType } = this.userInfo.getInfo();

    const company_id = loginType == 'superAdmin' ? '-1' : this.getCompanyId()

    const params = {
      version,
      company_id
    };
    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  sendTestEmailZoomPluginVersionUpdate(
    email,
    email_notes,
    include_release_notes,
    update_schedule,
    version
  ) {
    const url: string = this.getUrlPath('sendTestEmailZoomPluginVersionUpdate');
    const params = {
      email,
      email_notes: email_notes.trim(),
      include_release_notes,
      update_schedule,
      version,
    };

    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  sendZoomDesktopPluginTestEmail(
    email,
    email_notes,
    include_release_notes,
    update_schedule,
    version
  ) {
    const url: string = this.getUrlPath('sendZoomDesktopPluginTestEmail');
    const params = {
      email,
      email_notes: email_notes.trim(),
      include_release_notes,
      update_schedule,
      version,
    };

    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  setZoomPluginVersionUpdateSchedule(version, update_schedule) {
    const url: string = this.getUrlPath('setZoomPluginVersionUpdateSchedule');
    const params = {
      version,
      update_schedule,
    };

    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }

  setZoomDesktopPluginVersionUpdateSchedule(version, update_schedule) {
    const url: string = this.getUrlPath('setZoomDesktopPluginVersionUpdateSchedule');
    const params = {
      version,
      update_schedule,
    };

    return this.http.post<any>(url, params, this.httpOptions).pipe(catchError(this.handleError));
  }
}
