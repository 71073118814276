import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  @Input() loginUser: (e) => any;
  sentRequest: any = false;
  resetResponseData: any;
  links = environment.config.links;
  loginImage = environment.config.dynamicImage.loginImage;
  bgImage = environment.config.dynamicImage.bgImage;
  dTerms = environment.config.dynamicText.dTerms;
  showPoweredBy = environment.config.showPoweredBy;
  tenantAdmin = environment.config.tenantName
  isAdminAccess: boolean = false;
  messageError: string = '';

  constructor(
    private Auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private fb: FormBuilder
  ) {
    this.control = fb.control({ value: 'my val', disabled: true });
  }

  control: FormControl;
  forgetForm: FormGroup;
  submitted = false;
  model: any = {};
  // convenience getter for easy access to form fields
  showModal = false;
  get f() {
    return this.forgetForm.controls;
  }

  ngOnInit() {
    this.checkForm();
  }

  openModal = () => {
    // this.showModal = true;
    if (this.tenantAdmin == 'CxDetect') {
      this.showModal = true;
    } else {
      window.open(`${this.links.terms}`, "_blank");
    }
  }

  routeToHomePage = () => {
    this.router.navigate(['login']);

  }


  checkForm() {
    this.forgetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
    });
  }
  onSubmit() {
    (<HTMLInputElement>document.getElementById('feedback')).innerHTML = '';
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgetForm.invalid) {
      return;
    } else {
      this.resetPassword();
    }
  }
  resetPassword() {
    const userEmail = (<HTMLInputElement>document.getElementById('email')).value;
    const resetData = {
      data: {
        email: (<HTMLInputElement>document.getElementById('email')).value,
      },
    };

    console.log(userEmail);

    this.Auth.sendPasswordReset(userEmail).subscribe((data) => {
      let status = data['status'];
      if (status == 'error') {
        this.isAdminAccess = true;
        this.messageError = data['message'];
        (<HTMLInputElement>document.getElementById('feedback')).innerHTML = this.messageError;
      } else {
        this.sentRequest = true;
        this.resetResponseData = data;
      }
    });
  }
}
