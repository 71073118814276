<!--The content below is only a placeholder and can be replaced.-->
<div *ngIf="authLoggedon == true">
  <!-- <app-main-menu [runTutorial]="runTutorial"></app-main-menu> -->
  <div id="main-overlay" class="tut-show1" *ngIf="runTutorial == 1"></div>
  <app-popup-container [runTutorial]="runTutorial"></app-popup-container>
  <!-- SEARCH THIS  -->
  <!-- <div
    [class.fullscreen]="loginClass == 'fullscreen'"
    class="col float-right rt-panel"
    id="rt-panel"
    style="visibility: hidden;"
    [@scaleHideShow]="ScaleRouterOutletState"
  >
    <router-outlet></router-outlet>
  </div> -->
  <!-- SEARCH THIS  -->

  <router-outlet></router-outlet>
</div>
<div *ngIf="authLoggedon != true">
  <router-outlet></router-outlet>
</div>
<notifier-container></notifier-container>
