import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private info:any;

  constructor() { 
    this.init();
  }

  init(){
    const helper = new JwtHelperService();
    const token = localStorage.getItem('token');
    const decodedToken = helper.decodeToken(token);
    this.info = {... decodedToken };

  }

  getInfo():any{
    return this.info;
  }
}
