<div class="desktop-plugin-list">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ pluginListData.infoType }} update/s for {{ desktopPlugin }}( v{{
          pluginListData.version
          }}
          )
        </h5>
        <button type="button" class="close" (click)="closePluginListModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <!-- <th scope="col">Room Name</th> -->
              <th scope="col">Host</th>
              <th scope="col">Host IP</th>
              <th scope="col">{{ desktopPlugin }} Version</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of pluginList">
              <!-- <td>{{ p.room_name | displayEmpty }}</td> -->
              <td>{{ p.host_name | displayEmpty }}</td>
              <td>{{ p.ip_address | displayEmpty }}</td>
              <td>{{ p.version | displayEmpty }}</td>
              <td>
                <div [ngClass]="{
                    'status-red': p.status === -1,
                    'status-grey': p.status === 0,
                    'status-green': p.status === 1
                  }"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>