import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'app-domotz-dialog',
  templateUrl: './domotz-dialog.component.html',
  styleUrls: ['./domotz-dialog.component.scss'],
})
export class DomotzDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DomotzDialogComponent>
  ) {}

  ngOnInit(): void {}

  onClose = () => {
    this.dialogRef.close();
  };

  onYes() {
    this.dialogRef.close(true);
  }
}
