<div class="collector-version-release-notes">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Release Notes( v{{ rNotes.version }} )</h5>
        <button type="button" class="close" (click)="closeReleaseNotesModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <textarea (change)="keyup($event.target.value)" placeholder="Textarea" class="text-area" [disabled]="!showBtn">{{ this.releaseNotes }}
        </textarea>

        <!-- <swal #deleteSwal text="Success" icon="success"> </swal> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" *ngIf="showBtn" (click)="updateReleaseNotes()">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>