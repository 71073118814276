import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-domotz-setup',
  templateUrl: './domotz-setup.component.html',
  styleUrls: ['./domotz-setup.component.scss']
})
export class DomotzSetupComponent implements OnInit {

  @Input() companyId: any;
  @Input() currentUserInfo: any;

  activePanel: Number = 1;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  selectActivePanel = (opt) => (this.activePanel = opt);

}
