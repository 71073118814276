import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { environment } from './../../../environments/environment';
import { UserInfoService } from '../../services/user-info.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent implements OnInit {
  @Output() mainMenuEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() headerTitle: string;
  loginType: string;
  userId: number;
  showUpdateCenter: Boolean = false;

  constructor(private Auth: AuthService, private userInfo: UserInfoService, private router: Router) { }

  ngOnInit() {
    this.loginType = this.userInfo.getInfo().loginType;
    this.userId = this.userInfo.getInfo().user_id;
    this.getColletorUpdateStatus();
  }

  loginAsSuperAdmin() {
    this.Auth.superAdminViewUserAs().subscribe((data) => {
      if (data['status'] == 200) {
        const newWindow = window.open(
          environment.config.mainUrl +
          '/tokenloginadmin/' +
          data.response[0].token +
          '/' +
          this.loginType
        );
        newWindow.window.location.reload(true);
      }
    });
  }

  getColletorUpdateStatus = () => {
    this.Auth.getColletorUpdateStatus().subscribe((data) => {
      if (data.response) {
        const { count } = data.response[0];
        this.showUpdateCenter = count !== 0;
      }
    });
  };

  getLoginAsLabel(): string {
    return this.loginType === 'integrator'
      ? 'Integrator Dashboard'
      : environment.config.dynamicText.dashboardHeader;
  }

  openUpdateCenter = () => {
    // this.mainMenuEvent.emit({ activeMenu: 2 });
    this.router.navigate(['updatecenter']);
  };
}
