import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableFilterService {

  private _listners = new Subject<any>();

  listen(): Observable<any> {
     return this._listners.asObservable();
  }

  filter(filterBy: any) {
    console.log('this is the filter service ' + filterBy)
     this._listners.next(filterBy);
  }

  
}
