import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserInfoService } from '../../services/user-info.service';

@Component({
  selector: 'app-collector-list',
  templateUrl: './collector-list.component.html',
  styleUrls: ['./collector-list.component.scss'],
})

export class CollectorListComponent implements OnInit {
  @Output() collectorListEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() collectorListData: any;
  dCollector: String = environment.config.dynamicText.dCollector;
  collectorList: any;

  constructor(private Auth: AuthService,
    private userInfo: UserInfoService,
  ) { }

  ngOnInit() {
    this.setCollectorList();
  }

  closeCollectorListModal() {
    this.collectorListEvent.emit('closeCollectorList');
  }

  setCollectorList() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const { loginType, company_id } = this.userInfo.getInfo();

    this.collectorList = [];

    if (this.collectorListData.infoType === 'Pending') {
    
      this.Auth.getCollectorUpdatePendingList(this.collectorListData.version, company_id).subscribe(
        (result) => {
          if (result.status === 200) {
            console.log('getCollectorUpdatePendingList: ', result)
            this.collectorList = result.response;
          }
        }
      );
    }

    if (this.collectorListData.infoType === 'Error') {

      this.Auth.getCollectorUpdateActionFailedList(this.collectorListData.version, company_id).subscribe(
        (data: any) => {
          const res = data.response;
          console.log('res: ', res)
          this.collectorList = res;
        }
      );
    }

    if (this.collectorListData.infoType === 'Installed') {

      this.Auth.getCollectorUpdateInstalledList(this.collectorListData.version, company_id).subscribe(
        (result) => {
          console.log('getCollectorUpdateInstalledList: ', result)

          if (result.status === 200) {
            this.collectorList = result.response;
          }
        }
      );
    }
  }
}
