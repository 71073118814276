<!-- DELETE SECTION -->
<div class="agent-warning-dialog">
  <div class="agent-warning-dialog__body">
    <div class="agent-warning-dialog__image d-flex align-items-center">
      <!-- <img  src="../../../../assets/img/gbl_img/delete-icon.png" alt="" /> -->
      <!-- <img *ngIf="isLoading" src="{{ loader }}" alt="" /> -->
      <i class="fa fa-exclamation-circle fa-5x p-4" aria-hidden="true"></i>
    </div>
    <div class="agent-warning-dialog__text-button-container">
      <div class="">
        There is an agent linked to this child company. Please unlink the agent before deleting the
        child company
      </div>
      <div class="agent-warning-dialog__button-container">
        <div
          class="agent-warning-dialog__button-item agent-warning-dialog__button-item--yes"
          (click)="closeDialog()"
        >
          Okay
        </div>
      </div>
    </div>
  </div>
</div>
