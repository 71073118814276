<div class="domotz-link-agent">
    <input (keyup)="searchData($event.target.value)" placeholder="Search..." class="input-class" />

    <div class="mat-elevation-z8 child mat-table">
        <table class="data-table" mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="agent_name">
                <th mat-header-cell *matHeaderCellDef><strong>Agent Name</strong></th>
                <td mat-cell *matCellDef="let row">
                    {{ row.agent_name | displayEmpty }}
                </td>
            </ng-container>

            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef><strong>Company</strong></th>
                <td mat-cell *matCellDef="let row">
                    {{ row.company_name !== null ? row.company_name : '--' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="ip">
                <th mat-header-cell *matHeaderCellDef><strong>IP</strong></th>
                <td mat-cell *matCellDef="let row">
                    {{ row.ip | displayEmpty }}
                </td>
            </ng-container>

            <ng-container matColumnDef="mac">
                <th mat-header-cell *matHeaderCellDef><strong>MAC</strong></th>
                <td mat-cell *matCellDef="let row">
                    {{ row.mac | displayEmpty }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef><strong>Agent Status</strong></th>
                <td mat-cell *matCellDef="let row">
                    <div>
                        <div class="text-center {{ getStatusColor(row.status) }}"></div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="is_linked">
                <th mat-header-cell *matHeaderCellDef><strong>Link</strong></th>
                <td mat-cell *matCellDef="let row">
                    <div (click)="openLinkAgentToChildCompanyModal(row)">
                        <img src="{{getLinkIcon(row)}}">
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <div *ngIf="shouldShowLoaderBtn" class="loader-child">
        <img src="{{ loader }}" alt="" />
    </div>
</div>