import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../app/services/auth.service';
import { ListenerService } from '../../services/listener.service';

import { environment } from '../../../environments/environment';
import { forkJoin } from 'rxjs';
import { InputValidationService } from '../../services/input-validation.service';

@Component({
  selector: 'app-update-company',
  templateUrl: './update-company.component.html',
  styleUrls: ['./update-company.component.scss'],
})
export class UpdateCompanyComponent implements OnInit {
  @Input() companyInfo: any;
  @Input() refreshCompany: EventEmitter<any> = new EventEmitter<any>();

  @Output() infoSiteEmitter: EventEmitter<any> = new EventEmitter<any>();


  // infoSiteEmitter: EventEmitter<any>;

  @Input() currentUserInfo: any;

  allCountryListData: any = [];
  allStatesListData: any = [];
  companyUserList: any = [];
  loader: any = environment.config.dynamicImage.loader;
  isLoading: boolean = false;
  isCancel: boolean = false;
  primaryContact: any = {};
  addAdditionalDomain: any = null;
  additionalDomainHasErrors: boolean = false;
  additionalDomainValueChange: string = null;
  additionalDomainErrorMessage: string = '';
  country: any;
  submitted = false;

  companyForm = this.fbc.group({
    companyName: ['', [Validators.required, Validators.maxLength(30)]],
    address1: ['', [Validators.required, Validators.maxLength(50)]],
    address2: [''],
    city: ['', [Validators.required, Validators.maxLength(50)]],
    state: ['', [Validators.required, Validators.maxLength(50)]],
    zip: ['', [Validators.required, Validators.maxLength(11) , Validators.minLength(3), this.inputValidationService.postalCodeValidator]],
    country: [''],
    primaryContact: [''],
    compphonecode: [''],
    companyphone: ['', [Validators.required, Validators.maxLength(25), this.inputValidationService.customPhoneValidator]],
    companydomain: ['', Validators.required],
    additionalDomain: [''],
  });

  constructor(
    private fbc: FormBuilder,
    private Auth: AuthService,
    private listenRx: ListenerService,
    private inputValidationService: InputValidationService
  ) {

    this.listenRx.listen().subscribe((m: any) => {
      this.cancelUpdate();
    });
  }

  get fc() {
    return this.companyForm.controls;
  }

  ngOnInit() {
    // this.getCountryData();
  }

  setCountry(n) {
    this.getStatesData(n);
    const compphonecode = this.allCountryListData[n - 1].phonecode;
    this.companyForm.patchValue({
      compphonecode: compphonecode,
      zip: '',
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyInfo) {
      if (this.companyInfo) {
        this.setUpdateCompanyData();
      }
    }
  }

  setCompAcctFormValues() {
    // console.log(this.companyInfo, 'companyInfo')
    const { company_name, address1, address2, city, state, zip, country, phone, domain, additional_domain } = this.companyInfo;
    this.country = country;
    let compphonecode = null;
    let companyphone = null;
    if (phone) {
      let tmpDbCphn = this.companyInfo.phone;
      let spCPhnArr = tmpDbCphn.split('$$');
      compphonecode = spCPhnArr[1];
      companyphone = spCPhnArr[2];
    }
    console.log("additional domain: ", additional_domain)
    const compAcctFormValues = {
      companyName: company_name,
      address1,
      address2,
      city: city,
      state: state,
      zip: zip,
      country: country,
      compphonecode: compphonecode,
      companyphone: companyphone,
      companydomain: domain,
      primaryContact: this.primaryContact.user_id,
      additionalDomain: additional_domain === null ? null : additional_domain.replace(/,/g, ';'),
    };
    this.additionalDomainValueChange = compAcctFormValues.additionalDomain;
    console.log("Initial valuechange: ", this.additionalDomainValueChange)
    this.companyForm.patchValue(compAcctFormValues);
    console.log(this.companyForm)
  }
  onValueChange(additionalDomainValue: string): void {
    this.additionalDomainValueChange = additionalDomainValue;
    // console.log("onvaluechange: ", this.additionalDomainValueChange);
  }
  commaSepDomains = () => {
    // console.log("Validate before ", this.additionalDomainHasErrors)
    if (this.additionalDomainValueChange) {
      this.addAdditionalDomain = this.additionalDomainValueChange.split(';').map((e) => e.trim());
      // console.log("Inside if check what happens after ''", this.addAdditionalDomain)
      if (this.addAdditionalDomain.length > 4) {
        this.additionalDomainHasErrors = true;
        this.additionalDomainErrorMessage = 'Maximum of 4 Additional Domains Only';
      } else {
        this.additionalDomainHasErrors = false;
        console.log("Length is less than max")
        console.log(this.addAdditionalDomain)
        let strIndex;
        let flag = 0;
        for (let i = 0; i < this.addAdditionalDomain.length && flag == 0; i++) {
          strIndex = this.addAdditionalDomain[i].indexOf('.')
          console.log("testme ", strIndex)
          if (strIndex == -1) {
            console.log("i am -1")
            flag = 1;
          }
        }
        if (flag === 1) {
          this.additionalDomainHasErrors = true;
          this.additionalDomainErrorMessage = 'Please input a valid domain';
        }
      }
    } else {
      this.addAdditionalDomain = null;
      // console.log("add domain column is null", this.addAdditionalDomain)
    }
    // console.log("validate after ", this.additionalDomainHasErrors)
  };
  onSubmit() {
    if (!this.isCancel) {
      this.submitted = true;
      this.companyForm;
      this.companyForm.updateValueAndValidity;

      const hasErrors = this.companyForm.invalid;
      this.commaSepDomains();
      console.log("Onsubmit addAdditionalDomain", this.addAdditionalDomain)
      if (!hasErrors && !this.additionalDomainHasErrors) {
        console.log("It has no errors")
        this.isLoading = true;
        const {
          address1,
          address2,
          city,
          companyName,
          companyphone,
          compphonecode,
          country,
          primaryContact,
          state,
          zip,
          companydomain,
          domain
        } = this.companyForm.value;
        const compData = {
          data: {
            company_id: this.companyInfo.company_id,
            company_name: companyName,
            country,
            phone: '$$' + compphonecode + '$$' + companyphone,
            address1,
            address2,
            city,
            state,
            zip,
            domain: companydomain,
            primary_contact_id: primaryContact,
            is_domain_updated: 1,
            additionalDomain: this.addAdditionalDomain === null ? null : this.addAdditionalDomain.toString(),
          },
        };
        console.log("Compdata is: ", compData)
        this.Auth.updateCompany(compData).subscribe((data) => {
          this.isLoading = false;
          if (data.response[0].invalidStatus === "Error") {
            this.additionalDomainHasErrors = true;
            this.additionalDomainErrorMessage = data.response[0].domainSubStr + " is already a registered domain";
          } else {
            this.additionalDomainHasErrors = false;
          }
          console.log("data response is: ", data)


          this.infoSiteEmitter.emit('refreshCompany'); // close edit mode
          this.refreshCompany.emit('refreshCompanyInfo');
        });

      } else {
        // this.additionalDomainHasErrors = false;
        console.log("it has errors")
      }

    } else {
      this.refreshCompany.emit('refreshCompanyInfo');
    }

    console.log('here')
  }

  getStatesData(n) {
    this.isLoading = true;
    this.Auth.getAllStatesList(n).subscribe((data) => {
      this.allStatesListData = data['response'];
      this.isLoading = false;
    });
  }

  platFormAdmin: any;
  isDomainUpdated: any;

  setUpdateCompanyData() {
    this.isLoading = true;
    const { company_id, primary_contact_id, country, platform_admin, is_domain_updated } = this.companyInfo;
    const companyId = {
      company_id,
    };

    this.isDomainUpdated = is_domain_updated;
    this.platFormAdmin = platform_admin;

    const updateCompanyPromises = [];
    updateCompanyPromises.push(this.Auth.getUserList(companyId));
    if(this.allCountryListData.length === 0)
      updateCompanyPromises.push(this.Auth.getAllCountryList());

    forkJoin(updateCompanyPromises).subscribe((data) => {
      this.isLoading = false;
      this.companyUserList = data[0]['response'];
      this.allCountryListData =  data[1]? data[1]['response'] : this.allCountryListData;
      this.companyUserList = this.companyUserList.filter((u) => u.last_name !== null);

      this.getPrimaryContact(this.companyUserList, primary_contact_id);
      if(this.allStatesListData.length === 0)
        this.getStatesData(country);
      this.setCompAcctFormValues();
    });
  }

  getPrimaryContact(userList, primary_contact_id) {
    const uList = userList.sort(this.custom_sort);

    if (primary_contact_id === null) {
      return (this.primaryContact = { ...uList[0] });
    }

    if (primary_contact_id && primary_contact_id !== null) {
      this.primaryContact = { ...uList.filter((u) => u.user_id === primary_contact_id)[0] };
    }
  }

  custom_sort(a, b) {
    return new Date(a.create_date).getTime() - new Date(b.create_date).getTime();
  }

  cancelUpdate() {
    this.infoSiteEmitter.emit('refreshCompany');
  }
}
