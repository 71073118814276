import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService} from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(private auth: AuthService, public router: Router) {

  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      //Check to see if User has been authenitcated
      if (!this.auth.isAuthenticated()) {
        // If not authenitcated then re-routed back to login page
        console.log(this.auth.isAuthenticated());
        console.log("You need to be login to access this page-auth.guard")
        this.router.navigate(['adminlogin']);
        return false;
      }
      return true;
    }
    // return this.auth.isLoggedIn;
}
