import { BaseRequestOptions } from '@angular/http';
import { Injectable } from "@angular/core";

const AUTH_HEADER_KEY = 'Authorization';
const AUTH_PREFIX = 'Bearer';

@Injectable()
export class AuthRequestOptions extends BaseRequestOptions {
  constructor() {
    super();

    const token = localStorage.getItem('token');
    if (token) {
      this.headers.set(AUTH_HEADER_KEY, `${AUTH_PREFIX} ${token}`);
    }
  }
}
