import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
// import {
//   faUser, IconDefinition,
// } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-btn-info',
  templateUrl: './btn-info.component.html',
  styleUrls: ['./btn-info.component.scss']
})
export class BtnInfoComponent implements OnChanges {
  @Input() label: string = "Default";
  // @Input() faName: string = "user";
  @Input() isbutton: string;

  // @Input() faName: IconDefinition = faUser;
  
  // icon: any = faUser;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count) {
      // this.faName = changes.faName.currentValue
    }
    if (changes.total) {
      this.label = changes.label.currentValue
    }
  }

}
