<div class="vs-page-container">
  <section class="vs-page-layout">
    <div class="form-wrapper center">
      <img [src]="dashboardLogo" />
      <label class="vs-heading" for="authentication_code">Authentication Code: </label>
      <input
        [ngClass]="inputClass"
        id="authentication_code"
        name="authentication_code"
        [(ngModel)]="code"
      />
      <div>
        {{ message }}
        Note: Resend code button will be enabled every 5 minutes.
      </div>
      <div class="red-color" *ngIf="showErrorMessage">
        {{ errorMessage }}
      </div>
    </div>
    <div class="form-actions center">
      <button class="btn btn-primary" (click)="verifyCode()" [disabled]="isBusy">
        Verify Code
      </button>
      <button
        class="btn btn-secondary"
        (click)="resendCode()"
        [disabled]="isBusy || disableResendButton"
      >
        Resend Code
      </button>
      <button class="btn btn-cancel" (click)="cancel()" [disabled]="isBusy">Cancel</button>
    </div>
  </section>
</div>
