import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-agent-warning-dialog',
  templateUrl: './agent-warning-dialog.component.html',
  styleUrls: ['./agent-warning-dialog.component.scss'],
})
export class AgentWarningDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<AgentWarningDialogComponent>) {}

  ngOnInit(): void {}

  closeDialog = () => {
    this.dialogRef.close(true);
  };
}
