<div class="wrapper">
  <div class="progress" [class.removeClickable]="removeClickable<=0">
    <div class="progress-bar" [class.green]="color=='green'" [class.red]="color=='red'" [class.yellow]="color=='yellow'" [class.blue]="color=='blue'"
      [style.width]="val + '%'">
    </div>
  </div>
  <div class="dot dot-square" [class.green]="color=='green'" [class.red]="color=='red'" [class.yellow]="color=='yellow'" [class.blue]="color=='blue'">
    <div class="inner-dot inner-dot-square">
              <div id="label">
                  <span>{{ displayVal }}</span>
              </div>
          </div>
  </div>
</div>