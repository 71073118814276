import { build } from './../../../environments/build';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { MainMenuTypeService } from '../../services/mainmenuType.service';
import { MainMenuPopup } from '../../services/MainMenuPopup.animation';
import { MembersTypeService } from '../../services/membersType.service';
import { PopupOpenClose } from '../../services/popupContainer.animation';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  animations: [MainMenuPopup, PopupOpenClose],
})
export class MainMenuComponent implements OnInit {
  firstname: string;
  server_url: string = environment.config.serverUrl;
  logo: string;
  // logoURL: string = this.server_url + '/api/v1/images/companylogo/' + this.logo;
  topTitle: string;
  // topTitle: string = 'Hello...' + this.firstname + '!';
  @Output() mainmenuState: string = 'close';
  @Output() membersPopupState: string = 'close';
  @Input() title: string = 'Title';
  @Output() focusChange: EventEmitter<MatTabChangeEvent>;
  mainmenuType: number;
  popupType: number;
  subscription: Subscription;
  @Input() runTutorial: any;
  allSiteListData: any;
  allDeviceListData: any;
  runTut = localStorage.getItem('runTut');
  dev_server = environment.dev_server;
  buildVersion = build.version;
  buildStamp = build.stamp;
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;
  activeMenu = 'company';
  highlightNum: number = 1;
  activePanel: any;

  routeId: any;

  constructor(
    private ds: MembersTypeService,
    private dsM: MainMenuTypeService,
    public http: HttpClient,
    private router: Router,
    private Auth: AuthService,
    private route: ActivatedRoute
  ) {
    this.subscription = this.dsM.getMainMenuType().subscribe((x) => {
      this.mainmenuType = x;
      if (this.mainmenuType != null && this.mainmenuType != 0) {
        if (this.mainmenuType == 99) {
          this.mainmenuState = 'close';
          this.membersPopupState = 'close';
        } else {
          this.mainmenuState = 'open';
        }
      } else {
        this.mainmenuState = 'close';
        this.membersPopupState = 'close';
      } 0
    });
  }

  Logout() {
    this.Auth.logout()
  }

  // loginUser(event) {
  //   event.preventDefault();
  //   const target = event.target;
  //   const username = target.querySelector('#username').value;
  //   const password = target.querySelector('#password').value;
  //   this.Auth.getUserDetails(username, password).subscribe((data) => {
  //     localStorage.setItem('token', data.response.token);
  //     const token = localStorage.getItem('token');
  //     const helper = new JwtHelperService();
  //     const decodedToken = helper.decodeToken(token);
  //     const expirationDate = helper.getTokenExpirationDate(token);
  //     const isExpired = helper.isTokenExpired(token);
  //     this.firstname = decodedToken.firstname;
  //     this.logo = decodedToken.logo;

  //     if (!helper.isTokenExpired(token)) {
  //       // redirect to Admin homepage
  //       this.router.navigate(['video/allsites']);
  //       // this.Auth.setLoggedIn(true);
  //     }
  //   });
  // }

  ngOnInit() {
    this.ds.sendMemberType(99);
    var token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.topTitle = 'Hello...' + decodedToken.first_name + '!';
    this.logo = this.server_url + '/api/v1/images/companylogo/' + decodedToken.logo;
    this.mainmenuState = 'open';
  }

  setHighlight(n) {
    this.highlightNum = n;
  }

  activeMenuSet(routes) {
    this.activeMenu = routes;
    this.router.navigate([routes]);
  }
  sendMemberType(p) {
    this.activePanel = p;

    this.ds.sendMemberType(p);
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearMemberType();
  }

  clearMemberType() {
    // clear message
    this.ds.clearMemberType();
  }

  logoClicked() {
    this.activeMenuSet(1);
    this.sendMemberType(11);
  }

  onChangeEvent = ($event) => {
    if ($event.activeMenu) {
      this.activeMenu = $event.activeMenu;
    }
  };
}
