<div class="plugin-version-list">
  <app-plugin-version-release-notes *ngIf="isOpenReleaseNotes" (closeReleaseNotesEvent)="ngOnChange($event)"
    [rNotes]="rNotes">
  </app-plugin-version-release-notes>
  <app-plugin-update-schedule *ngIf="isOpenScheduleUpdate" [scheduleUpdateData]="scheduleUpdateData"
    (closePluginScheduleUpdateEvent)="ngOnChange($event)">
  </app-plugin-update-schedule>
  <app-plugin-list *ngIf="isOpenZoomPluginList" [pluginListData]="zoomPluginListData"
    (pluginListEvent)="ngOnChange($event)">
  </app-plugin-list>
  <div class="table-container">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">{{ dPlugin }} Version</th>
          <th scope="col">Release Date</th>
          <th scope="col">Size</th>
          <th scope="col">Release Notes</th>
          <th scope="col">Status</th>
          <th scope="col">Scheduled Date</th>
          <th scope="col">Installed</th>
          <th scope="col">Error</th>
          <th scope="col">Pending</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of cloudPluginVersionList">
          <td><img src="{{ pluginImg }}" /> Version {{ p.version }}</td>
          <td>
            <span *ngIf="p.stamp">
              {{ p.stamp | displayDate }} (Local)<br>
              {{ p.stamp | date : 'MM-dd-yyyy hh:mm a' :'UTC' }} (UTC)
            </span>
          </td>
          <td>{{ p.file_size | displayEmpty }}</td>
          <td (click)="openReleaseNotes(p)">
            <span class="view-notes">
              View Notes
            </span>
          </td>
          <td>{{ p.update_status | displayEmpty }}</td>
          <td>
            <span *ngIf="p.update_schedule">
              {{ p.update_schedule | displayDate }} (Local)<br>
              {{ p.update_schedule | date : 'MM-dd-yyyy hh:mm a' :'UTC' }} (UTC)
            </span>
          </td>
          <td (click)="openPluginList(p.installed_count, p.version, 'Installed')">
            <span class="view-plugins">{{ p.installed_count | displayEmpty }}</span>
          </td>
          <td (click)="openPluginList(p.error_count, p.version, 'Error')">
            <span class="view-plugins">{{ p.error_count | displayEmpty }}</span>
          </td>
          <td (click)="openPluginList(p.pending_count, p.version, 'Pending')">
            <span class="view-plugins">{{ p.pending_count | displayEmpty }}</span>
          </td>
          <td *ngIf="currentUserInfo?.accesslevel=='ADMIN'">
            <button *ngIf="isShowUpdatePluginBtn(p.update_status)" (click)="openScheduleUpdate(p)">
              {{ getUpdateScheduleBtnLabel(p.update_schedule) }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>