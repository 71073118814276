import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-panel-tools',
  templateUrl: './panel-tools.component.html',
  styleUrls: ['./panel-tools.component.scss']
})
export class PanelToolsComponent implements OnInit {
  @Input() PopupTitle: any;
  constructor() { }

  ngOnInit() {
  }

  printDiv(divID) {
    //Get the HTML of div
    var divElements = document.getElementById(divID).innerHTML;
    //Get the HTML of whole page
    var oldPage = document.body.innerHTML;

    //Reset the page's HTML with div's HTML only
    document.body.innerHTML = 
      "<html><head><title></title></head><body>" + 
      divElements + "</body>";

    //Print Page
    window.print();

    //Restore orignal HTML
    document.body.innerHTML = oldPage;

  
}
sendData(i){

}
}
