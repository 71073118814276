import { trigger, state, style, transition,
  animate, group, query, stagger, keyframes
} from '@angular/animations';

export const scaleRouterOulet = [
    trigger('scaleHideShow', [
      state('hide', style({
        // position: 'absolute',
        // right: '-200px',
        // top: '500px',
        opacity: '0',
        transform: 'scale(.5)',
        visibility: 'hidden',
        display: 'none'
      })),
      state('show', style({
        // transform: 'scale(.95,.5) translate(0px,-430px)',
        // visibility: 'visible',
        // transform: 'scale(1)'
        // position: 'absolute',
        // right: '0px',
        opacity: '1',
        visibility: 'visible',
        transform: 'scale(1)',
        'transform-origin': '0px 0px'
      })),
      transition('hide => show', animate('300ms ease-in-out')),
      transition('show => hide', animate('300ms ease-in-out'))
    ]),
]