import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GetIotService {
  getUrlPath: Function = environment.apiServicePathBuilder('iot');
  // getUrlPath: Function = environment.apiServicePathBuilder('dev');
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const { company_id } = helper.decodeToken(token);
    return company_id;
  };

  getDomotzAccountByCompanyId(company_id) {
    const url: string = this.getUrlPath(`getDomotzAccountByCompanyId/${company_id}`);
    return this.http.get<any>(url).map(this.extractData).catch(this.handleError);
  }

  getAllDomotzAgents(company_id, search_key, limit, offset, filterLink) {
    const url: string = this.getUrlPath(
      `getAllDomotzAgents/${company_id}/search_key/${search_key.trim()}/limit/${limit}/offset/${offset}/filterLink/${filterLink}`
    );
    return this.http.get<any>(url).map(this.extractData).catch(this.handleError);
  }

  getAllCompanyMembers(company_id) {
    const url: string = this.getUrlPath(`getAllCompanyMembers/${company_id}`);
    return this.http.get<any>(url).map(this.extractData).catch(this.handleError);
  }

  addDomotzAccount(company_id, api_key, api_name, account_url) {
    const _url: string = this.getUrlPath('addDomotzAccount');
    // const company_id = this.getCompanyId();
    const data = {
      company_id,
      api_name,
      api_key,
      account_url,
    };
    return this.http
      .post<any>(_url, data, this.httpOptions)
      .map(this.extractData)
      .catch(this.handleError);
  }

  updateDomotzAccount(company_id, domotz_account_id, api_name, account_url, api_key, active) {
    const url: string = this.getUrlPath('updateDomotzAccount');
    // const company_id = this.getCompanyId();
    const data = {
      domotz_account_id,
      api_name,
      account_url,
      api_key,
      active,
      company_id,
    };
    return this.http
      .post<any>(url, data, this.httpOptions)
      .map(this.extractData)
      .catch(this.handleError);
  }

  syncDomotz(company_id, api_name, account_url) {
    const _url: string = this.getUrlPath('syncDomotzAccount');

    const data = {
      company_id,
      api_name,
      account_url,
    };

    return this.http
      .post<any>(_url, data, this.httpOptions)
      .map(this.extractData)
      .catch(this.handleError);
  }

  constructor(private http: HttpClient) {}
}
