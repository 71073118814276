<h4>Add Company</h4>
<div [formGroup]="add_company_form">
  <div class="input-ctr form-group">
    <input class="input-class defaultInput" formControlName="companyName" placeholder="Company Name" />
    <div class="message-ctr"
      *ngIf="add_company_form.controls['companyName'].hasError('required') && (add_company_form.controls['companyName'].dirty || add_company_form.controls['companyName'].touched)">
      <span class="message">Company Name is required.</span>
    </div>
  </div>
  <div class="input-ctr form-group">
    <input class="input-class defaultInput" formControlName="domain" placeholder="Domain (ex. yourdomain.com)" />
    <div class="message-ctr"
      *ngIf="add_company_form.controls['domain'].hasError('required') && (add_company_form.controls['domain'].dirty || add_company_form.controls['domain'].touched)">
      <span class="message">Domain name is required.</span>
    </div>
    <div class="message-ctr"
      *ngIf="add_company_form.controls['domain'].hasError('pattern') && (add_company_form.controls['domain'].dirty || add_company_form.controls['domain'].touched)">
      <span class="message">Enter valid domain. (ex. yourdomain.com)</span>
    </div>
  </div>

  <div class="company-address-ctr form-group">

    <input class="defaultInput input-short" formControlName="address1" placeholder="Address" />
    <input class="defaultInput input-short" formControlName="address2" placeholder="Address2" />
    <input class="defaultInput input-short" formControlName="city" placeholder="City" />
  </div>

  <div class="company-address-ctr form-group">
    <input class="defaultInput input-short" formControlName="zip" placeholder="Zip" />

    <select class="select-css" (change)="selectCountry($event.target.value)">
      <option *ngFor="let item of countryDataList" [value]="item.name">{{ item.name }}</option>
    </select>

    <select class="select-css" [(value)]="selectedState" (change)="selectState($event.target.value)">
      <option *ngFor="let item of stateDataList" [value]="item.name">{{ item.name }}</option>
    </select>
  </div>

  <div class="input-ctr form-group">
    <input class="input-class defaultInput" formControlName="firstname" placeholder="Primary Contact First Name" />
    <div class="message-ctr"
      *ngIf="add_company_form.controls['firstname'].hasError('required') && (add_company_form.controls['firstname'].dirty || add_company_form.controls['firstname'].touched)">
      <span class="message">First name is required.</span>
    </div>
  </div>

  <div class="input-ctr form-group">
    <input class="input-class defaultInput" formControlName="lastname" placeholder="Primary Contact Last Name" />
    <div class="message-ctr"
      *ngIf="add_company_form.controls['lastname'].hasError('required') && (add_company_form.controls['lastname'].dirty || add_company_form.controls['lastname'].touched)">
      <span class="message">Last name is required.</span>
    </div>
    <div class="message-ctr"
      *ngIf="add_company_form.controls['lastname'].hasError('mustNotMatch') && (add_company_form.controls['lastname'].dirty || add_company_form.controls['lastname'].touched)">
      <span class="message">Last name must not match First name.</span>
    </div>
  </div>

  <div class="input-ctr form-group">
    <input class="input-class defaultInput" formControlName="phone" placeholder="Primary Contact Phone" />
    <div class="message-ctr"
      *ngIf="add_company_form.controls['phone'].hasError('required') && (add_company_form.controls['phone'].dirty || add_company_form.controls['phone'].touched)">
      <span class="message">Primary Contact Phone is required.</span>
    </div>
  </div>

  <div class="input-ctr form-group">
    <input class="input-class defaultInput" formControlName="email" placeholder="Primary Contact Email" />
    <div class="message-ctr"
      *ngIf="add_company_form.controls['email'].hasError('required') && (add_company_form.controls['email'].dirty || add_company_form.controls['email'].touched)">
      <span class="message">Primary Contact Email is required.</span>
    </div>
    <div class="message-ctr"
      *ngIf="add_company_form.controls['email'].hasError('pattern') && (add_company_form.controls['email'].dirty || add_company_form.controls['email'].touched)">
      <span class="message">Enter valid email address.</span>
    </div>
  </div>

  <div *ngIf="showMspInputForm" class="input-ctr form-group">
    <input class="input-class defaultInput" formControlName="mspemail"
      placeholder="MSP Email (ex. youremail@yourdomain.com)" />
    <div class="message-ctr"
      *ngIf="add_company_form.controls['mspemail'].hasError('required') && (add_company_form.controls['mspemail'].dirty || add_company_form.controls['mspemail'].touched)">
      <span class="message">MSP Email is required.</span>
    </div>
    <div class="message-ctr"
      *ngIf="add_company_form.controls['mspemail'].hasError('pattern') && (add_company_form.controls['mspemail'].dirty || add_company_form.controls['mspemail'].touched)">
      <span class="message">Enter valid email address.</span>
    </div>
    <div class="message-ctr"
      *ngIf="add_company_form.controls['mspemail'].hasError('mustNotMatch') && (add_company_form.controls['mspemail'].dirty || add_company_form.controls['mspemail'].touched)">
      <span class="message">MSP Email must not match Domain.</span>
    </div>
  </div>

  <div class="db-msg {{ colorStatus }}">
    {{ addCompanyStatus }}
  </div>

  <div class="input-checkbox">
    <div class="form-group">
      <span class="">Web View </span>
      <label class="switch">
        <input type="checkbox" (change)="enableWebView($event)" checked />
        <span class="slider round check-box" id="web-view-toggle"></span>
      </label>
    </div>
    <div class="form-group">
      <span> MSP Notification </span>
      <label class="switch">
        <input type="checkbox" (change)="enableMsp($event)" checked />
        <span class="slider round" id="msp-toggle"></span>
      </label>
    </div>
    <div *ngIf="isPlatformAdmin" class="form-group">
      <span class="">Tenant Admin </span>
      <label class="switch">
        <input type="checkbox" (change)="enableTenantAdmin($event)" />
        <span class="slider round check-box" id="tenant-admin-toggle"></span>
      </label>
    </div>
  </div>

  <button *ngIf="!doneAddingCompany" type="submit" class="btn-primary input-class" (click)="validateForm()">
    Creat{{companyBeingCreated ? 'ing' : 'e'}} Company
  </button>

</div>