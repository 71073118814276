import { Component, Inject, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-integrator-account-dialog',
  templateUrl: './integrator-account-dialog.component.html',
  styleUrls: ['./integrator-account-dialog.component.scss'],
})
export class IntegratorAccountDialogComponent {
  loader: any = environment.config.dynamicImage.loader;
  isLoading: boolean = false;
  companyName: any;
  removeCompany: Function;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<IntegratorAccountDialogComponent>
  ) {
    if (data) {
      const { company_name } = data.config;
      this.companyName = company_name;
      this.removeCompany = data.removeCompany;
    }
  }

  closeDialog = () => {
    this.isLoading = false;
    this.dialogRef.close(true);
  };

  confirmDelete = () => {
    this.removeCompany(this.data.config);
    this.closeDialog();
  };
}
