import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mac-collector-update-schedule',
  templateUrl: './mac-collector-update-schedule.component.html',
  styleUrls: ['./mac-collector-update-schedule.component.scss']
})
export class MacCollectorUpdateScheduleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
