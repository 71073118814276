<div class="main-box" [ngStyle]="{ 'background-image': 'url(' + bgImage + ')' }">
    <div class="row" *ngIf="!sentRequest && valid == 1">
        <div class="col-12">
            <form name="forgetForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()" role="form" class="signup">
                <div class="form-group">
                    <img [src]="loginImage" class="logo-img">
                </div>
                <p class="main_p">{{responseMessage}}</p>
                <div class="form-group">
                    <input type="password" formControlName="password" class="form-control" name="password" id="password"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="New Password"
                        autocomplete='new-password' required />
                </div>
                <div *ngIf=" submitted && f.password.errors" class="invalid-feedback  ">
                    <div *ngIf="f.password.errors.minlength" class="phonetext alert alert-danger p-2 m-0 bottom">
                        Minimum 8
                        character</div>
                    <div *ngIf="f.password.errors.required" id="confirmMessage" class="bottom">!</div>
                </div>
                <div class="form-group">
                    <input type="password" formControlName="password1" class="form-control" name="password1"
                        id="password1" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                        placeholder="Confirm New Password" autocomplete='new-password' required />
                </div>
                <div *ngIf=" submitted && f.password1.errors" class="invalid-feedback  ">
                    <div *ngIf="f.password1.errors.minlength" class="phonetext alert alert-danger p-2 m-0 bottom">
                        Minimum 8
                        character</div>
                    <div *ngIf="f.password1.errors.required" id="confirmMessage" class="bottom">!</div>
                </div>
                <div *ngIf="isPasswordNotMatched" class="match">Please ensure that the passwords
                    entered match exactly.
                </div>
                <button type="submit" class="btn-primary" style="width:100%;">
                    submit
                </button>
            </form>
        </div>
    </div>
    <div class="expire_msg" *ngIf="valid == 0">
        <div class="forgot__text">
            <h3>Oh, no!</h3>
        </div>
        <hr />
        <div class="forgot__text forgot__link-fa">
            <img src="../../../../assets/img/confused-face.svg" width="25px" height="25px">
            <h5>Reset code expired</h5>
        </div>
        <div class="forgot__input-ctr">
            <button class="btn_forget_pass" routerLink="/adminforgotpassword">
                Reset Password
            </button>
        </div>
    </div>
    <div class="forgot__box" *ngIf="sentRequest && valid == 1">
        <div *ngIf="resetResponseFailed == 1 && resetResponseData?.status == 200">
            <div class="expire_msg" *ngIf="valid == 0">
                <div class="forgot__text">
                    <h3>Oh, no!</h3>
                </div>
                <hr />
                <div class="forgot__text forgot__link-fa">
                    <img src="../../../../assets/img/confused-face.svg" width="25px" height="25px">
                    <h5>Reset code expired</h5>
                </div>
                <div class="forgot__input-ctr">
                    <button class="btn_forget_pass" routerLink="/adminforgotpassword">
                        Reset Password
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="resetResponseData?.status != 200">
            <div class="expire_msg" *ngIf="valid == 0">
                <div class="forgot__text">
                    <h3>Oh, no!</h3>
                </div>
                <hr />
                <div class="forgot__text forgot__link-fa">
                    <img src="../../../../assets/img/confused-face.svg" width="25px" height="25px">
                    <h5>Reset code expired</h5>
                </div>
                <div class="forgot__input-ctr">
                    <button class="btn_forget_pass" routerLink="/adminforgotpassword">
                        Reset Password
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="resetResponseData?.status == 200 && resetResponseFailed == 0">
            <div class="expire_msg">
                <div class="forgot__text">
                    <h3>Great!</h3>
                </div>
                <hr />
                <div class="forgot__text forgot__link-fa">
                    <img src="../../../../assets/img/emojisky.com-1020113 (1).png" width="50px" height="50px">
                    <h5>Password Has Been Reset</h5>
                </div>
                <div class="forgot__input-ctr">
                    <button class="btn_forget_pass" routerLink="/adminlogin">
                        login
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>