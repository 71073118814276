<!-- DELETE SECTION -->
<div class="company_dialog">
    <div class="company-dialog__body" *ngIf="!showSuccessMessage">
        <div class="company-dialog__image">
            <img *ngIf="!isLoading" src="../../../../assets/img/gbl_img/delete-icon.png" alt="" />
            <img *ngIf="isLoading" src="{{ loader }}" alt="" />
        </div>
        <div class="company-dialog__text-button-container">
            <div class="">
                Are you sure you want to delete <strong>{{ companyName }}</strong> company?
            </div>
            <div class="company-dialog__button-container">
                <div class="company-dialog__button-item company-dialog__button-item--yes"
                    [ngClass]="{ 'company-dialog__button-item--disabled': isLoading }" (click)="confirmDelete()">
                    Yes
                </div>
                <div class="company-dialog__button-item company-dialog__button-item--cancel"
                    [ngClass]="{ 'company-dialog__button-item--disabled': isLoading }" (click)="closeDialog()">
                    Cancel
                </div>
            </div>
        </div>


    </div>


    <div *ngIf="showSuccessMessage" class="company-dialog__body" style="justify-content: center;">
        <h5>Company deleted successfully.</h5>
    </div>
</div>

<!-- DELETE SECTION -->



<!-- <div class="company-dialog__success-message">
        <h5>Company deleted successfully</h5>
    </div> -->