import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class MembersTypeService {
  private subject = new Subject<any>();
  // what is this?
  sendMemberType(message: number) {
    this.subject.next(message);
  }

  clearMemberType() {
    this.subject.next();
  }

  getMemberType(): Observable<any> {
    return this.subject.asObservable();
  }
}
