import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { GetIotService } from '../../../app/services/get-iot.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-domotz-enable-service',
  templateUrl: './domotz-enable-service.component.html',
  styleUrls: ['./domotz-enable-service.component.scss'],
})
export class DomotzEnableServiceComponent implements OnInit {
  getUrlPath: Function = environment.apiServicePathBuilder('iot');

  @Input() boolEvent: any;
  @Input() currentCompanyId: any;

  dumbKey = '00000000-0000-0000-0000-000000000000';

  domotzAccount: any;
  apiKey: string = this.dumbKey;
  apiName: string = '';
  accountUrl: string = '';
  domotzAccountId: number = 0;
  lastPullingCycle: string;
  btnName: string = 'Add Api Key';
  isShowSync: boolean = false;
  active: number;
  msg: String = 'IoT API/Name/URL Key Updated.';
  msgColor: String = 'success_msg';
  shouldShowEnableServiceMessage: boolean = false;
  loader: String = environment.config.dynamicImage.loader;
  showLoading: Boolean = true;

  syncBtnEnabled: Boolean = true;
  updateBtnEnabled: Boolean = true;

  constructor(private getIot: GetIotService) {}

  webHookUrl =
    environment.config.webhookUrl !== null
      ? environment.config.webhookUrl + 'iot/domotzWebhook'
      : this.getUrlPath('domotzWebhook');

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentCompanyId && this.currentCompanyId) {
      this.getDomotzAccountByCompanyId();
    }
  }

  getDomotzAccountByCompanyId = () => {
    const company_id = this.currentCompanyId;

    this.getIot.getDomotzAccountByCompanyId(company_id).subscribe((data) => {
      this.domotzAccount = data;
      this.setData();
    });
  };

  setData = () => {
    const { api_key, api_name, account_url, domotz_account_id, active, last_pulling_cycle } =
      this.domotzAccount;

    this.apiKey = this.dumbKey;
    this.apiName = api_name;
    this.accountUrl = account_url;
    this.domotzAccountId = domotz_account_id;
    this.active = active;
    this.lastPullingCycle = last_pulling_cycle;

    if (api_key) {
      this.btnName = 'Update Api Key';
      this.isShowSync = true;
    }

    this.showLoading = false;
  };

  submitInput = () => {
    if (this.btnName === 'Add Api Key') {
      this.addDomotzAccount();
    } else {
      this.updateDomotzAccount();
    }
  };

  addDomotzAccount = () => {
    this.showLoading = true;
    const company_id = this.currentCompanyId;
    this.getIot.addDomotzAccount(company_id, this.apiKey, this.apiName, this.accountUrl).subscribe(
      (data) => {
        this.msgConfig('IoT API/Name/URL Key Updated.', 'success_msg');
        this.getDomotzAccountByCompanyId();
        this.showLoading = false;
      },
      (error) => {
        this.msgConfig('IoT API/Name/URL Key Invalid.', 'err_msg');
        this.showLoading = false;
      }
    );
  };

  updateDomotzAccount = () => {
    if (this.apiKey === this.dumbKey) return;

    const company_id = this.currentCompanyId;
    this.showLoading = true;
    this.updateBtnEnabled = false;

    this.getIot
      .updateDomotzAccount(
        company_id,
        this.domotzAccountId,
        this.apiName,
        this.accountUrl,
        this.apiKey,
        this.active
      )
      .subscribe(
        (data) => {
          if (data || data !== null) {
            this.msgConfig('IoT API/Name/URL Key Updated.', 'success_msg');
            this.getDomotzAccountByCompanyId();
            this.showLoading = false;
            this.updateBtnEnabled = true;
          }
        },
        (error) => {
          this.msgConfig('IoT API/Name/URL Key Invalid.', 'err_msg');
          this.showLoading = false;
          this.updateBtnEnabled = true;
        }
      );

    // commenting out Ali's code coz it doesn't handle err from the API and bringing back the old code

    // this.getIot
    //   .updateDomotzAccount(
    //     company_id,
    //     this.domotzAccountId,
    //     this.apiName,
    //     this.accountUrl,
    //     this.apiKey,
    //     this.active
    //   )
    //   .subscribe();

    // this.msgConfig(
    //   'Updating... This can take up to 2 minutes. Please be patient and do not leave this page.',
    //   'success_msg',
    //   59000
    // );
    // this.updateBtnEnabled = false;

    // setTimeout(() => {
    //   this.updateBtnEnabled = true;
    //   this.msgConfig('IoT API/Name/URL Key Updated.', 'success_msg', 10000);
    //   this.getDomotzAccountByCompanyId();
    // }, 60000);
  };

  syncDomotzAccount = () => {
    // this.showLoading = true;
    const company_id = Number(this.currentCompanyId);
    this.getIot.syncDomotz(company_id, this.apiName, this.accountUrl).subscribe();
    // this.getIot.syncDomotz(company_id, this.apiName, this.accountUrl).subscribe(
    //   (data) => {
    //     if (data || data !== null) {
    //       this.msgConfig('IoT API/Name/URL Key Updated.', 'success_msg');
    //       this.getDomotzAccountByCompanyId();
    //       this.showLoading = false;
    //     }
    //   },
    //   (error) => {
    //     this.msgConfig('IoT API/Name/URL Key Invalid.', 'err_msg');
    //     this.showLoading = false;
    //   }
    // );

    this.msgConfig(
      'Syncing... This can take up to 2 minutes. Please be patient and do not leave this page.',
      'success_msg',
      59000
    );
    this.syncBtnEnabled = false;

    setTimeout(() => {
      this.syncBtnEnabled = true;
      this.msgConfig('IoT API/Name/URL Key Synced.', 'success_msg', 10000);
      this.getDomotzAccountByCompanyId();
    }, 60000);
  };

  msgConfig = (text, color, time = 2000) => {
    this.shouldShowEnableServiceMessage = true;
    this.msg = text;
    this.msgColor = color;
    setTimeout(() => {
      this.shouldShowEnableServiceMessage = false;
    }, time);
  };

  ngOnDestroy() {}
}
