import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';

@Component({
  selector: 'app-collector-version-list',
  templateUrl: './collector-version-list.component.html',
  styleUrls: ['./collector-version-list.component.scss'],
})
export class CollectorVersionListComponent implements OnInit {
  @Input() currentUserInfo: any;
  @Input() systemType: any;
  collectorVersionList: any;
  isOpenReleaseNotes = false;
  isOpenScheduleUpdate = false;
  isOpenCollectorList = false;
  rNotes: any;
  scheduleUpdateData: any;
  collectorListData: any;
  dCollector: String = environment.config.dynamicText.dCollector;
  collectorImg: String = environment.config.dynamicImage.collectorImg;

  constructor(private Auth: AuthService) { }

  ngOnInit() {
    this.getCollectorVersionList();
  }

  getCollectorVersionList = () => {
    this.collectorVersionList = [];
    this.Auth.getCollectorVersionList().subscribe((data) => {
      // needs a parameter for super admin
      if (data['status'] === 200) {
        this.collectorVersionList = data.response;
        console.log('collectorVersionList: ', this.collectorVersionList)
      }
    });

  };

  openReleaseNotes = (cVersion) => {
    const obj = {
      collector_version_id: cVersion.collector_version_id,
      release_notes: cVersion.release_notes,
      version: cVersion.version,
    };
    this.rNotes = obj;
    this.isOpenReleaseNotes = true;
  };

  openScheduleUpdate = (tableObj) => {
    const obj = {
      email_notes: tableObj.email_notes,
      include_release_notes: tableObj.include_release_notes,
      version: tableObj.version,
      update_schedule: tableObj.update_schedule,
    };
    this.scheduleUpdateData = obj;
    this.isOpenScheduleUpdate = true;
  };

  openCollectorList = (cCount, version, infoType) => {
    if (cCount > 0) {
      this.isOpenCollectorList = true;
      this.collectorListData = {
        infoType,
        version,
      };

      console.log('this.collectorListData: ', this.collectorListData)
    }
  };

  ngOnChange = ($event) => {
    if ($event === 'closeReleaseNotes') {
      this.isOpenReleaseNotes = false;
      return;
    }

    if ($event === 'closeScheduleUpdate') {
      this.isOpenScheduleUpdate = false;
      return;
    }

    if ($event === 'closeCollectorList') {
      this.isOpenCollectorList = false;
      return;
    }

    if ($event === 'getCollectorVersionList') {
      this.getCollectorVersionList();
      return;
    }
  };

  isShowUpdateCollectorBtn = (status) => {
    if (status === 'COMPLETED') {
      return false;
    }
    return true;
  };

  getUpdateScheduleBtnLabel = (scheduleUpdate) => {
    return scheduleUpdate === null ? 'Schedule Update' : 'Reschedule Update';
  };
}
