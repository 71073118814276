import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { SSOService } from '../../../services/sso.service';
// export const TOKEN_NAME: string = 'jwt_token';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  control: FormControl;
  loginForm: FormGroup;
  submitted = false;
  loginFeedback = '';
  //server global variable;
  server_url: string = environment.config.serverUrl;
  links = environment.config.links;
  loginImage = environment.config.dynamicImage.loginImage;
  bgImage = environment.config.dynamicImage.bgImage;
  showPoweredBy = environment.config.showPoweredBy;
  dTerms = environment.config.dynamicText.dTerms;
  dLoginTitle = environment.config.dynamicText.dLoginTitle;
  pass1: any;
  tenantAdmin = environment.config.tenantName
  
  sso: {
    enabled: boolean // for now it is enabled for cxdetect only
    // FOR SSO CALLBACK:
    code?: string 
    state?: string
    errorMsg?: string
  } = {
    enabled: environment.config['SSO']?.enabled
  }

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ssoService: SSOService
    // private userInfo: UserInfoService,
  ) {}

  model: any = {};
  showModal = false;
  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }


  ngOnInit() {
    // FOR SSO CALLBACK:
    this.route.queryParams.subscribe(params => {
      this.sso.code = params['code']
      this.sso.state = params['state']

      if (this.sso.state && this.sso.code) this.matchTheState()
    })

    localStorage.getItem('successReg') == '0';
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
    this.onSuccessReg();
  }

  matchTheState() {
		const storedState = localStorage.getItem('ssoGAMLoginState')
		if (storedState === this.sso.state) {
			localStorage.removeItem('ssoGAMLoginState')
			this.getSSOAccessToken(this.sso.code)
		} else this.sso.errorMsg = 'Invalid state'
	}

  getSSOAccessToken(code: string) {
		this.ssoService
			.getTokenFromGAMCode(code)
			.subscribe(
				() => {},
				error => {
					this.sso.errorMsg = JSON.stringify(error?.error)
				}
			)
	}

  openModal = () => {
    // this.showModal = true;
    if(this.tenantAdmin == 'CxDetect') {
      this.showModal = true;
    } else {
      window.open(`${this.links.terms}`, "_blank");
    }
  }


  onSuccessReg() {
    if (localStorage.getItem('successReg') == '1') {
      (<HTMLInputElement>document.getElementById('thankYou')).style.display = 'block';
      (<HTMLInputElement>document.getElementById('thankYouText')).style.display = 'block';
    }
  }
  
  onSubmit() {
    this.loginFeedback = '';
    localStorage.setItem('successReg', '0');
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    //alert('SUCCESS!! :-)')
  }


  loginUser(event, enable2Fa = true) {
    event.preventDefault();

    const target = event.target;
    const username = target.querySelector('#username').value;
    const password = target.querySelector('#password').value;
    const invalidFeed = target.querySelector('#feedback');
    this.Auth.getUserDetails(username, password, enable2Fa).subscribe((data: any) => {
			// moved the logic here to 'onSuccessfullLogin' method of Auth service so that it can be reused in login with SSO
    }, error => {
      this.loginFeedback = error;
    });
  }

  onClickSSOLogin = () => {
    this.ssoService.signIn()
  }
}
