import { Component, OnInit, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { PopupTypeService } from '../../services/popupType.service';
import { PopupOpenClose } from '../../services/popupContainer.animation';
import { PopupSendDataService } from '../../services/popup-send-data.service'

@Component({
  selector: 'app-popup-container',
  templateUrl: './popup-container.component.html',
  styleUrls: ['./popup-container.component.scss'],
  animations: [ PopupOpenClose ]
})
export class PopupContainerComponent implements OnInit {
  @Output() popupTypeState:string = 'close';
  @Input() title:string = "Title";
  popupType: number = 1;
  subscription: Subscription;
  @Input() runTutorial;
  deviceId: any;
  data: any;

  constructor(private ds: PopupTypeService,  private sendPopupDataService: PopupSendDataService) { 
    this.subscription = this.ds.getPopupType().subscribe(x => {
      if (x) {
        let inputs = JSON.parse(x);
        this.popupType = inputs.popupId; 
        this.data = inputs.data;
        if(this.popupType != null && this.popupType != 0){
           this.togglePopupType();
        }
      }
    });

    //This function passes the device params Id to the reboot video device function. Can be used for funciton as well
    this.sendPopupDataService.popupDataListen().subscribe((paramsData: any) =>{
      this.deviceId = paramsData;
    })
  }

  togglePopupType() {
    // 1-line if statement that toggles the value:
    this.popupTypeState = this.popupTypeState === 'open' ? 'close' : 'open';
  }

  ngOnInit(){
    this.popupTypeState === 'open';
  }
}
