<!-- *ngIf="menuStateNote=='out'" -->
<div class="main-container" style="background-color: #000; min-height: 100%;min-width: 100%; position: fixed; bottom:0px; transition: all 1500ms; z-index: 1120"
    [ngStyle]="{opacity: popupTypeState=='open' ? .5 : 0, 'pointer-events': popupTypeState=='open' ? 'auto': 'none'}" (click)="popupTypeState='close'"></div>
<div style="max-height: 100%;min-width: 100%; position: fixed; bottom:-8px;  z-index: 1120;transition: all 1500ms;" [@popupOpenClose]="popupTypeState"  >
    <div class="panel-group">
        <div class="panel panel-default">
            <span class="close float-right" (click)="popupTypeState='close'" >
                <img src="./assets/img/btn close_default.png">
            </span>
        </div>
    </div>
</div>
