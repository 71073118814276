<div *ngIf="showLoading" class="domotzenable__loading">
  <img src="{{ loader }}" alt="" />
</div>
<div *ngIf="!showLoading" class="domotzenable">
  <div class="res-message {{ msgColor }}">
    <h3 *ngIf="shouldShowEnableServiceMessage">
      {{ msg }}
    </h3>
  </div>
  <div class="domotzenable__container">
    <div class="domotzenable__label">API KEY</div>
    <input class="domotzenable__input" value="apiKey" [(ngModel)]="apiKey" type="password" />
  </div>
  <div class="domotzenable__container">
    <div class="domotzenable__label">API NAME</div>
    <input class="domotzenable__input" value="apiName" [(ngModel)]="apiName" type="text" />
  </div>
  <div class="domotzenable__container">
    <div class="domotzenable__label">ACCOUNT URL</div>
    <input class="domotzenable__input" value="accountUrl" [(ngModel)]="accountUrl" type="text" />
  </div>
  <div class="domotzenable__btn">
    <button (click)="submitInput()" [disabled]="!updateBtnEnabled">{{ btnName }}</button>
    <button *ngIf="isShowSync" (click)="syncDomotzAccount()" [disabled]="!syncBtnEnabled">Sync Account</button>
  </div>
  <div class="domotzenable__other-info">
    <!-- <p><b>Webhook URL:</b> {{ webHookUrl }}</p> -->
    <p><b>Webhook URL:</b>{{ webHookUrl }}</p>
    <p *ngIf="lastPullingCycle"><b>Last Pulling Cycle:</b> <br>
      {{ lastPullingCycle | date: 'EEEE, d MMMM y, h:mm:ss a'}} (Local)<br>
      {{ lastPullingCycle | date : 'EEEE, d MMMM y, h:mm:ss a' : 'UTC'}} (UTC)
    </p>
  </div>
</div>