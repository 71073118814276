import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { ListenerService } from '../../services/listener.service';
import { LocalDataSource } from 'ng2-smart-table';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';

import { UserInfoService } from '../../services/user-info.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  setoverlay: String = '';
  companyListData: LocalDataSource;
  server_url: string = environment.config.serverUrl;
  showPoweredBy = environment.config.showPoweredBy;
  isShowAddCompany: any = false;

  control: FormControl;
  addCompanyForm: FormGroup;
  submitted: any = false;

  addCompanyStatus: any = '';
  selectOption = 1;
  nowSelectedCompanyId = 0;
  allCompanyListData = [];
  selCompDtlData:any = [];
  filterListner: Subscription;
  companyLoginType: string;
  shouldShowIntergrator: boolean;
  allCompanyUserData = [];
  allCompanyCollectorData = [];

  currentCompanyLoggedIn: any;
  currentUserInfo: any;
  isPlatformAdmin = false;
  isTenantAdmin = false;
  isMspDomotzEnabled: boolean = false;
  isCompanyIntegrator: boolean = false;
  isDomotzEnvEnabled = environment.config.domotzIntegration;

  highlightNum: Number = 0;

  dCollector: String = environment.config.dynamicText.dCollector;
  collectorImg: String = environment.config.dynamicImage.collectorImg;

  isShowMSPDomotzAppBtn: Boolean = false;
  beingDeleted: boolean = false;

  // axe
  @Input() routeId: any;

  get f() {
    return this.addCompanyForm.controls;
  }

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private listenerRx: ListenerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    private userInfo: UserInfoService,
    private route: ActivatedRoute
  ) {
    this.companyListData = new LocalDataSource();
    this.control = fb.control({ value: 'my val', disabled: true });

    this.route.params.subscribe(({ id }) => {
      this.routeId = id;
    });
  }

  reFreshCompanyList(event: string) {
    if (event) {
      this.getAllCompanyList();
    }
  }

  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(';').map((e) => e.trim());
    const forbidden = emails.some((email) => Validators.email(new FormControl(email)));
    return forbidden ? { 'toAddress': { value: control.value } } : null;
  };

  ngOnInit() {
    this.getAsyncInit();
  }

  getAsyncInit = async () => {
    await this.getCurrentCompanyLoggedIn();
    await this.getCurrentUserInfo();
    await this.getAllCompanyList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.routeId && this.routeId) {
      this.fetchCompanyInfo();
    }
  }

  checkMSPDomotzButton(is_integrator, is_domotz) {
    if (this.isDomotzEnvEnabled) {
      if ((this.isTenantAdmin || this.isPlatformAdmin || is_integrator === 1) && is_domotz === 1) {
        this.isShowMSPDomotzAppBtn = true;
      } else {
        this.isShowMSPDomotzAppBtn = false;
      }
    } else {
      this.isShowMSPDomotzAppBtn = false;
    }
  }

  onSearch(query: string = '') {
    if (query === '') {
      this.companyListData.setFilter([]);
    } else {
      this.companyListData.setFilter(
        [
          {
            field: 'company_name',
            search: query,
          },
        ],
        false
      );
    }
  }

  getAllCompanyList = async () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    this.allCompanyListData = [];
    const { loginType, company_id } = this.userInfo.getInfo();

    const data = await this.Auth.getCompanyList(loginType, company_id).toPromise();

    this.allCompanyListData = data['response'];
    this.filterPlatformCompany();
    if (!this.routeId) {
      this.routeId = this.allCompanyListData[0].company_id;
    }
    this.fetchCompanyInfo();
  }

  refreshAllCompanyList() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const { loginType, company_id } = this.userInfo.getInfo();
    this.Auth.getCompanyList(loginType, company_id).subscribe((data) => {
      this.allCompanyListData = data['response'];
      this.filterPlatformCompany();
      this.nowSelectedCompanyId = this.allCompanyListData[0].company_id;
    });
  }

  setHighlight(n) {
    //something that has class
    this.highlightNum = n;
  }

  companySelected = (id) => {
    this.isCompanyIntegrator = false;

    this.listenerRx.filter(id);
    this.router.navigate(['/company', id]);
    this.routeId = id;

    if (this.selectOption === 1) {
      this.fetchCompanyInfo();
    } else if (this.selectOption === 2) {
      this.fetchCompanyInfo();
      this.fetchCompanyUserList();
    } else if (this.selectOption === 3) {
      this.fetchCompanyInfo();
      this.fetchCompanyCollectorList();
    } else {
      this.fetchCompanyInfo();
    }
  };

  fetchCompanyInfo = () => {
    this.selectOption = 1;
    const companyDetails = {
      company_id: this.routeId,
    };

    this.selCompDtlData = [];
    this.Auth.getCompanyInfo(companyDetails).subscribe((data) => {
      if (data.response[0].company_being_deleted == 1) {
        this.beingDeleted = true;
        return
      }
      this.beingDeleted = false;
      this.selCompDtlData = data['response'];
      this.companyLoginType = this.userInfo.getInfo().loginType;
      this.isMspDomotzEnabled = this.selCompDtlData[0].is_msp_domotz === 1 ? true : false;
      this.isCompanyIntegrator = this.selCompDtlData[0].is_integrator === 1 ? true : false;
      // console.log('cmpanyLoginType: ', this.companyLoginType)
      this.shouldShowIntergrator =
        this.companyLoginType !== 'superAdmin' && this.selCompDtlData[0].is_integrator === 1;
      if (this.companyLoginType === 'superAdmin') {
        this.shouldShowIntergrator = true;
      }
      this.checkMSPDomotzButton(this.selCompDtlData[0].is_integrator, this.selCompDtlData[0].is_msp_domotz);
    });
  };

  openDocumentCenter = () => {
    this.selectOption = 5;
  };

  openMSPDomotz = () => {
    this.selectOption = 6;
  };

  myFunction() {
    let input, filter, table, tr, td, i, txtValue;
    input = document.getElementById('search-input');
    filter = input.value.toUpperCase();
    table = document.getElementById('company-table');
    tr = table.getElementsByTagName('tr');
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName('td')[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = '';
        } else {
          tr[i].style.display = 'none';
        }
      }
    }
  }

  fetchCompanyUserList() {
    if (this.beingDeleted) {
      return;
    }
    this.selectOption = 2;
    const companyId = {
      company_id: this.routeId,
    };

    this.allCompanyUserData = [];
    this.Auth.getUserList(companyId).subscribe((data) => {
      const res = data.response;
      console.log(res)
      
      this.allCompanyUserData = res.map( e => {
        return {
        ...e,
        fullname: this.getFullname(e.first_name, e.last_name)
        
        }
      
      });

      if (this.currentUserInfo.accesslevel === "USER") {
        this.allCompanyUserData = this.allCompanyUserData.filter(e => e.accesslevel === "USER");
        
      } else if (this.currentUserInfo.accesslevel === "MANAGER") {
        this.allCompanyUserData = this.allCompanyUserData.filter(e => e.accesslevel === "USER" || e.accesslevel === "MANAGER");
      }
      
      if(this.selCompDtlData[0].uses_gam) {
        this.allCompanyUserData = this.allCompanyUserData.filter(e => e.gam_id != null);
      }
  
    });
  }
  
  getFullname = (firstName,lastName) => {
    if(firstName == null) {
      return '--'
    }
    return firstName + lastName;
  }

  fetchCompanyCollectorList() {
    this.selectOption = 3;
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const compyId = {
      company_id: this.routeId,
    };

    this.allCompanyCollectorData = [];
    this.Auth.getCollectorList(compyId).subscribe((data) => {
      this.allCompanyCollectorData = data['response'];
    });
  }

  loginUser(userId) {
    this.Auth.adminViewUserAs(userId).subscribe((data) => {
      if (data['status'] === 200) {
        const newWindow = window.open(
          environment.config.mainUrl + '/tokenlogin/' + data.response[0].token
        );
      }
    });
  }

  addCompanyClicked() {
    this.selectOption = 4;
    this.addCompanyStatus = '';
    this.submitted = false;
  }

  isShowResendRegistration(registationCode) {
    if (registationCode === null || registationCode.length <= 0) {
      return false;
    }

    return true;
  }

  sendEmailInvite(email) {
    const inviteData = {
      email,
    };
    this.Auth.resendRegisterInvite(inviteData).subscribe((data) => {
    });
  }

  getCurrentCompanyLoggedIn = async () => {
    const companyData = {
      company_id: this.userInfo.getInfo().company_id
    };

    const data = await this.Auth.getCompanyInfo(companyData).toPromise();

    this.currentCompanyLoggedIn = data.response[0];
    if (this.currentCompanyLoggedIn.platform_admin === 1) {
      this.isPlatformAdmin = true;
    }
    if (this.currentCompanyLoggedIn.tenant_admin === 1) {
      this.isTenantAdmin = true;
    }
    
    console.log('this.currentCompanyLoggedIn: ', this.currentCompanyLoggedIn)

  }

  getCurrentUserInfo = async () => {
    const userData = {
      company_id: this.userInfo.getInfo().company_id,
      user_id: this.userInfo.getInfo().user_id
    };

    const data = await this.Auth.getCurrentUserInfo(userData).toPromise();
    this.currentUserInfo = data.response[0];
    
    console.log(' this.currentUserInfo: ',  this.currentUserInfo)
  }

  filterPlatformCompany() {
    if (!this.isPlatformAdmin) {
      this.allCompanyListData = this.allCompanyListData.filter(e => e.platform_admin === 0);
    }
  }


  customerEventEmitter = ($event) => {
    if ($event === 'refreshCompany') {
      this.getAllCompanyList();
      return;
    }
    if ($event === 'refreshCompanyInfo') {
      this.fetchCompanyInfo();
      return;
    }
  }

  mspButtonClicked = ($event) => {
    this.isShowMSPDomotzAppBtn = false;
  }
  
  gamEnabed: any = true;
  
  
  isGamEnabled = () => {
  
    this.Auth.isGamEnabled().subscribe((data: any) => {
      console.log('isGamEnabled: ',data)
      this.gamEnabed = data.uses_gam;
    }, (err => {
      console.log('here')
      // this.gamEnabed = false;
    }));
  }
  

}
