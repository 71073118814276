<div class="document-center mb-5">
  <div *ngIf="shouldShowLoader" class="document-center__loader">
    <img src="{{ loader }}" alt="" />
  </div>
  <div class="document-center__header-container">
    <div class="document-center__header-container__stat">
      <strong>Files:</strong> {{ fileCount }}
    </div>
    <div class="document-center__header-container__stat">
      <strong>Used:</strong> {{ totalDocumentSize | documentFileSize }}
    </div>
    <div class="document-center__header-container__stat">
      <strong>Available:</strong> {{ availableStorage | documentFileSize }}
    </div>
    <div *ngIf="currentUserInfo?.accesslevel =='ADMIN' || currentUserInfo?.accesslevel =='MANAGER'; else storageElse"
      class="document-center__header-container__storage">
      <strong>Storage:</strong>
      <input type="number" id="storageLimit" name="storageLimit" min="5" max="10" [(ngModel)]="storageLimit" />
      GB
      <button title="Update document storage size." class="btn btn-primary" (click)="setStorageLimit()"
        *ngIf="isShowSaveStorageLimit&&!shouldShowLoader">
        Save
      </button>
    </div>
    <ng-template #storageElse>
      <div class="document-center__header-container__stat">
        <strong>Storage Size:</strong> {{ storageLimit }} GB
      </div>
    </ng-template>
    <div class="document-center__header-container__right-container">
      <div class="document-center__header-container__right-container__search">
        <i class="fa fa-search"></i>
        <input (keyup)="searchData($event.target.value)" placeholder="Search..." />
      </div>
    </div>
  </div>
  <div class="document-center__table">
    <table *ngIf="fileCount > 0" class="data-table" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="file_type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let doc">
          <img [src]="doc.file_type | fileTypeIcon" [alt]="doc.file_type" />
        </td>
      </ng-container>

      <ng-container matColumnDef="file_name">
        <th mat-header-cell *matHeaderCellDef>Document Name</th>
        <td mat-cell *matCellDef="let doc">
          {{ doc.file_name + '.' + doc.file_type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="file_size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let doc">
          {{ doc.file_size | documentFileSize }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>DNA Page</th>
        <td mat-cell *matCellDef="let doc">
          {{ doc.description }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef>Uploaded On</th>
        <td mat-cell *matCellDef="let doc">
          <!-- {{ convertTimeStamp(doc.date_created) }} <br> -->
          <span *ngIf="doc.date_created">
            {{ doc.date_created | date : 'short'}} (Local) <br>
            {{ doc.date_created | date : 'short' : 'UTC'}} (UTC) <br>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="is_shared">
        <th mat-header-cell *matHeaderCellDef>Shared</th>
        <td mat-cell *matCellDef="let doc">
          {{ doc.is_shared }}
        </td>
      </ng-container>

      <ng-container matColumnDef="share_expiration">
        <th mat-header-cell *matHeaderCellDef>Share Expires</th>
        <td mat-cell *matCellDef="let doc">
          <!-- {{ convertTimeStamp(doc.share_expiration) }} -->
          <span *ngIf="doc.share_expiration">
            {{ doc.share_expiration | date : 'short'}} (Local) <br>
            {{ doc.share_expiration | date : 'short' : 'UTC'}} (UTC)
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <p *ngIf="fileCount <= 0" class="text-center mt-3">Sorry, no files found. Try refining your search or consider uploading new documents</p>
  </div>
</div>