<div class="container-fluid " id="main-box" [ngStyle]="{ 'background-image': 'url(' + bgImage + ')' }">
  <app-term-and-condition-modal *ngIf="showModal" (closeModal)="showModal = false"></app-term-and-condition-modal>

  <div class="row">
    <div class="col-12">
      <form name="forgetForm" [formGroup]="forgetForm" (ngSubmit)="onSubmit()" role="form" class="signup">
        <div class="form-group">
          <img [src]="loginImage" class="logo-img" (click)="routeToHomePage()">
        </div>
        <ng-container *ngIf="!sentRequest">
          <p>forgot your password?enter your email and we’ll send you instructions on how to reset it.</p>
          <div class="form-group">
            <input type="email" formControlName="email" class="form-control" placeholder="email" name="email" id="email"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email" autocomplete='email'
              required />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">Exceed Maximum 50
                character</div>
              <div *ngIf="f.email.errors.required" id="confirmMessage">!</div>
            </div>
          </div>
          <p id="feedback" class="feedback text-center text-danger"></p>
          <button type="submit" class="btn-primary" style="width:100%;">
            submit
          </button>
          <div class="form-group terms text-center pt-2">
            <a (click)="openModal()" target="_blank">{{dTerms}}</a>
          </div>
        </ng-container>
        <ng-container *ngIf="sentRequest">
          <div>
            <p *ngIf="resetResponseData?.response[0].status === 'OK' "
              style="text-align: center; font-size: 20px; color: green!important">Email has been sent with Password
              reset instructions</p>
            <p *ngIf="resetResponseData?.response[0].status === 'Error' "
              style="text-align: center; font-size: 20px; color: red!important">Email address not found</p>
          </div>
        </ng-container>
        <div *ngIf="showPoweredBy" class="poweredby">
          Powered by VisibilityOne
        </div>

      </form>
    </div>
  </div>
</div>