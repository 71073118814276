import { Component, Input, OnInit } from '@angular/core';
import { GetIotService } from '../../services/get-iot.service';

import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LinkAgentDialogComponent } from '../../../app/dialog/link-agent-dialog/link-agent-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

export interface AgentListInterface {
  agent_name: string;
  company: string;
  ip: string;
  mac: number;
  status: string;
  is_linked: number;
}

@Component({
  selector: 'app-domotz-agent-link',
  templateUrl: './domotz-agent-link.component.html',
  styleUrls: ['./domotz-agent-link.component.scss']
})
export class DomotzAgentLinkComponent implements OnInit {

  @Input() currentCompanyId: any;

  iotAgentList: any = [];
  companyChildrenToLink: any;

  pageSizeSelect: any[] = [];
  totalPageLimit = 500;
  paginateOjb: any = {
    currentPage: 1,
    endIndex: 4,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };
  totalCount: number;
  pageSelected: any = 0;
  searchKey: any = 'null';
  filterLink: Boolean = false;
  filterListner: Subscription;
  refreshData: Boolean;
  shouldShowLoaderBtn: Boolean = false;
  displayedColumns: any[] = ['agent_name', 'company', 'ip', 'mac', 'status', 'is_linked'];
  dataSource: MatTableDataSource<AgentListInterface>;
  loader = environment.config.dynamicImage.loader;

  private dialogRef: MatDialogRef<LinkAgentDialogComponent>

  constructor(private getIot: GetIotService, private dialog: MatDialog) { }

  ngOnInit() {
    this.getChildCompanyMembers();
    this.getIotAgentList();
  }

  ngOnChanges() {
  }

  getIotAgentList = () => {
    this.shouldShowLoaderBtn = true;
    this.getIot.getAllDomotzAgents(
      this.currentCompanyId,
      this.searchKey,
      this.totalPageLimit,
      this.paginateOjb.startIndex,
      this.filterLink
    ).subscribe((data) => {
      this.shouldShowLoaderBtn = false;
      this.iotAgentList = data;
      this.dataSource = new MatTableDataSource(this.iotAgentList);
    });
  };

  getChildCompanyMembers = () => {
    this.getIot.getAllCompanyMembers(this.currentCompanyId
    ).subscribe((data) => {
      this.companyChildrenToLink = data;
    });
  };

  getStatusColor = (status) => {
    let color = 'iot-status-grey';
    switch (status) {
      case 'ONLINE':
        color = 'iot-status-green';
        break;
      default:
        color = 'iot-status-red';
        break;
    }
    return color;
  };

  getLinkIcon = (params) => {
    return params.subvendor_id === 0 ? '../../../assets/img/link.png' : '../../../assets/img/unlink.png';
  };

  openLinkAgentToChildCompanyModal = (params) => {
    const config = this.companyChildrenToLink;
    const agentName = params.agent_name;
    const agentID = params.agent_id;
    const currentCompanyID = this.currentCompanyId;
    this.dialogRef = this.dialog.open(LinkAgentDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
        items: params,
        agentName,
        agentID,
        currentCompanyID,
        isAgentLinked: params.subvendor_id === 0 ? false : true,
        companyChildName: params.company_name,
      },
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource.data = [];
        this.getIotAgentList();
      }

    });
  };

  searchData = (key) => {
    this.searchKey = key;
    if (!this.searchKey) {
      this.searchKey = 'null';
    }
    this.refreshIotAgentList();
  };

  refreshIotAgentList = () => {
    this.getIot.getAllDomotzAgents(
      this.currentCompanyId,
      this.searchKey,
      this.totalPageLimit,
      this.paginateOjb.startIndex,
      this.filterLink
    ).subscribe((data) => {
      this.iotAgentList = data;
      this.dataSource = new MatTableDataSource(this.iotAgentList);
    });
  };

}
