<div class="container-fluid" id="main-box" [ngStyle]="{ 'background-image': 'url(' + bgImage + ')' }">

	<app-term-and-condition-modal *ngIf="showModal" (closeModal)="showModal = false"></app-term-and-condition-modal>

	<div class="row">
		<div class="col-12">
			<div class="signup" >
				<!-- <form (submit)="loginUser($event)" role="form" class="signup"> -->
				<form *ngIf="!sso?.code" (submit)="loginUser($event)" role="form" name="loginForm" [formGroup]="loginForm"
					(ngSubmit)="onSubmit()">
					<div class="form-group">
						<img [src]="loginImage">
					</div>
					<h4>
						<p>{{dLoginTitle}}</p>
					</h4>

					<img class="success_login" id="thankYou" src="../../../../assets/img/icon_llama.png">
					<h5 id="thankYouText" class="text-center text-success">Thank you for registering!</h5>
					<div class="form-group">
						<!-- <input type="email" class="form-control" id="username" placeholder="email" required /> -->
						<input type="email" formControlName="email" class="form-control" value="email" placeholder="email"
							name="email" id="username" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
							placeholder="email" required />
						<div *ngIf="submitted && f.email.errors" class="invalid-feedback" id="invalid-feedback">
							<!-- <div *ngIf="f.email.errors.required">email is required</div> -->
							<div *ngIf="f.email.errors.required" id="confirmMessage">!</div>
						</div>
					</div>
					<div class="form-group">
						<!-- <input type="password" class="form-control" id="password" placeholder="password" required /> -->
						<input type="password" formControlName="password" class="form-control" value="password"
							autocomplete="current-password" placeholder="password" name="password" id="password"
							[ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="password" required />
						<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
							<!-- <div *ngIf="f.password.errors.required">password is required</div> -->
							<div *ngIf="f.password.errors.required" id="confirmMessage">!</div>
						</div>
					</div>
					<div id="feedback" class="text-center text-danger" *ngIf="loginFeedback">{{loginFeedback}}</div>
					<button type="submit" class="btn-success" style="width:100%;">
						Login
					</button>
					<div class="form-group terms text-center pt-2">
						<a (click)="openModal()" target="_blank">{{dTerms}}</a>
					</div>
					<button *ngIf="sso.enabled" type="button" class="btn btn-block btn-outline-primary mb-2" (click)="onClickSSOLogin()">
						<i class="fa fa-key" aria-hidden="true"></i>
						SSO
					</button>
					<div class="form-group terms text-center pt-2">
						<a routerLink="/adminforgotpassword">Forgot password?</a>
					</div>
					<div *ngIf="showPoweredBy" class="poweredby">
						Powered by VisibilityOne
					</div>

				</form>
				<ng-container *ngIf="sso?.code">
					<div class="text-center" *ngIf="sso?.errorMsg">
					<h2 class="text-center mb-5">Oops!</h2>
					<p>An unexpected error occurred during the sign-in process:</p>
					<p class="text-danger">{{ sso?.errorMsg }}</p>
					<p>
						Please accept our apologies for any inconvenience caused. To resolve this issue, kindly fix the problem or
						contact our support team.
					</p>
					<p>Click <a routerLink="/">here</a> to reattempt the sign-in process.</p>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>