<!-- <div class="modal" tabindex="-1" role="dialog" #myModal data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Switch Domotz</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Are you sure you want to switch to MSP DOMOTZ? This action will result in the removal of
          all existing Domotz records for the company
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onYes()">Yes</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onClose()">No</button>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="domotz_dialog">
  <h2 mat-dialog-title>Switch Domotz</h2>
  <mat-dialog-content
    >Are you sure you want to switch to MSP DOMOTZ? This action will result in the removal of all
    existing Domotz records for the company</mat-dialog-content
  >
  <mat-dialog-actions>
    <button mat-button mat-dialog-close (click)="onClose()">No</button>
    <button mat-button [mat-dialog-close]="true" (click)="onYes()">Yes</button>
  </mat-dialog-actions>
</div> -->

<div class="domotz_dialog">
  <div class="domotz-dialog__body">
    <div class="domotz-dialog__text-button-container">
      <div>
        Are you sure you want to switch MSP DOMOTZ? This action will result in the permanent deletion of all
        existing Domotz records for the company
      </div>
      <div class="domotz-dialog__button-container">
        <div class="domotz-dialog__button-item domotz-dialog__button-item--yes" (click)="onYes()">
          Yes
        </div>
        <div
          class="domotz-dialog__button-item domotz-dialog__button-item--cancel"
          (click)="onClose()"
        >
          Cancel
        </div>
      </div>
    </div>
  </div>
</div>
