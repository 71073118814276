import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  bgImage = environment.config.dynamicImage.bgImage;
  loginImage = environment.config.dynamicImage.loginImage;
  resetForm: FormGroup;
  submitted: boolean = false;
  isFirstSubmit: boolean = false;
  isPasswordNotMatched: boolean = false;
  id: any;

  getInfoData: any;
  valid = -1;
  email: any;
  password: any;

  sentRequest: any = false;
  responseMessage = '';

  resetResponseData: any;
  resetResponseFailed: any;
  resetResponseMessage: any;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    public http: HttpClient,
    private Auth: AuthService,
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      console.log(this.id);

    });
  }
  get f() {
    return this.resetForm.controls;
  }
  ngOnInit(): void {
    this.validateForm();
    this.resetForm.get('password1').valueChanges.subscribe(() => {
      this.validatePasswordsMatch();
    });
    this.getResetInfo();
  }
  validateForm() {
    this.resetForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      password1: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  validatePasswordsMatch() {
    const password = this.resetForm.get('password').value;
    const confirm_password = this.resetForm.get('password1').value;

    if (password !== confirm_password) {
      this.isPasswordNotMatched = true;
    } else {
      this.password = password;
      this.isPasswordNotMatched = false;
    }
  }

  getResetInfo() {
    this.Auth.getResetCodeInfo(this.id).subscribe((data) => {
      this.getInfoData = data;
      console.log(this.getInfoData, "this.getResetInfo();");

      if (this.getInfoData.response[0].status == 'Ok') {
        this.valid = 1;

        this.email = this.getInfoData.response[0].email;
      } else {
        this.valid = 0;
      }
      console.log("valid", this.valid);
    });
  }


  onSubmit() {
    this.submitted = true;
    if (this.resetForm.valid && !this.isPasswordNotMatched) {
      if (this.getInfoData.response[0].status === 'Ok') {
        const resetData = {
          password: this.password,
          code: this.id,
          email: this.getInfoData.response[0].email,
        };

        this.Auth.changePassword(resetData).subscribe((data) => {
          if (data['response'][0].data.status.toLowerCase() === 'ok') {
            this.sentRequest = true;
            this.resetResponseData = data;
            this.resetResponseFailed = 0;
          } else if (data['response'][0].data.status.toLowerCase() === 'failure') {
            if (data['response'][0].data.message.includes("old")) {
              this.responseMessage = data['response'][0].data.message;
            } else {
              this.sentRequest = true;
              this.resetResponseData = data;
              this.resetResponseFailed = 1;
              this.resetResponseMessage = data['response'][0].data.message;
            }
          }
        });
      }
    }

  }

}