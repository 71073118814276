import { Component, OnInit, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';


@Component({
  selector: 'app-table-oncall-icon',
  templateUrl: './table-oncall-icon.component.html',
  styleUrls: ['./table-oncall-icon.component.scss']
})
export class TableOnCallIconComponent implements ViewCell, OnInit {

  renderValue: any;
  
  @Input() value: string | number;
  @Input() rowData: any;
  constructor() { }

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

}
