import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
 
@Injectable({
    providedIn: 'root'
})
export class PopupTypeService {
    private subject = new Subject<any>();
 
    sendPopupType(message: number, data?: any) {
        let t = {
            'popupId': message, 
            'data': data,
            ...data
        }
        this.subject.next(JSON.stringify(t));
    }
 
    clearPopupType() {
        this.subject.next();
    }
 
    getPopupType(): Observable<any> {
        return this.subject.asObservable();
    }
}