import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GetAppManagerService } from '../../services/get-app-manager.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-desktop-plugin-update-schedule',
  templateUrl: './desktop-plugin-update-schedule.component.html',
  styleUrls: ['./desktop-plugin-update-schedule.component.scss'],
})
export class DesktopPluginUpdateScheduleComponent implements OnInit {
  @Output() closePluginScheduleUpdateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() scheduleUpdateData: any;
  emailVal: String = ' ';
  dateVal: any;
  emailNoteVal: String = ' ';
  checkBoxVal: any;

  emailResponse: String = 'Email Invalid';
  dateMsg: String = 'Date Invalid';
  showEmailErrMsg: Boolean = false;
  showDateErrMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  showNoteSuccessMsg: Boolean = false;
  testBtnDisabled: Boolean = false;
  minDate: Date;
  constructor(private appManager: GetAppManagerService) { }

  ngOnInit() {
    this.setEmailAndCheckbox();
    this.setDateLabel();
    this.setMinDate();
  }

  setMinDate() {
    const dayAftertomorrowDate = moment().add(2, 'day');
    this.minDate = dayAftertomorrowDate.toDate()
  }

  setDateLabel = () => {
    const { update_schedule } = this.scheduleUpdateData;
    if (update_schedule !== null && update_schedule.trim().length !== 0) {
      const momentData = moment(update_schedule)
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format();
      this.dateVal = momentData.split(':')[0] + ':' + momentData.split(':')[1];
    }
  };

  setEmailAndCheckbox = () => {
    const { email_notes, include_release_notes } = this.scheduleUpdateData;
    console.log('this.scheduleUpdateData:', this.scheduleUpdateData);
    this.emailNoteVal = email_notes;
    this.checkBoxVal = include_release_notes === 1 ? true : false;
  };

  closeScheduleUpdateModal() {
    this.closePluginScheduleUpdateEvent.emit('closeZoomDesktopPluginScheduleUpdate');
  }

  onKeyEmail = (event: any) => {
    this.emailVal = event.target.value;
  };

  onKeyEmailNote = (event: any) => {
    this.emailNoteVal = event.target.value;
  };

  updatePluginSchedule() {
    const uDate = (<HTMLInputElement>document.getElementById('schedule-date')).value;
    const dateArr = new Date(uDate).toString().split(' ');
    const validate = this.validateForm(dateArr, true, new Date(uDate) > new Date());
    if (validate) {
      const sDate = uDate.split('T');
      const scheduleDate = moment(sDate[0] + ' ' + sDate[1])
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format();
      this.appManager
        .setZoomDesktopPluginVersionUpdateSchedule(this.scheduleUpdateData.version, scheduleDate)
        .subscribe((data) => {
          console.log(data);
          this.closePluginScheduleUpdateEvent.emit('closeZoomDesktopPluginScheduleUpdate');
          this.closePluginScheduleUpdateEvent.emit('getZoomDesktopPluginVersionList');
        });
    }
  }

  sendTestEmail = () => {
    const uDate = (<HTMLInputElement>document.getElementById('schedule-date')).value;
    const dateArr = new Date(uDate).toString().split(' ');
    const formatEmailNoteVal = this.emailNoteVal !== null ? this.emailNoteVal.trim() : '';
    const validate = this.validateForm(
      dateArr,
      this.validateEmail(this.emailVal),
      new Date(uDate) > new Date()
    );
    if (validate) {
      const sDate = uDate.split('T');
      const scheduleDate = moment(sDate[0] + ' ' + sDate[1])
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format();

      this.appManager
        .sendZoomDesktopPluginTestEmail(
          this.emailVal,
          formatEmailNoteVal,
          this.checkBoxVal,
          scheduleDate,
          this.scheduleUpdateData.version
        )
        .subscribe((data) => {
          console.log('data:', data);
          this.emailResponse = data.message;
          this.showSuccessMsg = true;
          this.testBtnDisabled = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.testBtnDisabled = false;
          }, 2000);
        });
    }
  };

  validateForm = (date: any, bool: any, dateCompare: any) => {
    this.showEmailErrMsg = false;
    this.showDateErrMsg = false;
    let count = 0;
    if (date[0] === 'Invalid') {
      this.dateMsg = 'Date Invalid';
      this.showDateErrMsg = true;
      count++;
    } else {
      if (!dateCompare) {
        this.showDateErrMsg = true;
        this.dateMsg = 'Date must be after Date today';
        count++;
      }
    }
    if (!bool) {
      this.showEmailErrMsg = true;
      count++;
    }

    return count === 0;
  };

  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const regexEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEx.test(String(email).toLowerCase());
  }

  includeReleaseNotes = (event) => {
    this.checkBoxVal = event.target.checked;
  };

  saveNotes = () => {
    const includeReleaseNotesVal = this.checkBoxVal ? '1' : '0';
    this.appManager
      .updateZoomPluginEmailNotes(
        this.scheduleUpdateData.version,
        this.emailNoteVal,
        includeReleaseNotesVal
      )
      .subscribe((data) => {
        this.showNoteSuccessMsg = true;
        setTimeout(() => {
          this.showNoteSuccessMsg = false;
        }, 2000);
        this.closePluginScheduleUpdateEvent.emit('getZoomDesktopPluginVersionList');
      });
  };
}
