<form #f="ngForm" class="container" [formGroup]="phoneForm">
	<div class="row justify-content-md-center vertical-center">
		<img [src]="dashboardLogo" class="col-6" />
	</div>
	<div class="row justify-content-md-center add-margin">
		<ngx-intl-tel-input
			[cssClass]="'form-control'"
			[preferredCountries]="preferredCountries"
			[enableAutoCountrySelect]="true"
			[enablePlaceholder]="true"
			[searchCountryFlag]="true"
			[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
			[selectFirstCountry]="false"
			[selectedCountryISO]="CountryISO.UnitedStates"
			[tooltipField]="TooltipLabel.Name"
			[phoneValidation]="true"
			[separateDialCode]="separateDialCode"
			name="phone"
			formControlName="phone">
		</ngx-intl-tel-input>
	</div>
	<div class="row justify-content-md-center">
		<pre>Note: The phone number you saved before was in invalid format. Please input a new one with + extension.</pre>
	</div>
	<div class="row justify-content-md-center mt-2">
		<pre>After clicking the save button, wait for few seconds and you will be redirected to two factor auth page.</pre>
	</div>
	<div class="row justify-content-md-center">
		<pre class="red-color">{{ errorMessage }}</pre>
	</div>
	<div class="row justify-content-md-center">
		<button class="col-4 btn btn-primary" (click)="saveAndSend(f)" [disabled]="isBusy">Save and Send Verification
			Code</button>
	</div>
	<div class="row justify-content-md-center">
		<button class="col-4 btn btn-secondary mt-2" (click)="cancel()" [disabled]="isBusy">Cancel</button>
	</div>
</form>