import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { UserInfoService } from '../services/user-info.service';
import { map } from 'rxjs/operators';
import phone from 'phone';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserIdleService } from 'angular-user-idle';
import { UserIdleDialogComponent } from '../dialog/user-idle-dialog/user-idle-dialog.component';

interface myData {
  response: {
    'token': string;
    'error': string;
  };
}
interface statusMessagerData {
  response: {
    'status': string;
    'message': string;
  };
}

interface newDeviceData {
  response: {
    'status': string;
    'message': string;
    'action_id': number;
  };
}

@Injectable()
export class Globals {
  role: string = 'test';
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  getUrlPath: Function = environment.apiServicePathBuilder('iot');
  get2faUrlPath: Function = environment.apiServicePathBuilder('two-factor');
  getUrlPathSSO:  Function = environment.apiServicePathBuilder('ssogam');
  server_url: string = environment.config.serverUrl;
  authOption: any = '';

  timerCountBehaviorSubject: BehaviorSubject<number> = new BehaviorSubject(0)
  userIdleSubscription1: Subscription;
  userIdleSubscription2: Subscription;

  constructor(
    private http: HttpClient,
    private userInfo: UserInfoService,
    private router: Router,
    private userIdle: UserIdleService,
    public dialog: MatDialog
    ) {
      if (this.isAuthenticated()) {
        this.checkForUserInactivity()
      }
    }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  updateInstantLoginToken(instant_login_token: string, company_id: number) {
    const params = { company_id, instant_login_token };

    return this.http.post<any>(
      this.server_url + '/api/v1/admin/updateInstantLoginToken',
      params,
      this.httpOptions
    );
  }

  public isAuthenticated(): boolean {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');

    const helper = new JwtHelperService();

    return !helper.isTokenExpired(token);
  }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const { company_id } = helper.decodeToken(token);
    return company_id;
  };

  getDomotzAccountByCompanyId(company_id) {
    // const company_id = this.getCompanyId();
    const url: string = this.getUrlPath(`getDomotzAccountByCompanyId/${company_id}`);
    return this.http.get<any>(url).map(this.extractData).catch(this.handleError);
  }
  
  deleteDomotzAccountOfAllChildren(company_id) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const url = this.server_url + '/api/v1/domotz/deleteDomotzAccountOfAllChildren';
    const body = { company_id };
    return this.http.post<{
      domotzRecords: Array<number>;
    }>(url, body, { headers: headers });
  }

  deleteDomotzAccount(company_id) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const url = this.server_url + '/api/v1/domotz/deleteDomotzAccount';
    const body = { company_ids : [company_id] };
    return this.http.post<void>(url, body, { headers: headers });
  }

  getUserDetails(usernamebody, passwordbody, enable2Fa) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const body = JSON.stringify({ username: usernamebody, password: passwordbody });
    // Logins into the remote server to grab the User Creditinals and Token
    const apiUrl = '/api/v1/admin/login';
    const url = this.server_url + apiUrl;
    return this.http.post<myData>(url, body, { headers: headers }).pipe(map((data) => {
      this.checkForUserInactivity()
      return this.onSuccessfullLogin(data, true)
    }))
  }

  onSuccessfullLogin(data: any, enable2Fa) {
          // localStorage.setItem('token', data.response.token);
      // console.log(localStorage.getItem('token'));
      console.log(data.response)
      
      localStorage.setItem('token', data.response.token);
      
      
      if (data.response[0] == undefined) {
        // Grabs Tokens for User from Server and Stores it in Local Storage
        localStorage.setItem('token', data.response.token);
        const token = localStorage.getItem('token');
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);
        this.authOption = decodedToken.two_factor_auth_option;
        const expirationDate = helper.getTokenExpirationDate(token);
        const isExpired = helper.isTokenExpired(token);
        if (!helper.isTokenExpired(token)) {
          localStorage.setItem('retryAttempts', '0');
          localStorage.setItem('phone', decodedToken.phone);
          if (enable2Fa) {
            if (decodedToken.enable_two_factor_auth) {
              localStorage.setItem('verified', 'false');
              const phoneLib = phone(decodedToken.phone, {validateMobilePrefix: true});
              if (decodedToken.two_factor_auth_option === "sms" && !phoneLib.isValid) {
                this.router.navigate(['/add-phone-number']);
              } else {
                localStorage.setItem('verified', 'false');
                const sendAddress = decodedToken.two_factor_auth_option === 'email' ? decodedToken.email : decodedToken.phone;
                this.router.navigate(['/two-factor-auth']);
                this.sendVerificationCode(environment.config.twilioServiceId, sendAddress, decodedToken.two_factor_auth_option);
              }
            } else {
              decodedToken.verifiedCode = true;
              localStorage.setItem('verified', 'true');
              this.router.navigate(['/']);
              this.userInfo.init();
            }
          } 
        }
      } else {
        if (data.response[0].status === 'Error') {
          // this.loginFeedback = data.response[0].message;
          throw new Error(data.response[0]?.message)
        } else {
          localStorage.setItem('successReg', '0');
        }
      }
  }

  logginOut(message: string) {
    this.messageSource.next(message);
  }

  adminViewUserAs(userId) {
    // post these details to the API server return user info if correct
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const body = JSON.stringify({ user_id: userId });
    // Logins into the remote server to grab the User Creditinals and Token
    const url = this.server_url + '/api/v1/admin/userViewAs';
    return this.http.post<myData>(url, body, { headers: headers });
  }

  superAdminViewUserAs() {
    // post these details to the API server return user info if correct
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const body = JSON.stringify({ user_id: decodedToken.user_id });
    // Logins into the remote server to grab the User Creditinals and Token
    const url = this.server_url + '/api/v1/admin/userViewAs';
    return this.http.post<myData>(url, body, { headers: headers });
  }

  adminLoginToken(userToken) {
    // post these details to the API server return user info if correct
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const body = JSON.stringify({ token: userToken });

    // Logins into the remote server to grab the User Creditinals and Token
    const url = this.server_url + '/api/v1/user/loginToken';
    return this.http.post<myData>(url, body, { headers: headers }).pipe(
			map(resp => {
				this.checkForUserInactivity()
				return resp
			})
		)
  }

  updateCompanyLogo(company_id, logo) {
    const fd = new FormData();
    fd.append('companyImage', logo, 'image');
    fd.append('company_id', company_id);

    // Logins into the remote server to grab the User Creditinals and Token
    const url = this.server_url + '/api/v1/user/uploadTest';
    return this.http.post<myData>(url, fd, {
      reportProgress: true,
      observe: 'events',
    });
  }

  //This function send user a Reset Password email
  sendPasswordReset(userEmail: any): Observable<Object> {
    let postData = {
      email: userEmail,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/admin/sendPasswordReset',
      postData,
      this.httpOptions
    );
  }

  // This route gets the all the company list
  getCompanyList(loginType: string = 'integrator', companyId: number = 0) {

    let endPointUrl = this.server_url + '/api/v1/admin/getCompanyListForIntegrator';

    const data = {
      company_id: companyId,
    };

    if (loginType === 'superAdmin')
      endPointUrl = this.server_url + '/api/v1/admin/getCompanyList';

    return this.http.post<any>(endPointUrl, data, this.httpOptions);
  }

  // This route gets the company details
  getCompanyInfo(companyId: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/getCompanyInfo',
      companyId,
      this.httpOptions
    );
  }

  // This route gets the current user details
  getCurrentUserInfo(userDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/getUserInfo',
      userDetails,
      this.httpOptions
    );
  }

  // This route gets the company's user list details
  getUserList(companyId: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/getUserList',
      companyId,
      this.httpOptions
    );
  }

  // This route gets the company's user list details
  getCollectorList(companyId: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/getCollectorList',
      companyId,
      this.httpOptions
    );
  }

  // This route gets the all the trial reports
  getReportTrials(loginType: string = null, company_id: number = null) {
    let url = this.server_url + '/api/v1/admin/reportTrials';
    const params = {
      company_id,
    };

    if (loginType === 'integrator') {
      url = this.server_url + '/api/v1/admin/reportTrialsForIntegrator';
      params.company_id = params.company_id * -1;
    }

    return this.http.post<any>(url, params, this.httpOptions);
  }

  // This route gets the all the subrcription reports
  getReportSubscriptions(loginType: string = null, company_id: number = null) {
    let url = this.server_url + '/api/v1/admin/reportSubscriptions';
    const params = {
      company_id,
    };

    if (loginType === 'integrator') {
      url = this.server_url + '/api/v1/admin/reportSubscriptionsForIntegrator';
      params.company_id = params.company_id * -1;
    }

    return this.http.post<any>(url, params, this.httpOptions);
  }

  // This route gets the all the inactive reports
  getReportInactive(loginType: string = null, company_id: number = null) {
    let url = this.server_url + '/api/v1/admin/reportInactive';
    const params = {
      company_id,
    };

    if (loginType === 'integrator') {
      url = this.server_url + '/api/v1/admin/reportInactiveForIntegrator';
      params.company_id = params.company_id * -1;
    }
    return this.http.post<any>(url, params, this.httpOptions);
  }

  // This route gets the company's status updated
  updateStatus(companyStatus: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/updateStatus',
      companyStatus,
      this.httpOptions
    );
  }

  // This route gets the company's trial end date updated
  extendTrialDate(companyTdateUpd: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/extendTrial',
      companyTdateUpd,
      this.httpOptions
    );
  }

  // This route gets the company's subscription end date updated
  extendSubscriptionDate(companySdateUpd: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/extendSubscription',
      companySdateUpd,
      this.httpOptions
    );
  }

  // This route gets the company's subscription end date updated
  getTrialExportDownload(loginType: string = null, company_id: number = null) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    let url = this.server_url + '/api/v1/admin/downloadReportTrials';

    if (loginType === 'integrator') {
      company_id = company_id * -1;
      url =
        this.server_url +
        '/api/v1/admin/downloadReportTrialsForIntegrator?company_id=' +
        company_id;
    }

    return this.http.get(url, { responseType: 'arraybuffer', headers: headers });
  }

  // This route gets the company's subscription end date updated
  getSubscriptionExportDownload(loginType: string = null, company_id: number = null) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    let url = this.server_url + '/api/v1/admin/downloadReportSubscriptions';

    if (loginType === 'integrator') {
      company_id = company_id * -1;
      url =
        this.server_url +
        '/api/v1/admin/downloadReportSubscriptionsForIntegrator?company_id=' +
        company_id;
    }

    return this.http.get(url, { responseType: 'arraybuffer', headers: headers });
  }

  // This route gets the company's subscription end date updated
  getInactiveExportDownload(loginType: string = null, company_id: number = null) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    let url = this.server_url + '/api/v1/admin/downloadReportInactive';

    if (loginType === 'integrator') {
      company_id = company_id * -1;
      url =
        this.server_url +
        '/api/v1/admin/downloadReportInactiveForIntegrator?company_id=' +
        company_id;
    }

    return this.http.get(url, { responseType: 'arraybuffer', headers: headers });
  }

  addCompany(addCompanyData: any) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/addCompany',
      addCompanyData,
      this.httpOptions
    );
  }

  deleteCompany(company_id: any) {
    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const postData = {
      company_id,
      email: decodedToken.email,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/deleteCompany',
      postData,
      this.httpOptions
    );
  }

  updateCmpWebView(webVCompanyData: any) {
    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/updateWebView',
      webVCompanyData,
      this.httpOptions
    );
  }

  linkDomotzAgent(linkDomotzAgentData: any) {
    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/linkDomotzAgentSubVendor',
      linkDomotzAgentData,
      this.httpOptions
    );
  }

  unlinkDomotzAgent(unlinkDomotzAgentData: any) {
    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/unlinkDomotzAgentSubVendor',
      unlinkDomotzAgentData,
      this.httpOptions
    );
  }

  checkIfDomotzAgent(companyId: number, childCompanyId: number) {
    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    const body = {
      company_id: companyId,
      child_company_id: childCompanyId
    }
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/checkIfDomotzAgentSubVendor',
      body,
      this.httpOptions
    );
  }

  updateCmpMspNotification(MspNCompanyData: any) {
    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/updateMspNotification',
      MspNCompanyData,
      this.httpOptions
    );
  }

  updateTeamSpaces(spacesData: any) {
    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/updateTeamSpaceStatus',
      spacesData,
      this.httpOptions
    );
  }

  updateMSPDomotz(mspDomotzData: any) {
    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/updateMspDomotzStatus',
      mspDomotzData,
      this.httpOptions
    );
  }

  updateSingleDomotz(singleDomotzData: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/updateSingleDomotzStatus',
      singleDomotzData,
      this.httpOptions
    );
  }

  updatecmpMspEmail(MspEmailCompanyData: any) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/updateMspEmail',
      MspEmailCompanyData,
      this.httpOptions
    );
  }

  setIntegrator(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/setIntegrator',
      data,
      this.httpOptions
    );
  }

  async updateUserProfile(data: any) {
    const response = await new Promise((resolve, reject) => {
      return this.http.post<any>(this.server_url + '/api/v1/user/updateInfo', data, this.httpOptions).subscribe((data) => {
        resolve(data);
      });
    });
    return response;
  }
  async sendVerificationCode(serviceId, to, channel = 'email') {
    const url = this.get2faUrlPath(`sendVerificationCode`);
    const body = {
      service_id: serviceId,
      to: to,
      channel: channel
    };
    const response = await new Promise((resolve, reject) => {
      return this.http.post<any>(url, body, this.httpOptions).subscribe((data) => {
        resolve(data);
      });
    })
    return response;
  }

  async checkVerificationCode(serviceId, to, code) {
    const url = this.get2faUrlPath(`checkVerificationCode`);
    const body = {
      service_id: serviceId,
      to: to,
      code: code
    };
    const response = await new Promise((resolve, reject) => {
      return this.http.post<any>(url, body, this.httpOptions).subscribe((data) => {
        resolve(data);
      });
    })
    return response;
  }

  updateTwoFactorAuth(data) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/updateTwoFactorAuth',
      data,
      this.httpOptions
    );
  }

  getCompanyIdsToAdd(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/getCompanyIdsToAdd',
      data,
      this.httpOptions
    );
  }

  setCompanyMemberIds(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/setCompanyMemberIds',
      data,
      this.httpOptions
    );
  }

  setDirectAccess(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/setDirectAccess',
      data,
      this.httpOptions
    );
  }

  getActiveCollectorCount() {
    const { loginType, company_id } = this.userInfo.getInfo();

    const params = {
      company_id: loginType == 'superAdmin' ? -1 : company_id
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getActiveCollectorCount',
      params,
      this.httpOptions
    );
  }



  getCollectorVersionList() {
    const { loginType, company_id } = this.userInfo.getInfo();
    const params = {
      company_id: loginType == 'superAdmin' ? -1 : company_id
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getCollectorVersionList',
      params,
      this.httpOptions
    );
  }



  getMacCollectorVersionList() {
    const { loginType, company_id } = this.userInfo.getInfo();
    const params = {
      company_id: loginType == 'superAdmin' ? -1 : company_id
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/collector/getMacCollectorVersionList',
      params,
      this.httpOptions
    );
  }

  updateCollecterReleaseNotes(
    _collector_version_id: string,
    _release_notes: string,
    _version: string
  ) {
    const url = this.server_url + '/api/v1/collector/updateCollectorReleaseNotes';
    const params = {
      collector_version_id: _collector_version_id,
      release_notes: _release_notes.trim(),
      version: _version,
    };

    return this.http.post<any>(url, params, this.httpOptions);
  }

  sendTestEmailForCollectorVersionUpdateSchedule(
    _email: any,
    _emailNote: any,
    _includeReleaseNotes: any,
    _updateSchedule: any,
    _cVersion: any
  ) {
    const url =
      this.server_url + '/api/v1/collector/sendTestEmailForCollectorVersionUpdateSchedule';
    const params = {
      email: _email,
      emailNote: _emailNote,
      includeReleaseNotes: _includeReleaseNotes,
      updateSchedule: _updateSchedule,
      cVersion: _cVersion,
    };

    return this.http.post<any>(url, params, this.httpOptions);
  }

  setCollectorVersionUpdateSchedule(_version: any, _date: any) {
    const url = this.server_url + '/api/v1/collector/setCollectorVersionUpdateSchedule';
    const params = {
      version: _version,
      date: _date,
    };
    return this.http.post<any>(url, params, this.httpOptions);
  }

  setMacCollectorVersionUpdateSchedule(_version: any, _date: any) {
    const url = this.server_url + '/api/v1/collector/setMacCollectorVersionUpdateSchedule';
    const params = {
      version: _version,
      date: _date,
    };
    return this.http.post<any>(url, params, this.httpOptions);
  }


  saveNotes(_version: any, _email_notes: any, _include_release_notes: any) {
    const url = this.server_url + '/api/v1/collector/saveNotes';
    const params = {
      version: _version,
      email_notes: _email_notes,
      include_release_notes: _include_release_notes,
    };
    return this.http.post<any>(url, params, this.httpOptions);
  }

  getColletorUpdateStatus() {
    return this.http.get<any>(
      this.server_url + '/api/v1/collector/getCollectorUpdateOpenStatusCount',
      this.httpOptions
    );
  }


  getMacCollectorUpdatePendingList(version: any, company_id, is_integrator) {
    const { loginType } = this.userInfo.getInfo();

    const url = this.server_url + '/api/v1/collector/getMacCollectorUpdatePendingList';
    const params = {
      version,
      company_id: loginType == 'superAdmin' ? -1 : company_id,
      is_integrator
    };
    return this.http.post<any>(url, params, this.httpOptions);
  }




  getMacCollectorUpdateInstalledList(version: any, company_id, is_integrator) {
    const url = this.server_url + '/api/v1/collector/getMacCollectorUpdateInstalledList';


    const { loginType } = this.userInfo.getInfo();

    const params = {
      version,
      company_id: loginType == 'superAdmin' ? -1 : company_id,
      is_integrator
    };

    return this.http.post<any>(url, params, this.httpOptions);
  }

  getMacCollectorUpdateActionFailedList(version: any, company_id, is_integrator) {
    const url = this.server_url + '/api/v1/collector/getMacCollectorUpdateActionFailedList';


    const { loginType } = this.userInfo.getInfo();

    const params = {
      version,
      company_id: loginType == 'superAdmin' ? -1 : company_id,
      is_integrator
    };

    return this.http.post<any>(url, params, this.httpOptions);
  }

  getCollectorUpdateInstalledList(version: any, company_id) {
    const url = this.server_url + '/api/v1/collector/getCollectorUpdateInstalledList';
    const { loginType } = this.userInfo.getInfo();
    const params = {
      version,
      company_id: loginType == 'superAdmin' ? -1 : company_id,
    };
    return this.http.post<any>(url, params, this.httpOptions);
  }

  getCollectorUpdatePendingList(version: any, company_id) {
    const { loginType } = this.userInfo.getInfo();
    const url = this.server_url + '/api/v1/collector/getCollectorUpdatePendingList';
    const params = {
      version,
      company_id: loginType == 'superAdmin' ? -1 : company_id,
    };
    return this.http.post<any>(url, params, this.httpOptions);
  }


  getCollectorUpdateActionFailedList(version: any, company_id) {
    const url = this.server_url + '/api/v1/collector/getCollectorUpdateActionFailedList';
    const { loginType } = this.userInfo.getInfo();
    const params = {
      version,
      company_id: loginType == 'superAdmin' ? -1 : company_id,
    };
    return this.http.post<any>(url, params, this.httpOptions);
  }




  countriesRest() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>('https://api.first.org/data/v1/countries', httpOptions);
  }

  // FOR NOW

  getAllCountryList() {
    return this.http.post<any>(
      this.server_url + '/api/v1/company/getCountriesList',
      this.httpOptions
    );
  }

  getAllStatesList(country_id: any) {
    const postData = {
      country_id: country_id,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/company/getStatesList',
      postData,
      this.httpOptions
    );
  }

  updateCompanyStartDate(company_id: number, start_date: any) {
    const postData = {
      company_id,
      start_date,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/updateCompanyStartDate',
      postData,
      this.httpOptions
    );
  }

  resendRegisterInvite(inviteDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/user/resendUserRegistration',
      inviteDetails,
      this.httpOptions
    );
  }

  updateCompany(data: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/updateCompany',
      data,
      this.httpOptions
    );
  }

  setCompanyBeingDeleted(company_id, company_name) {
    const postData = {
      company_id,
      company_name,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/admin/beingDeleted',
      postData,
      this.httpOptions
    );

  }

  getResetCodeInfo(resetCode: any): Observable<Object> {
    let postData = {
      code: resetCode,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/admin/getPasswordResetInfo',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
    // return this.http.post<any>( 'http://127.0.01:8080/api/v1/user/getPasswordResetInfo', postData, this.httpOptions).map(this.extractData)
    // .catch(this.handleError)
  }

  // this ur; reset the password
  changePassword(resetData: any): Observable<Object> {
    let postData = {
      ...resetData,
    };
    console.log(resetData);

    return this.http
      .post<any>(
        this.server_url + '/api/v1/admin/resetPassword',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
    // return this.http.post<any>( 'http://127.0.01:8080/api/v1/user/resetPassword', postData, this.httpOptions).map(this.extractData)
    // .catch(this.handleError)
  }


//SSO 
isGamEnabled = () => {
  const company_id = this.getCompanyId()
  const url: string = this.getUrlPathSSO(`isGamEnabled?company_id=${company_id}`)
  
  return this.http.get<any>(url).pipe(map(this.extractData))
}

setGamEnabled = ( uses_gam, company_id) => {
  const url: string = this.getUrlPathSSO(`setGamEnabled`)
  const data = {
    company_id,
    uses_gam
  }
  
  console.log('setGamEnabled: ', data)
  return this.http.post<any>(url, data, this.httpOptions).pipe(map(this.extractData))
}

setEnableInstantLogin = (flag, company_id) => {
  return this.http.post<any>(this.server_url + '/api/v1/admin/setEnableInstantLogin', { flag, company_id }, this.httpOptions);
}

logout() {
  const { enabled, clientId, clientSecret, redirectURI, GAMBaseUri } = environment.config['SSO'] || {}
  
  const GAMToken = localStorage.getItem('GAMToken')
  const GAMState = localStorage.getItem('ssoGAMLoginState')
  if (enabled && GAMToken) {
    // const body = {
    //   GAM_token: GAMToken,
    //   GAM_state: GAMState
    // }
    // const url: string = this.getUrlPathSSO(`logoutGAM`)
    // this.http.post<any>(url, body).pipe(
    //   finalize(() => {
    //     // Code to execute when the HTTP request completes (whether success or error)
    //     this.clearStorageAndNavigate(true)
    //   })
    // ).subscribe(() => {})
    const params = new HttpParams()
      .set('oauth', 'signout')
      .set('client_id', clientId)
      .set('client_secret', clientSecret)
      .set('redirect_uri', redirectURI)
      .set('server_ip', 1)
      .set('token', GAMToken)
      .set('first_call', 1)
      .set('state', GAMState);
    
    console.log('GAM logout endpoint: ', `${GAMBaseUri}/signout?${params.toString()}`)
    // this.http
    // 	.get<any>(`${baseURL}/oauth/gam/signout`, { params })
    // 	.pipe(
    // 		finalize(() => {
    // 			// Code to execute when the HTTP request completes (whether success or error)
    // 			this.clearStorageAndNavigate(true)
    // 		})
    // 	)
    // 	.subscribe(() => {})
    this.clearStorageAndNavigate(false)
    window.location.href = `${GAMBaseUri}/signout?${params.toString()}`
  } else this.clearStorageAndNavigate(true)
}

clearStorageAndNavigate(shouldNavigate) {
  this.userIdle.stopTimer()
  this.userIdle.stopWatching()
  this.userIdleSubscription1?.unsubscribe()
  this.userIdleSubscription2?.unsubscribe()
  localStorage.removeItem('token')
  localStorage.removeItem('GAMToken')
  localStorage.removeItem('GAMState')
  localStorage.removeItem('phone');
  localStorage.removeItem('verified');
  localStorage.setItem('keyDownload', '0')
  localStorage.setItem('reloadSiteData', '0')
  localStorage.setItem('showCongrats', '0')
  localStorage.setItem('runTut', '0')
  localStorage.setItem('successReg', '0')
  localStorage.setItem('overLayMembersMove', '0')
  if (shouldNavigate) this.router.navigate(['adminlogin'])
}

checkForUserInactivity() {
  //Start watching for user inactivity.
  this.userIdle.startWatching()

  if (!this.userIdleSubscription1 || this.userIdleSubscription1?.closed) {
    // Start watching when user idle is starting.
    this.userIdleSubscription1 = this.userIdle.onTimerStart().subscribe(count => {
      // console.log('count', count)
      this.timerCountBehaviorSubject.next(count)
      if (count === 1) this.openUserIdleModal()
    })
  }

  if (!this.userIdleSubscription2 || this.userIdleSubscription2?.closed) {
    // Start watch when time is up.
    this.userIdleSubscription2 = this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer()
      this.logout()
      this.dialog.closeAll()
    })
  }
}

openUserIdleModal = () => {
  // const config = {};
  const dialog = this.dialog.open(UserIdleDialogComponent, {
    panelClass: 'custom-dialog-container',
    data: {
      // config,
    },
  });
};
}
