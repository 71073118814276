<main class="wrap">
  <section class="container">
    <div class="container__heading">
      <h2>Terms & Conditions</h2>
    </div>
    <div class="container__content">
      <!-- <object data="/assets/terms-and-condition-pdf/term_and_condition_cxedit.pdf#toolbar=0" type="application/pdf"
            width="100%" height="500px">
            <p>Unable to display PDF file. <a
                  href="/assets/terms-and-condition-pdf/term_and_condition_cxedit.pdf#toolbar=0">Download</a>
               instead.</p>
         </object> -->
      <iframe src="https://www.cxunify.com/termsandconditions"></iframe>
    </div>
    <div class="container__nav">
      <small>By clicking 'Accept' you are agreeing to our terms and conditions.</small>
      <a class="button" (click)="onCloseModal()">Accept</a>
    </div>
  </section>
</main>
