import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../app/services/auth.service';
import { environment } from '../../../environments/environment';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-link-agent-dialog',
  templateUrl: './link-agent-dialog.component.html',
  styleUrls: ['./link-agent-dialog.component.scss']
})
export class LinkAgentDialogComponent implements OnInit {

  isAgentLinked: boolean = false;
  setTimer: any;
  isLoading: any = false;
  searchKey: any;
  modalList: any;
  showLinkAgent: boolean = false;
  agentMessage: string = 'asdsad';
  showAgentMessage: boolean = false;
  agentName: string = '';
  agentID: any;
  companyIdToLink: any;

  companyChildName: any = '';
  dialogRefStatus: boolean = false;
  currentCompanyId: any;
  errMessage: string;

  btnLoader: string = environment.config.dynamicImage.loader;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LinkAgentDialogComponent>,
    private Auth: AuthService,
  ) {
    if (data) {
      const { config, items, isAgentLinked, agentName, agentID, companyChildName, currentCompanyID } = data;
      if(config.length) {
        this.companyChildName = companyChildName;
        this.isAgentLinked = isAgentLinked;
        this.agentName = agentName;
        this.agentID = agentID;
        this.currentCompanyId = currentCompanyID;
        this.modalList = config.map((e) => {
          return {
            ...e,
          }
        });
      }
      else 
        this.errMessage='There are no child companies to link'
    }
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  getModalTitle = () => {
    if (!this.isAgentLinked) {
      return 'LINK AGENT TO COMPANY';
    }
    if (this.isAgentLinked) {
      return 'UN-LINK AGENT TO COMPANY';
    }
    return 'title';
  };

  closeDialog = () => {
    this.isLoading = false;
    this.dialogRef.close(this.dialogRefStatus);
  };

  openLinkAgent = ({ company_name, company_id }) => {
    this.companyChildName = company_name;
    this.companyIdToLink = company_id;
    this.showLinkAgent = true;
  };

  linkAgent = () => {
    this.isLoading = true;

    const linkDomotzAgentData = {
      company_id: this.companyIdToLink,
      agent_id: this.agentID,
    };

    this.Auth.linkDomotzAgent(linkDomotzAgentData).subscribe((data) => {
      if (data['status'] == 200) {
        this.agentMessage = 'Agent Linking is successful';
        this.dialogRefStatus = true;
      } else {
        this.agentMessage = 'Something went wrong in agent linking';
      }

      this.isLoading = false;
      this.showAgentMessage = true;
      this.resetAgentMessage();
    });

  };

  unlinkAgent = () => {
    this.isLoading = true;

    const unlinkDomotzAgentData = {
      company_id: this.currentCompanyId,
      agent_id: this.agentID,
    };

    this.Auth.unlinkDomotzAgent(unlinkDomotzAgentData).subscribe((data) => {
      if (data['status'] == 200) {
        if (data.response[0].status === 'fail') {
          this.agentMessage = "Cannot unlink agent due to connected devices"
        } else {
          this.agentMessage = 'Unlinking of agent is successful';
          this.dialogRefStatus = true;
        }
      } else {
        this.agentMessage = 'Something went wrong in unlinking of agent';
      }

      this.isLoading = false;
      this.showAgentMessage = true;
      this.resetAgentMessage();
    });

  };

  closeLinkAgent = () => {
    this.showLinkAgent = false;
  };


  resetAgentMessage = () => {
    this.setTimer = setTimeout(() => {
      this.closeDialog();
    }, 3000);
    this.setTimer = setTimeout(() => {
      this.showAgentMessage = false;
    }, 4000);
    this.isLoading = false;
  }


}
