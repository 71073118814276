import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GetAppManagerService } from '../../services/get-app-manager.service';

@Component({
  selector: 'app-plugin-version-list',
  templateUrl: './plugin-version-list.component.html',
  styleUrls: ['./plugin-version-list.component.scss'],
})
export class PluginVersionListComponent implements OnInit {
  @Input() currentUserInfo: any;
  dPlugin: String = environment.config.dynamicText.dPlugin;
  pluginImg: String = environment.config.dynamicImage.pluginImg;
  cloudPluginVersionList: any;
  isOpenReleaseNotes = false;
  isOpenZoomPluginList = false;
  isOpenScheduleUpdate = false;
  rNotes: any;
  scheduleUpdateData: any;
  zoomPluginListData: any;

  constructor(private appManager: GetAppManagerService) { }

  ngOnInit() {
    this.getZoomPluginVersionList();
  }

  getZoomPluginVersionList = () => {
    this.cloudPluginVersionList = [];
    this.appManager.getZoomPluginVersionList().subscribe((data) => {
      if (data) {
        console.log('cloudPluginVersionList: ', data)
        this.cloudPluginVersionList = data;
      }
    });
  };

  openReleaseNotes = (pVersion) => {
    const obj = {
      plugin_version_id: pVersion.plugin_version_id,
      release_notes: pVersion.release_notes,
      version: pVersion.version,
    };
    this.rNotes = obj;
    this.isOpenReleaseNotes = true;
  };

  openPluginList = (pCount, version, infoType) => {
    if (pCount > 0) {
      this.isOpenZoomPluginList = true;
      this.zoomPluginListData = {
        infoType,
        version,
      };
    }
  };

  ngOnChange = ($event) => {
    if ($event === 'closeZoomPluginReleaseNotes') {
      this.isOpenReleaseNotes = false;
      return;
    }

    if ($event === 'closeZoomPluginScheduleUpdate') {
      this.isOpenScheduleUpdate = false;
      return;
    }

    if ($event === 'closeZoomPluginList') {
      this.isOpenZoomPluginList = false;
      return;
    }

    if ($event === 'getZoomPluginVersionList') {
      this.getZoomPluginVersionList();
      return;
    }
  };

  openScheduleUpdate = (pluginVersion) => {
    const obj = {
      email_notes: pluginVersion.email_notes,
      include_release_notes: pluginVersion.include_release_notes,
      version: pluginVersion.version,
      update_schedule: pluginVersion.update_schedule,
    };
    this.scheduleUpdateData = obj;
    this.isOpenScheduleUpdate = true;
  };

  isShowUpdatePluginBtn = (status) => {
    if (status === 'COMPLETED') {
      return false;
    }
    return true;
  };

  getUpdateScheduleBtnLabel = (scheduleUpdate) => {
    return scheduleUpdate === null ? 'Schedule Update' : 'Reschedule Update';
  };
}
