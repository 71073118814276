
  <!-- <span><fa name="phone" class="pr-1"></fa>{{ renderValue }}</span> -->
  <span *ngIf="renderValue == 0">
    <span>
      <img  style="width: 13px" src="../../../assets/img/video/hang-up.png"/>
    </span>
  </span>
  <span *ngIf="renderValue > 0">
    <span><fa name="phone" class="pr-1"></fa>{{ renderValue }}</span>
  </span>
