import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserInfoService } from '../services/user-info.service';

@Component({
  selector: 'app-add-phone-number',
  templateUrl: './add-phone-number.component.html',
  styleUrls: ['./add-phone-number.component.scss'],
})

export class AddPhoneNumberComponent implements OnInit {
  dashboardLogo = environment.config.dynamicImage.loginImage;
	separateDialCode = false;
  errorMessage = '';
	SearchCountryField = SearchCountryField;
  isBusy: boolean = false;
  TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.Philippines];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  constructor(
    private router: Router,
    private Auth: AuthService,
    private userInfo: UserInfoService
  ) {

  }

  ngOnInit() {
  }

  async saveAndSend(f: any) {
    this.isBusy = true;
    if (f.form.controls['phone'].invalid) {
      this.errorMessage = 'Invalid Phone Number';
      this.isBusy = false;
      return;
    } else {
      this.errorMessage = '';
    }
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const data = await this.Auth.updateUserProfile({
      data: {
        company_id: decodedToken.company_id,
        first_name: decodedToken.first_name,
        last_name: decodedToken.last_name,
        email: decodedToken.email,
        user_id: decodedToken.user_id,
        phone: f.form.controls['phone'].value.e164Number
      }
    });
    localStorage.setItem('phone', f.form.controls['phone'].value.e164Number);
    if (data && data['response'][0] && data && data['response'][0].status === "OK" ) {
      await this.Auth.sendVerificationCode(environment.config.twilioServiceId, f.form.controls['phone'].value.e164Number, "sms");
      this.router.navigate(['/two-factor-auth']).then(() => {
        window.location.reload();
      });
      this.isBusy = false;
    } else {
      this.isBusy = false;
    }
  }

  async cancel() {
    try {
      this.isBusy = true;
      localStorage.setItem('verified', 'false');
      localStorage.removeItem('phone');
      this.Auth.logout()
      // this.router.navigate(['login']);
      // localStorage.removeItem('token');
      // localStorage.setItem('keyDownload', '0');
      // localStorage.setItem('reloadSiteData', '0');
      // localStorage.setItem('showCongrats', '0');
      // localStorage.setItem('runTut', '0');
      // localStorage.setItem('successReg', '0');
      // localStorage.setItem('overLayMembersMove', '0');
      this.isBusy = false;
    } catch (e) {
      this.errorMessage = e;
      console.log(e);
    }
  }
}
