import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { RoleguardService as RoleGuard } from './services/roleguard.service';
// Routing Components
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './panels/login/login/login.component';
import { ForgotPasswordComponent } from './panels/login/forgot-password/forgot-password.component';

import { MainMenuComponent } from '../app/popups/main-menu/main-menu.component';
import { CustomerComponent } from '../app/popups/customer/customer.component';
import { UpdateCenterComponent } from '../app/popups/update-center/update-center.component';
import { ReportComponent } from '../app/popups/report/report.component';
//Two factor Auth
import { TwoFactorAuthComponent } from './two-factor-auth/two-factor-auth.component';
import { AddPhoneNumberComponent } from './add-phone-number/add-phone-number.component';
import { ResetPasswordComponent } from './panels/login/reset-password/reset-password.component';
// canActivate: [AuthGuard]
const routes: Routes = [
  //Login Route
  { path: 'adminlogin', component: LoginComponent },
  { path: 'two-factor-auth', component: TwoFactorAuthComponent },
  { path: 'adminforgotpassword', component: ForgotPasswordComponent },
  { path: 'resetpassword/:id', component: ResetPasswordComponent },
  { path: 'add-phone-number', component: AddPhoneNumberComponent },
  {
    path: '',
    component: MainMenuComponent,
    children: [
      {
        path: '',
        component: CustomerComponent,
      },
      { path: 'company/:id', component: CustomerComponent },

      {
        path: 'updatecenter',
        component: UpdateCenterComponent,
      },
      {
        path: 'reports',
        component: ReportComponent,
      },
    ],
  },

  // { path: ' ', component: MainMenuComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },
  // {
  //   path: '',
  //   component: MainMenuComponent,
  //   canActivateChild: [AuthGuard],
  //   children: [],
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
