import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GetAppManagerService } from '../../services/get-app-manager.service';

@Component({
  selector: 'app-plugin-version-release-notes',
  templateUrl: './plugin-version-release-notes.component.html',
  styleUrls: ['./plugin-version-release-notes.component.scss'],
})
export class PluginVersionReleaseNotesComponent implements OnInit {
  @Input() rNotes: any;
  @Output() closeReleaseNotesEvent: EventEmitter<any> = new EventEmitter<any>();

  showBtn: Boolean = false;
  swalIcon: String = 'success';
  swalMessage: String = 'Update Successful';
  releaseNotes: string;
  constructor(private appManager: GetAppManagerService) {}

  ngOnInit() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const company_id = decodedToken.company_id;
    if (environment.config.superAdminCompanyId === company_id) {
      this.showBtn = true;
    }

    this.releaseNotes = this.rNotes.release_notes;
  }

  closeReleaseNotesModal() {
    this.closeReleaseNotesEvent.emit('closeZoomPluginReleaseNotes');
  }

  updateReleaseNotes = () => {
    if (this.rNotes) {
      const { plugin_version_id, version } = this.rNotes;
      this.appManager
        .updateZoomPluginReleaseNotes(plugin_version_id, this.releaseNotes)
        .subscribe((data) => {
          if (data.status === 'Error') {
            this.swalIcon = 'warning';
            this.swalIcon = data.message;
          }
          this.closeReleaseNotesEvent.emit('getZoomPluginVersionList');
          this.closeReleaseNotesEvent.emit('closeZoomPluginReleaseNotes');
        });
    }
  };

  keyup = (keyValue) => {
    this.releaseNotes = keyValue;
  };
}
