<div class="delete-site">
  <div class="delete-site__body">
    <div class="delete-site__image">
      <img *ngIf="!isLoading" src="../../../../assets/img/gbl_img/delete-icon.png" alt="" />
      <img *ngIf="isLoading" src="{{ loader }}" alt="" />
    </div>
    <div class="delete-site__text-button-container">
      <div class="">
        Are you sure you want to delete <strong>{{ companyName }}</strong> company?
      </div>
      <div class="delete-site__button-container">
        <div
          class="delete-site__button-item delete-site__button-item--yes"
          (click)="confirmDelete()"
        >
          Yes
        </div>
        <div
          class="delete-site__button-item delete-site__button-item--cancel"
          (click)="closeDialog()"
        >
          Cancel
        </div>
      </div>
    </div>
  </div>
</div>
