import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { AuthService } from './services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Set Authorization header for every request made
    let token = '';
    if (localStorage && localStorage.getItem('token')) {
      token = localStorage.getItem('token');
    }
    if (request.url.search('/api/v1/admin/') !== -1) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }
    return next.handle(request).pipe(
      catchError(error => {
				if (error instanceof HttpErrorResponse) {
					switch (error.status) {
            case 401:
              this.auth.logout()
							break
						case 403:
							this.auth.logout()
							break
					}
				}
				return throwError(error)
			})
		)
  }
}
