import { Component, OnInit, Input } from '@angular/core';
import { DocumentCenterService } from '../../../services/document-center.service';
import { UserInfoService } from '../../../services/user-info.service';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment-timezone';
import { MatTableDataSource } from '@angular/material/table';
import { JwtHelperService } from '@auth0/angular-jwt';

export interface DocumentListInterface {
  document_id: number;
  device_id: number;
  quadrant_group: any;
  file_type: any;
  file_name: any;
  file_size: any;
  description: any;
  file_path: any;
  date_created: any;
  share_expiration: any;
  is_shared: any;
  root_id: number;
  isFiles: boolean;
}

@Component({
  selector: 'app-document-center',
  templateUrl: './document-center.component.html',
  styleUrls: ['./document-center.component.scss'],
})
export class DocumentCenterComponent implements OnInit {
  @Input() companyId: number;
  @Input() currentUserInfo: any;

  loader: String = environment.config.dynamicImage.loader;
  documentList: any = [];
  totalDocumentSize: number = 0;
  storageLimit: number = 0;
  shouldShowLoader: boolean = true;
  isShowSaveStorageLimit: boolean = false;
  searchKey: any;
  fileCount: number;
  availableStorage: number = 0;
  displayedColumns: any[] = [
    'file_type',
    'file_name',
    'file_size',
    'description',
    'date_created',
    'is_shared',
    'share_expiration',
  ];
  dataSource: MatTableDataSource<DocumentListInterface>;

  constructor(private Document: DocumentCenterService, private userInfo: UserInfoService) { }

  ngOnInit() {
    if (this.companyId) {
      const token = localStorage.getItem('token');
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      const cid = decodedToken.company_id;
      this.isShowSaveStorageLimit = environment.config.superAdminCompanyId === cid;

      this.isShowSaveStorageLimit = this.userInfo.getInfo().loginType === 'superAdmin';
      this.getDocuments();
    }
  }

  getDocuments() {
    this.shouldShowLoader = true;
    const body = {
      companyId: this.companyId,
      root_id: 0,
      isFiles: true,
      offset: 0
    }
    this.Document.getDocumentsByCompany(body).subscribe((data) => {
      const { document_list, total_document_size, document_center_storage_limit } = data;
      this.totalDocumentSize = total_document_size;
      this.documentList = document_list.map((d) => {
        const isNotExpired = moment(d.share_expiration).isAfter(moment());
        let isShared = 'No';
        if (isNotExpired) {
          isShared = d.shared_by_user_id !== null ? 'Yes' : 'No';
        }
        return {
          ...d,
          is_shared: isShared,
        };
      });
      this.dataSource = new MatTableDataSource(this.documentList);
      this.storageLimit = document_center_storage_limit;
      this.availableStorage = this.storageLimit * 1000000000 - this.totalDocumentSize;
      this.fileCount = this.documentList.reduce((pV, cV) => cV.file_type != 'folder' ? pV + 1 : pV + 0, 0);;
      this.shouldShowLoader = false;
    });
  }

  // convertTimeStamp = (time) => (time === null ? '--' : moment(time).format('l, hh:mm a'));

  setStorageLimit() {
    this.shouldShowLoader = true;
    this.Document.setCompanyStorageLimit(this.companyId, this.storageLimit).subscribe((data) => {
      this.shouldShowLoader = false;
      this.getDocuments();
    });
  }

  searchData = (key) => {
    this.dataSource.filter = key.trim().toLowerCase();
    this.fileCount = this.dataSource.filteredData.length;
  };
}
