<div class="domotz-setup">
    <div class="title">

    </div>

    <div class="nav-components-ctr">
        <div class="navoptions">
            <div class="navoptions__item navoptions__item--active" (click)="selectActivePanel(1)"
                [class.active]="activePanel === 1">
                enable service
            </div>
            <div class="navoptions__item navoptions__item--active" (click)="selectActivePanel(2)"
                [class.active]="activePanel === 2">
                link agents
            </div>

        </div>
        <div class="vl"></div>
        <div class="components">
            <div *ngIf="activePanel === 1" class="components_item">
                <app-domotz-enable-service [currentCompanyId]="companyId" (boolEvent)="ngOnChange($event)">
                </app-domotz-enable-service>
            </div>

            <div *ngIf="activePanel === 2" class="components_item">
                <app-domotz-agent-link [currentCompanyId]="companyId" (boolEvent)="ngOnChange($event)">
                </app-domotz-agent-link>
            </div>
        </div>

    </div>
</div>