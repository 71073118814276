<div class="update-company">
  <div *ngIf="isLoading" class="update-company__loader-container">
    <img src="{{ loader }}" alt="" />
  </div>


  <form role="form" name="companyForm" class="mt-2" [formGroup]="companyForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="update-company__form-container">
      <div class="update-company__group-row">
        <!-- company name -->
        <div class="update-company__group">
          <div class="update-company__group__field">
            <label for="companyName">Name:</label>
            <input type="text" formControlName="companyName" class="form-control update-company__company-name"
              placeholder="Company Name" name="companyName" id="companyName" required />
          </div>
          <div class="update-company__input-invalid" *ngIf="submitted && fc.companyName.errors">
            Company name required!
          </div>
        </div>
        <!-- primary contact -->
        <div class="update-company__group">
          <div class="update-company__group__field">
            <label for="primaryContact">Primary Contact:</label>
            <select type="text" formControlName="primaryContact" class="form-control" placeholder="Primary Contact"
              name="primaryContact" id="primaryContact" required>
              <option *ngFor="let user of companyUserList" value="{{ user.user_id }}">{{
                user.last_name + ',' + user.first_name
                }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="update-company__group-row">
        <!-- address1 -->
        <div class="update-company__group">
          <div class="update-company__group__field">
            <label for="address1">Address1:</label>
            <input type="text" formControlName="address1" class="form-control update-company__address"
              placeholder="Address1" name="address1" id="address1" required />
          </div>
          <div class="update-company__input-invalid" *ngIf="submitted && fc.address1.errors">
            Address1 required!
          </div>
        </div>
        <!-- address2 -->
        <div class="update-company__group">
          <div class="update-company__group__field">
            <label for="address2">Address2:</label>
            <input type="text" formControlName="address2" class="form-control update-company__address"
              placeholder="Address2" name="address2" id="address2" />
          </div>
        </div>
      </div>
      <div class="update-company__group-row">
        <!-- country -->
        <div class="update-company__group">
          <div class="update-company__group__field">
            <label for="country">Country:</label>
            <select (change)="setCountry($event.target.value)" type="text" formControlName="country"
              class="form-control" placeholder="Country" name="country" id="country" required>
              <option *ngFor="let countries of allCountryListData" value="{{ countries.id }}">{{
                countries.name
                }}</option>
            </select>
          </div>
          <div class="update-company__input-invalid" *ngIf="submitted && fc.country.errors">
            Country required!
          </div>
        </div>
        <!-- state -->
        <div class="update-company__group">
          <div class="update-company__group__field">
            <label for="state">State:</label>
            <select type="text" formControlName="state" class="form-control" placeholder="State/Province/Region"
              name="state" id="state" autocomplete="state" required>
              <option *ngFor="let states of allStatesListData; let i = index" value="{{ states.name }}">{{ states.name
                }}</option>
            </select>
          </div>
          <div class="update-company__input-invalid" *ngIf="submitted && fc.state.errors">
            State required!
          </div>
        </div>
      </div>
      <div class="update-company__group-row">
        <!-- city -->
        <div class="update-company__group">
          <div class="update-company__group__field">
            <label for="city">City:</label>
            <input type="text" formControlName="city" class="form-control" placeholder="City" name="city" id="city"
              required />
          </div>
          <div class="update-company__input-invalid" *ngIf="submitted && fc.city.errors">
            City required!
          </div>
        </div>
        <!-- zip -->
        <div class="update-company__group">
          <div class="update-company__group__field">
            <label for="zip">Zip:</label>
            <input type="text" formControlName="zip" class="form-control" placeholder="Zip" name="zip" id="zip"
              required />
          </div>
          <div class="update-company__input-invalid" *ngIf="submitted && fc.zip.errors">
            Please enter a valid Zipcode of the country
          </div>
        </div>
      </div>

      <div class="update-company__group-row">
        <!-- phone code -->
        <div class="update-company__group">
          <div class="update-company__group__field">
            <label for="compphonecode">Phone Code:</label>
            <input type="text" formControlName="compphonecode" class="form-control update-company__phone-code"
              name="compphonecode" id="compphonecode" placeholder="Phone code" readonly />
          </div>
          <div class="update-company__input-invalid" *ngIf="submitted && fc.compphonecode.errors">
            Phone code required!
          </div>
        </div>
        <!-- phone -->
        <div class="update-company__group">
          <div class="update-company__group__field">
            <label for="companyphone">Phone number:</label>
            <input type="text" formControlName="companyphone" class="form-control update-company__phone"
              placeholder="Company Phone" name="companyphone" id="companyphone" required />
          </div>
          <div class="update-company__input-invalid" *ngIf="submitted && fc.companyphone.errors">
            Please enter valid phone number
          </div>
        </div>
      </div>

      <div class="update-company__group-row">
        <!-- phone code -->

        <!-- phone -->
        <div *ngIf="currentUserInfo?.accesslevel =='ADMIN' &&  isDomainUpdated == 0 
          || currentUserInfo?.accesslevel =='MANAGER' &&  isDomainUpdated == 0 || platFormAdmin == 1"
          class="update-company__group">
          <div class="update-company__group__field">
            <label for="companydomain">Domain:</label>
            <input type="text" formControlName="companydomain" class="form-control update-company__phone"
              placeholder="Domain" name="companydomain" id="companydomain" required />
          </div>
          <div class="update-company__input-invalid" *ngIf="submitted && fc.companydomain.errors">
            Please enter valid Domain
          </div>
        </div>
      </div>

      <!-- additional domain -->
      <div class="update-company__group-row">

        <!-- <div *ngIf="currentUserInfo?.accesslevel =='ADMIN' &&  isDomainUpdated == 0 
          || currentUserInfo?.accesslevel =='MANAGER' &&  isDomainUpdated == 0 || platFormAdmin == 1"
          class="update-company__group"> -->
        <div class="update-company__group">
          <div class="update-company__group__field">
            <label for="companydomain">Additional Domain:</label>
            <input (input)="onValueChange($event.target.value)" type="text" formControlName="additionalDomain"
              class="form-control update-company__phone" placeholder="Additional Domain ( example1.com;example2.com )"
              name="additionalDomain" id="additionalDomain" />
          </div>
          <div class="update-company__input-invalid" *ngIf="submitted && additionalDomainHasErrors">
            {{additionalDomainErrorMessage}}
          </div>
        </div>
      </div>
      <!-- additional domain -->

      <!-- buttons -->
      <div class="update-company__button-container">
        <button type="submit" class="form-control btn btn-primary">
          Save
        </button>

        <div class="btn btn-danger" style="margin: 5px;" (click)="cancelUpdate()">
          Cancel
        </div>

      </div>
    </div>
  </form>


</div>