import {
  Component, OnInit, Output, EventEmitter, Input, OnChanges,
  SimpleChanges
} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-collector-update-schedule',
  templateUrl: './collector-update-schedule.component.html',
  styleUrls: ['./collector-update-schedule.component.scss'],
})
export class CollectorUpdateScheduleComponent implements OnInit, OnChanges {
  @Output() closeCollectorScheduleUpdateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() scheduleUpdateData: any;
  @Input() systemType: any;

  emailVal: String = ' ';
  dateVal: any;
  emailNoteVal: String = ' ';
  checkBoxVal: any;

  emailResponse: String = 'Email Invalid';
  dateMsg: String = 'Date Invalid';
  showEmailErrMsg: Boolean = false;
  showDateErrMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  showNoteSuccessMsg: Boolean = false;
  testBtnDisabled: Boolean = false;
  minDate: Date;
  constructor(private Auth: AuthService) { }

  ngOnInit() {
    this.setMinDate()
    //   this.setEmailAndCheckbox();
    //   this.setDateLabel();
  }

  setMinDate() {
    const dayAftertomorrowDate = moment().add(2, 'day');
    this.minDate = dayAftertomorrowDate.toDate()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scheduleUpdateData && this.scheduleUpdateData) {
      this.setDateLabel()
      this.setEmailAndCheckbox();
      console.log('system type: ', this.systemType)
    }
  }


  setDateLabel = () => {
    const { update_schedule } = this.scheduleUpdateData;
    console.log(update_schedule)
    if (update_schedule !== null && update_schedule.trim().length !== 0) {
      const momentData = moment(update_schedule)
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format();
      this.dateVal = momentData.split(':')[0] + ':' + momentData.split(':')[1];
    }
  };

  setEmailAndCheckbox = () => {
    const { email_notes, include_release_notes } = this.scheduleUpdateData;
    this.emailNoteVal = email_notes;
    this.checkBoxVal = include_release_notes === 1 ? true : false;
  };

  closeScheduleUpdateModal() {
    this.closeCollectorScheduleUpdateEvent.emit('closeScheduleUpdate');
  }

  onKeyEmail = (event: any) => {
    this.emailVal = event.target.value;
  };

  onKeyEmailNote = (event: any) => {
    this.emailNoteVal = event.target.value;
  };

  updateCollectorSchedule() {
    const uDate = (<HTMLInputElement>document.getElementById('schedule-date')).value;
    const dateArr = new Date(uDate).toString().split(' ');
    const validate = this.validateForm(dateArr, true, new Date(uDate) > new Date());
    if (validate) {
      if (this.systemType === 'mac') {
        const sDate = uDate.split('T');
        const scheduleDate = moment(sDate[0] + ' ' + sDate[1])
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format();
        this.Auth.setMacCollectorVersionUpdateSchedule(
          this.scheduleUpdateData.version,
          scheduleDate
        ).subscribe((data) => {
          console.log('set mac success!!')
          this.closeCollectorScheduleUpdateEvent.emit('closeScheduleUpdate');
          this.closeCollectorScheduleUpdateEvent.emit('getCollectorVersionList');
        });
      } else {
        const sDate = uDate.split('T');
        const scheduleDate = moment(sDate[0] + ' ' + sDate[1])
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format();
        this.Auth.setCollectorVersionUpdateSchedule(
          this.scheduleUpdateData.version,
          scheduleDate
        ).subscribe((data) => {
          console.log('set windows success!!')
          this.closeCollectorScheduleUpdateEvent.emit('closeScheduleUpdate');
          this.closeCollectorScheduleUpdateEvent.emit('getCollectorVersionList');
        });
      }
    }
  }

  sendTestEmail = () => {
    const uDate = (<HTMLInputElement>document.getElementById('schedule-date')).value;
    const dateArr = new Date(uDate).toString().split(' ');
    const formatEmailNoteVal = this.emailNoteVal !== null ? this.emailNoteVal.trim() : '';
    const validate = this.validateForm(
      dateArr,
      this.validateEmail(this.emailVal),
      new Date(uDate) > new Date()
    );
    if (validate) {
      const sDate = uDate.split('T');
      const scheduleDate = moment(sDate[0] + ' ' + sDate[1])
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format();

      this.Auth.sendTestEmailForCollectorVersionUpdateSchedule(
        this.emailVal,
        formatEmailNoteVal,
        this.checkBoxVal,
        scheduleDate,
        this.scheduleUpdateData.version
      ).subscribe((data) => {
        this.emailResponse = data.response.message;
        this.showSuccessMsg = true;
        this.testBtnDisabled = true;
        setTimeout(() => {
          this.showSuccessMsg = false;
          this.testBtnDisabled = false;
        }, 2000);
      });
    }
  };

  validateForm = (date: any, bool: any, dateCompare: any) => {
    this.showEmailErrMsg = false;
    this.showDateErrMsg = false;
    let count = 0;
    if (date[0] === 'Invalid') {
      this.dateMsg = 'Date Invalid';
      this.showDateErrMsg = true;
      count++;
    } else {
      if (!dateCompare) {
        this.showDateErrMsg = true;
        this.dateMsg = 'Date must be after Date today';
        count++;
      }
    }
    if (!bool) {
      this.showEmailErrMsg = true;
      count++;
    }

    return count === 0;
  };

  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const regexEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEx.test(String(email).toLowerCase());
  }

  includeReleaseNotes = (event) => {
    this.checkBoxVal = event.target.checked;
  };

  saveNotes = () => {
    const includeReleaseNotesVal = this.checkBoxVal ? '1' : '0';
    this.Auth.saveNotes(
      this.scheduleUpdateData.version,
      this.emailNoteVal,
      includeReleaseNotesVal
    ).subscribe((data) => {
      this.showNoteSuccessMsg = true;
      setTimeout(() => {
        this.showNoteSuccessMsg = false;
      }, 2000);
      this.closeCollectorScheduleUpdateEvent.emit('getCollectorVersionList');
    });
  };
}
