import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GetAppManagerService } from '../../services/get-app-manager.service';

@Component({
  selector: 'app-plugin-list',
  templateUrl: './plugin-list.component.html',
  styleUrls: ['./plugin-list.component.scss'],
})
export class PluginListComponent implements OnInit {
  dPlugin: String = environment.config.dynamicText.dPlugin;
  @Output() pluginListEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() pluginListData: any;
  pluginList: any;

  constructor(private appManager: GetAppManagerService) { }

  ngOnInit() {
    this.setPluginList();
  }

  closePluginListModal() {
    this.pluginListEvent.emit('closeZoomPluginList');
  }

  setPluginList() {
    this.pluginList = [];

    if (this.pluginListData.infoType === 'Pending') {
      this.appManager
        .getZoomPluginUpdatePendingList(this.pluginListData.version)
        .subscribe((result) => {
          console.log('getZoomPluginUpdatePendingList: ', result)
          this.pluginList = [...result];
        });
    }

    if (this.pluginListData.infoType === 'Error') {
      this.appManager
        .getZoomPluginUpdateFailedList(this.pluginListData.version)
        .subscribe((result) => {
          console.log('getZoomPluginUpdateFailedList: ', result)
          this.pluginList = [...result];
        });
    }

    if (this.pluginListData.infoType === 'Installed') {
      this.appManager
        .getZoomPluginUpdateInstalledList(this.pluginListData.version)
        .subscribe((result) => {
          console.log('getZoomPluginUpdateInstalledList: ', result)
          this.pluginList = [...result];
        });
    }
  }
}
