import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
// Angular Material
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';

import { JwtModule } from '@auth0/angular-jwt';
// import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { DatePipe } from '@angular/common';

import { AuthGuard } from './auth.guard';
import { AuthRequestOptions } from './auth-request';
import { TokenInterceptor } from './token.interceptor';

import { AppComponent } from './app.component';
import { FilterTableComponent } from './components/filter-table/filter-table.component';
import { HealthBarComponent } from './components/health-bar/health-bar.component';
import { AppRoutingModule } from './/app-routing.module';

// Table filter components
import { TableHealthIconComponent } from './components/table-icons/table-icon/table-health-icon.component';
import { TableOnCallIconComponent } from './components/table-icons/table-oncall-icon/table-oncall-icon.component';
import { TableCogIconComponent } from './components/table-icons/table-cog-icon/table-cog-icon.component';

//buttons components
import { BtnInfoComponent } from './components/buttons/btn-info/btn-info.component';
import { BtnRoundedComponent } from './components/buttons/btn-rounded/btn-rounded.component';
//charts components
import { PopupContainerComponent } from './popups/popup-container/popup-container.component';
import { InfoDetailsComponent } from './components/info/info-details/info-details.component';
import { MainMenuComponent } from './popups/main-menu/main-menu.component';
import { HomepageComponent } from './homepage/homepage.component';
import { PanelToolsComponent } from './components/panel-tools/panel-tools.component';
import { BtnHealthComponent } from './components/buttons/btn-health/btn-health.component';

//services
import { PopupTypeService } from './services/popupType.service';
import { MainMenuTypeService } from './services/mainmenuType.service';
import { MembersTypeService } from './services/membersType.service';

import { TableStatusIconComponent } from './components/table-icons/table-status-icon/table-status-icon.component';
import { LoginComponent } from './panels/login/login/login.component';
import { ForgotPasswordComponent } from './panels/login/forgot-password/forgot-password.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerComponent } from './popups/customer/customer.component';
import { ReportComponent } from './popups/report/report.component';
import { environment } from '../environments/environment';
import { InfoSiteDetailsComponent } from './components/info/info-site-details/info-site-details.component';
import { WholeNumberPipe } from './pipes/whole-number.pipe';
import { TwoDecimalsPipe } from './pipes/two-decimals.pipe';
import { TableQosIconComponent } from './components/table-icons/table-qos-icon/table-qos-icon.component';
import { InfoIntegratorComponent } from './components/info/info-integrator/info-integrator.component';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { UpdateCenterComponent } from './popups/update-center/update-center.component';
import { CollectorVersionListComponent } from './components/collector-version-list/collector-version-list.component';
import { CollectorVersionReleaseNotesComponent } from './components/collector-version-release-notes/collector-version-release-notes.component';
import { DisplayEmptyPipe } from './pipes/display-empty.pipe';
import { CollectorUpdateScheduleComponent } from './components/collector-update-schedule/collector-update-schedule.component';
import { DisplayDatePipe } from './pipes/display-date.pipe';
import { CollectorListComponent } from './components/collector-list/collector-list.component';
import { AddCompanyComponent } from './components/add-company/add-company.component';
import { PluginVersionListComponent } from './components/plugin-version-list/plugin-version-list.component';
import { PluginVersionReleaseNotesComponent } from './components/plugin-version-release-notes/plugin-version-release-notes.component';
import { PluginListComponent } from './components/plugin-list/plugin-list.component';
import { PluginUpdateScheduleComponent } from './components/plugin-update-schedule/plugin-update-schedule.component';
import { DesktopPluginVersionListComponent } from './components/desktop-plugin-version-list/desktop-plugin-version-list.component';
import { DesktopPluginVersionReleaseNotesComponent } from './components/desktop-plugin-version-release-notes/desktop-plugin-version-release-notes.component';
import { DesktopPluginListComponent } from './components/desktop-plugin-list/desktop-plugin-list.component';
import { DesktopPluginUpdateScheduleComponent } from './components/desktop-plugin-update-schedule/desktop-plugin-update-schedule.component';
import { IntegratorAccountDialogComponent } from './dialog/integrator-account-dialog/integrator-account-dialog.component';
import { UpdateCompanyComponent } from './components/update-company/update-company.component';
import { UpdateSubscriptionDateComponent } from './components/info/update-subscription-date/update-subscription-date.component';
import { DocumentCenterComponent } from './components/document-center/document-center/document-center.component';
import { DocumentFileSizePipe } from './pipes/document-file-size.pipe';
import { DocumentFilterPipe } from './pipes/document-filter.pipe';
import { FileTypeIconPipe } from './pipes/file-type-icon.pipe';
import { MacCollectorVersionListComponent } from './components/mac-collector-version-list/mac-collector-version-list.component';
import { MacCollectorVersionReleaseNotesComponent } from './components/mac-collector-version-release-notes/mac-collector-version-release-notes.component';
import { MacCollectorUpdateScheduleComponent } from './components/mac-collector-update-schedule/mac-collector-update-schedule.component';
import { MacCollectorListComponent } from './components/mac-collector-list/mac-collector-list.component';
import { CompanyDialogComponent } from './dialog/company-dialog/company-dialog.component';
import { TwoFactorAuthComponent } from './two-factor-auth/two-factor-auth.component';
import { AddPhoneNumberComponent } from './add-phone-number/add-phone-number.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DomotzSetupComponent } from './components/domotz-setup/domotz-setup.component';
import { DomotzEnableServiceComponent } from './components/domotz-enable-service/domotz-enable-service.component';
import { DomotzAgentLinkComponent } from './components/domotz-agent-link/domotz-agent-link.component';
import { LinkAgentDialogComponent } from './dialog/link-agent-dialog/link-agent-dialog.component';
import { MainMenuFilterPipe } from './pipes/main-menu-filter.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TermAndConditionModalComponent } from './components/term-and-condition-modal/term-and-condition-modal.component';
import { NotifierModule } from 'angular-notifier';
import { ResetPasswordComponent } from './panels/login/reset-password/reset-password.component';
import { DomotzDialogComponent } from './dialog/domotz-dialog/domotz-dialog.component';
import { AgentWarningDialogComponent } from './dialog/agent-warning-dialog/agent-warning-dialog.component';
import { SSOService } from './services/sso.service';

import { UserIdleModule } from 'angular-user-idle';
import { UserIdleDialogComponent } from './dialog/user-idle-dialog/user-idle-dialog.component';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    HealthBarComponent,
    FilterTableComponent,
    TableHealthIconComponent,
    TableOnCallIconComponent,
    BtnInfoComponent,
    BtnRoundedComponent,
    PopupContainerComponent,
    TableCogIconComponent,
    InfoDetailsComponent,
    MainMenuComponent,
    HomepageComponent,
    PanelToolsComponent,
    BtnHealthComponent,
    TableStatusIconComponent,
    LoginComponent,
    ForgotPasswordComponent,
    CustomerComponent,
    ReportComponent,
    InfoSiteDetailsComponent,
    WholeNumberPipe,
    TwoDecimalsPipe,
    TableQosIconComponent,
    InfoIntegratorComponent,
    DashboardHeaderComponent,
    UpdateCenterComponent,
    CollectorVersionListComponent,
    CollectorVersionReleaseNotesComponent,
    DisplayEmptyPipe,
    CollectorUpdateScheduleComponent,
    DisplayDatePipe,
    CollectorListComponent,
    AddCompanyComponent,
    PluginVersionListComponent,
    PluginVersionReleaseNotesComponent,
    PluginListComponent,
    PluginUpdateScheduleComponent,
    DesktopPluginVersionListComponent,
    DesktopPluginVersionReleaseNotesComponent,
    DesktopPluginListComponent,
    DesktopPluginUpdateScheduleComponent,
    IntegratorAccountDialogComponent,
    UpdateCompanyComponent,
    UpdateSubscriptionDateComponent,
    DocumentCenterComponent,
    DocumentFileSizePipe,
    DocumentFilterPipe,
    FileTypeIconPipe,
    MacCollectorVersionListComponent,
    MacCollectorVersionReleaseNotesComponent,
    MacCollectorUpdateScheduleComponent,
    MacCollectorListComponent,
    CompanyDialogComponent,
    TwoFactorAuthComponent,
    AddPhoneNumberComponent,
    DomotzSetupComponent,
    DomotzEnableServiceComponent,
    DomotzAgentLinkComponent,
    LinkAgentDialogComponent,
    MainMenuFilterPipe,
    TermAndConditionModalComponent,
    ResetPasswordComponent,
    DomotzDialogComponent,
    AgentWarningDialogComponent,
    UserIdleDialogComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    // AngularDateTimePickerModule,
    // Angular Material
    Ng2SmartTableModule,
    MatTabsModule,
    MatRadioModule,
    NotifierModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        // whitelistedDomains: ['http://127.0.01:8080']
      },
    }),
    MatCheckboxModule,
    MatSelectModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    SweetAlert2Module.forRoot(),
    NgxIntlTelInputModule,
    UserIdleModule.forRoot({
      // 30 min idle time
      idle: 1800,
      timeout: 20,
      // ping: 120
    }),
  ],
  entryComponents: [
    TableHealthIconComponent,
    TableOnCallIconComponent,
    TableCogIconComponent,
    TableStatusIconComponent,
    TableQosIconComponent,
    IntegratorAccountDialogComponent,
    CompanyDialogComponent,
    LinkAgentDialogComponent,
  ],
  providers: [
    AuthGuard,
    DatePipe,
    PopupTypeService,
    MainMenuTypeService,
    MembersTypeService,
    AuthRequestOptions,
    SSOService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
