import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { UserInfoService } from '../../../services/user-info.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IntegratorAccountDialogComponent } from '../../../dialog/integrator-account-dialog/integrator-account-dialog.component';
import { AgentWarningDialogComponent } from '../../../dialog/agent-warning-dialog/agent-warning-dialog.component';

@Component({
  selector: 'app-info-integrator',
  templateUrl: './info-integrator.component.html',
  styleUrls: ['./info-integrator.component.scss'],
})
export class InfoIntegratorComponent implements OnChanges {
  @Input() siteInformation: any;
  @Input() currentUserInfo: any;
  // @ViewChild('deleteSwal', { static: true }) private deleteSwal: SwalComponent;
  @Output() refreshCompanyListChange: EventEmitter<any> = new EventEmitter<any>();
  siteCompanyId: any;
  siteGroupName: string;
  siteData = new FormControl();
  siteDataCtrl = new FormControl();
  siteDataList: any;
  siteDataListCopy: any;
  selected: [];
  companyMembersList: any = [];
  shouldShowGroupMembersBtn: boolean = false;
  companyLoginType: string;
  checkBoxChecked: boolean;
  swalObject: any;
  shouldShowIntegratorInfo: boolean = false;
  loginType: string = null;
  toBeDeletedCompany: any = '';

  constructor(
    private Auth: AuthService,
    private userInfo: UserInfoService,
    public dialog: MatDialog
  ) {
    this.loginType = this.userInfo.getInfo().loginType;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.siteInformation) {
      if (this.siteInformation[0]) {
        this.setAllIntegratorInfo(this.siteInformation[0]);
      }
    }
  }

  setAllIntegratorInfo = (siteInfo) => {
    const { company_id, is_integrator, company_members, company_name, company_parent } = siteInfo;

    let companyMembers = [];
    if (company_members !== null) {
      companyMembers = [...company_members];
    }
    this.siteCompanyId = company_id;
    this.getCompanyIdsToAdd(company_id);
    this.shouldShowIntegratorInfo = this.loginType !== 'integrator'; // has child
    this.siteGroupName = company_parent === null ? company_name : company_parent.company_name;
    this.companyMembersList = companyMembers.map((item) => ({ ...item, selected: false }));
    if (this.shouldShowIntegratorInfo) {
      if (is_integrator === 0) {
        this.shouldShowIntegratorInfo = false;
      }
    }
  };

  getCompanyIdsToAdd = (siteId) => {
    let getCompanyIdsToData = {
      'company_id': siteId,
    };
    this.Auth.getCompanyIdsToAdd(getCompanyIdsToData).subscribe((data) => {
      const deepCopy = data.response.map((item) => ({ ...item, 'value': item.company_id }));
      this.siteDataList = [...deepCopy];
      this.siteDataListCopy = [...deepCopy];
      this.setSelectedData(this.siteDataList);
    });
  };

  filterMyOptions = (keyword) => {
    let copiedArray = this.siteDataListCopy.filter((axe) => {
      return axe.company_name.toLowerCase().search(keyword.toLowerCase()) > -1;
    });
    this.siteDataList = copiedArray;
  };

  onRemoveChild = (eventObj) => {
    this.Auth.checkIfDomotzAgent(this.siteCompanyId, eventObj.company_id).subscribe((resp) => {
      const isAnAgent = resp.response;
      if (isAnAgent) this.showIsAgentWarning();
      else this.removeIntegrator(eventObj);
    });
  };

  showIsAgentWarning = () => {
    const agentWarningDialog = this.dialog.open(AgentWarningDialogComponent, {
      panelClass: 'custom-dialog-container',
    });
  };

  removeIntegrator = (eventobj) => {
    const config = eventobj;
    const webportal = this.dialog.open(IntegratorAccountDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
        removeCompany: (params) => {
          this.removeCompany(params);
        },
      },
    });

    // this.removeCompany(eventobj);
  };

  removeCompany = (objParams) => {
    const copiedArray = [...this.companyMembersList];
    const filterArry = copiedArray.filter((e) => e.company_id !== objParams.company_id);
    this.updateCompany(filterArry);
  };

  addGroupCompany = () => {
    const copiedArray = [...this.siteDataList];
    const setCopiedArray = copiedArray.filter((e) => (this.filterSelectedId(e) ? e : ''));
    this.updateCompany(setCopiedArray);
  };

  filterSelectedId = (arr) => {
    return this.selected.filter((e) => e === arr.value).length > 0;
  };

  updateCompany = (arrayParams) => {
    const getkeys = arrayParams.map((e) => ({
      'company_id': parseInt(e['company_id']),
      'company_name': e['company_name'],
    }));
    const setCompanyMemberIdsData = {
      'company_id': this.siteCompanyId,
      'company_members': JSON.stringify(getkeys),
    };
    this.Auth.setCompanyMemberIds(setCompanyMemberIdsData).subscribe((data) => {
      const response = data.response[0];
      if (response.status === 'Ok') {
        this.companyMembersList = JSON.parse(response.currentMemberIds).map((item) => {
          return {
            ...item,
            selected: false,
          };
        });
      }
      this.getCompanyIdsToAdd(this.siteCompanyId);
      this.shouldShowGroupMembersBtn = false;
      this.refreshCompanyListChange.emit('refreshCompany');
    });
  };

  setSelectedData = (siteDataList) => {
    this.selected = this.siteDataList.filter((e) => e.is_member === 1).map((e) => e.value);
  };
  setIfBtnWillShow = (selectedData) => {
    const filterBool = !(
      this.selected.filter((e) => this.findIfSelectedValueFune(e) === -1).length < 1
    );
    const lengthBool = this.selected.length === this.companyMembersList.length;
    this.shouldShowGroupMembersBtn = !lengthBool || filterBool;
  };

  findIfSelectedValueFune = (e) => {
    return this.companyMembersList.findIndex((item) => item.company_id === e);
  };

  cancelChange = () => {
    const copiedArray = this.companyMembersList.map((e) => e.company_id);
    this.selected = copiedArray;
    this.shouldShowGroupMembersBtn = false;
  };
}
