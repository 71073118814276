import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { UserInfoService } from '../../services/user-info.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
})
export class AddCompanyComponent implements OnInit {
  @Output() refreshCompanyEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() isPlatformAdmin: any;

  addCompany: any = {
    companyName: '',
    domain: '',
    address_company: {
      address1: '',
      address2: '',
      city: '',
      zip: '',
    },
    cFirstname: '',
    cLastname: '',
    cPhone: '',
    cEmail: '',
    cMspEmail: '',
  };

  colorStatus: any = 'green';
  addCompanyStatus: any = '';

  selectedCountry: any;
  selectedState: any;
  formControl: any;

  countryDataList: any;
  stateDataList: any;

  showMspInputForm: Boolean = true;
  isWebViewAllowed: Boolean = true;
  isMspAllowed: Boolean = true;
  isTenantAdminEnabled: Boolean = false;

  add_company_form: FormGroup;
  doneAddingCompany: Boolean = false;
  setTimer: any;
  companyBeingCreated: any;

  constructor(private Auth: AuthService, private userInfo: UserInfoService, private fb: FormBuilder, private readonly notifier: NotifierService) {

    this.add_company_form = this.fb.group({
      'companyName': [null, Validators.required],
      'domain': [null, Validators.compose([Validators.required, Validators.pattern(/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/)])],
      // 'domain': [null, Validators.required],
      'address1': [null],
      'address2': [null],
      'city': [null],
      'zip': [null],
      // 'country': [null, Validators.required],
      // 'state': [null, Validators.required],
      'firstname': [null, Validators.required],
      'lastname': [null, Validators.required],
      'phone': [null, Validators.required],
      'email': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      'mspemail': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]),],
    },
      {
        validator: this.mustNotMatch("firstname", "lastname", "domain", "mspemail")
      },);

  }

  ngOnInit() {
    this.setFormControl();
    this.getCountryData();
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  setFormControl = () => {
    const deepCopy = {
      companyName: {
        shouldShowMessage: false,
        style: 'default',
        message: '',
      },
      domain: {
        shouldShowMessage: false,
        style: 'default',
        message: '',
      },
      country: {
        shouldShowMessage: false,
        style: 'default',
        message: '',
      },
      state: {
        shouldShowMessage: false,
        style: 'default',
        message: '',
      },
      cFirstname: {
        shouldShowMessage: false,
        style: 'default',
        message: '',
      },
      cLastname: {
        shouldShowMessage: false,
        style: 'default',
        message: '',
      },
      cPhone: {
        shouldShowMessage: false,
        style: 'default',
        message: '',
      },
      cEmail: {
        shouldShowMessage: false,
        style: 'default',
        message: '',
      },
      cMspEmail: {
        shouldShowMessage: false,
        style: 'default',
        message: '',
      },
    };
    this.formControl = { ...deepCopy };
  };

  getCountryData = () => {
    this.Auth.getAllCountryList().subscribe((data) => {
      const { status, response } = data;
      if (status === 200 && response.length > 0) {
        this.countryDataList = response;
        const { name, id } = this.countryDataList[0];
        this.selectedCountry = name;
        this.getStatesData(id);
      }
    });
  };

  getStatesData(countyID) {
    this.Auth.getAllStatesList(countyID).subscribe((data) => {
      const { status, response } = data;
      if (status === 200 && response.length > 0) {
        this.stateDataList = response;
        const { name } = this.stateDataList[0];
        this.selectedState = name;
      }
    });
  }

  selectCountry = (event: Event) => {
    // console.log(event);
    this.selectedCountry = event;
    const { id } = this.countryDataList.filter((i) => i.name === event)[0];
    this.getStatesData(id);
  };

  selectState = (event: Event) => {
    this.selectedState = event;
  };

  validateForm = () => {
    // const validation = this.validate();

    // if (validation) {
    //   this.createCompany();
    // }
    this.markFormTouched(this.add_company_form);
    if (this.add_company_form.valid) {
      this.createCompany();
    }
  };

  validate = () => {
    let errCount = 0;
    this.setFormControl();
    const {
      cEmail,
      cFirstname,
      cLastname,
      cMspEmail,
      cPhone,
      companyName,
      domain,
    } = this.addCompany;

    if (cEmail.trim() === '') {
      this.setErrorMessageAndStatus('cEmail', true, 'error', 'Required Field');
      errCount++;
    } else if (!this.validateEmail(cEmail)) {
      // console.log(!this.validateEmail(cEmail));
      errCount++;
      this.setErrorMessageAndStatus(
        'cEmail',
        true,
        'error',
        'Input proper format (ex. email@domain.com) '
      );
    }

    if (cFirstname.trim() === '') {
      this.setErrorMessageAndStatus('cFirstname', true, 'error', 'Required Field');
      errCount++;
    } else if (cFirstname.trim().length > 30) {
      this.setErrorMessageAndStatus('cFirstname', true, 'error', 'Maximum Length 30');
      errCount++;
    }

    if (cLastname.trim() === '') {
      this.setErrorMessageAndStatus('cLastname', true, 'error', 'Required Field');
      errCount++;
    } else if (cLastname.trim().length > 30) {
      this.setErrorMessageAndStatus('cLastname', true, 'error', 'Maximum Length 30');
      errCount++;
    }

    if (cMspEmail.trim() === '' && this.showMspInputForm) {
      this.setErrorMessageAndStatus('cMspEmail', true, 'error', 'Required Field');
      errCount++;
    } else if (!this.validateEmail(cMspEmail) && this.showMspInputForm) {
      // console.log(!this.validateEmail(cMspEmail));
      errCount++;
      this.setErrorMessageAndStatus(
        'cMspEmail',
        true,
        'error',
        'Input proper format (ex. email@domain.com) '
      );
    }

    if (cPhone.trim() === '') {
      this.setErrorMessageAndStatus('cPhone', true, 'error', 'Required Field');
      //phone regex
      errCount++;
    }

    if (companyName.trim() === '') {
      this.setErrorMessageAndStatus('companyName', true, 'error', 'Required Field');
      errCount++;
    } else if (cLastname.trim().length > 30) {
      this.setErrorMessageAndStatus('companyName', true, 'error', 'Maximum Length 30');
      errCount++;
    }

    if (domain.trim() === '') {
      this.setErrorMessageAndStatus('domain', true, 'error', 'Required Field');
      errCount++;
    } else if (domain.trim().length > 30) {
      this.setErrorMessageAndStatus('domain', true, 'error', 'Maximum Length 30');
      errCount++;
    }

    return errCount === 0;
  };

  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const regexEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEx.test(String(email).toLowerCase());
  }

  setErrorMessageAndStatus = (propname, bool, style, message) => {
    this.formControl[propname].shouldShowMessage = bool;
    this.formControl[propname].style = style;
    this.formControl[propname].message = message;
  };

  createCompany() {
    const { loginType, company_id, company_name } = this.userInfo.getInfo();
    const { companyName, domain, address1, address2, city, zip, firstname, lastname, phone, email, mspemail } = this.add_company_form.value;
    const addCompanyData = {
      data: {
        user: {
          firstname: firstname,
          lastname: lastname,
          email: email,
          phone: phone,
        },
        company: {
          companyname: companyName,
          primary_email: email,
          domain: this.convertDomainName(domain),
          msp_email: this.isMspAllowed ? mspemail : "",
          msp_notification: this.isMspAllowed ? 1 : 0,
          web_view: this.isWebViewAllowed ? 1 : 0,
          tenant_admin: this.isTenantAdminEnabled ? 1 : 0,
          phone: phone,
          company_address: {
            address1: address1,
            address2: address2,
            city: city,
            zip: zip,
            country: this.getCountryId(this.selectedCountry),
            state: this.selectedState,
          },
        },
        parentCompanyName: company_name,
        parentCompanyId: company_id,
        isLoginIntegrator: loginType === 'integrator' ? 1 : 0,
      },
    };
    console.log('addCompanyData:', addCompanyData);
    if(this.companyBeingCreated){
      return ;
    }
    this.companyBeingCreated = true;
    this.Auth.addCompany(addCompanyData).subscribe((data) => {
      this.companyBeingCreated = false;

      if (data['status'] === 200) {
        console.log(data , " Response i have ");
        if(data.error){
          this.colorStatus = 'err-msg';
          this.notifier.notify('error', 'Error occured while creating company.'+data.error);
          return ;
        }
        this.addCompanyStatus = data.response[0].message;
        this.colorStatus = data.response[0].status === 'Error' ? 'err-msg' : 'success-msg';
        if (data.response[0].status === 'OK') {
          this.doneAddingCompany = true;
          this.setTimer = setTimeout(() => {
            this.refreshCompanyEvent.emit('refreshCompany');
            this.clearTimeout();
          }, 3000);
          this.notifier.notify('success', 'Company created' );
        } else {
          this.doneAddingCompany = false;
          this.notifier.show({
            type: 'warning',
            message: this.addCompanyStatus,
            id: 'THAT_NOTIFICATION_ID', // Again, this is optional
          });
        }
      }else{
        this.notifier.show({
          type: 'error',
          message: 'Something went wrong while creating company',
          id: 'THAT_NOTIFICATION_ID', // Again, this is optional
        });
      }
    });
  }

  getCountryId = (countryname) => {
    const { id } = this.countryDataList.filter((e) => e.name === countryname)[0];
    return id;
  };

  convertDomainName = (domain) => {
    const _domain = domain;
    if (_domain.indexOf('www.') !== -1) {
      return _domain.split('www.')[1];
    }
    return domain;
  };

  enableWebView($event) {
    this.isWebViewAllowed = !this.isWebViewAllowed;
  }

  enableMsp($event) {
    this.isMspAllowed = !this.isMspAllowed;
    this.showMspInputForm = this.isMspAllowed;
    if (this.showMspInputForm) {
      this.add_company_form.addControl('mspemail', this.fb.control(null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])));
      // console.log(this.add_company_form)
    } else {
      this.add_company_form.removeControl('mspemail');
      // console.log(this.add_company_form)
    }
  }

  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) { control.markAsTouched(); this.markFormTouched(control); }
      else { control.markAsTouched(); };
    });
  };

  enableTenantAdmin($event) {
    this.isTenantAdminEnabled = !this.isTenantAdminEnabled;
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  mustNotMatch = (controlName: string, matchingControlName: string, secondControlName: string, secondMatchingControlName: string) => {
    return (formGroup: FormGroup) => {
      const firstnameControl = formGroup.controls[controlName];
      const matchingFirstnameControl = formGroup.controls[matchingControlName];
      const domainControl = formGroup.controls[secondControlName];
      const matchingMSPDomainControl = formGroup.controls[secondMatchingControlName];
      let firstnameErrors = false;
      let domainErrors = false;

      // console.log(domainControl)

      if (matchingMSPDomainControl && matchingMSPDomainControl.errors && !matchingMSPDomainControl.errors.mustNotMatch) {
        domainErrors = true;
      }

      if (matchingFirstnameControl.errors && !matchingFirstnameControl.errors.mustNotMatch) {
        firstnameErrors = true;
      }
      if (firstnameErrors && domainErrors) {
        return;
      }

      if (!firstnameErrors) {
        if (firstnameControl.value === matchingFirstnameControl.value) {
          matchingFirstnameControl.setErrors({ mustNotMatch: true });
        } else {
          matchingFirstnameControl.setErrors(null);
        }
      }

      if (!domainErrors) {
        let mspDomain = matchingMSPDomainControl.value !== null ? matchingMSPDomainControl.value.split("@")[1] : "";
        if (domainControl.value === mspDomain) {
          matchingMSPDomainControl.setErrors({ mustNotMatch: true })
        } else {
          matchingMSPDomainControl.setErrors(null);
        }
      }

    };

  };

}
