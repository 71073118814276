import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshApiService {

  private _apiListners = new Subject<any>();

  refreshAPIListen(): Observable<any> {
    return this._apiListners.asObservable();
  }

  updateApiData(refreshNow: any) {
    console.log('this is the refresh service ' + refreshNow)
     this._apiListners.next(refreshNow);
  }

}
