<div class="row pt-2" id="printablediv">
  <div class="col-9">
    <h2>{{PopupTitle}}</h2>
  </div>
  <div class="col-3">
    <img src="../../assets/img/email_default.png" alt="" class="menu-icon float-right" (click)="sendData(1)">
  <img src="../../assets/img/pdf_default.png" alt="" class="menu-icon float-right"> <!--  (click)="printDiv('rt-panel')" onclick="window.print();" -->
    <!-- <img src="../../assets/img/search_default.png" alt="" class="menu-icon float-right" (click)="sendData(1)"> -->
  </div>
</div>
 