<div class="link-agent">
    <div class="link-agent__header">
        <div>
            {{ getModalTitle() }}
        </div>
        <div (click)="closeDialog()" class="link-agent__header-close-button">
            <img src="./assets/img/delete-btn.png" alt="" />
        </div>
    </div>

    <ng-container *ngIf="!errMessage">
        <div *ngIf="!showLinkAgent && !isAgentLinked" class="link-agent__body">
            <div class="link-agent__search">
                <div class="link-agent__icon">
                    <i class="fa fa-search"></i>
                </div>
                <input type="text" class="search-default" type="text" placeholder="" [(ngModel)]="searchKey" />
            </div>
            <div id="scroll-bar" style="overflow: auto; overflow-x: hidden; height: 280px;">
                <table class="table">
                    <thead>
                        <tr class="black">
                            <th scope="col">CUSTOMER</th>
                            <th scope="col" style="width: 50px;">SELECT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr scope="row" *ngFor="let item of modalList | mainMenuFilter: searchKey:'linkDialog'">
                            <td>
                                {{item.company_name}}
                            </td>
                            <td>
                                <div (click)="openLinkAgent(item)" class="link-agent__body-add-button">
                                    <img src="./assets/img/dialog_add_button.png" alt="" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    
        <div *ngIf="showLinkAgent && !isAgentLinked" class="link-agent__body link-agent__body-2">
            <div class="link-agent__image">
                <img *ngIf="!isLoading" src="../../../../assets/img/delete-icon.png" alt="" />
                <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
            </div>
            <div class="link-agent__text-button-container">
                <div *ngIf="!showAgentMessage" class="">
                    Link {{agentName}} to {{companyChildName}}?
                </div>
                <div *ngIf="showAgentMessage" class="">
                    {{agentMessage}}
                </div>
                <div *ngIf="!showAgentMessage" class="link-agent__button-container">
                    <div class="link-agent__button-item link-agent__button-item--yes" (click)="linkAgent()">
                        Yes, Link Them!
                    </div>
                    <div class="link-agent__button-item link-agent__button-item--cancel" (click)="closeLinkAgent()">
                        X Cancel
                    </div>
                </div>
            </div>
        </div>
    
        <div *ngIf="isAgentLinked" class="link-agent__body link-agent__body-2">
            <div class="link-agent__image">
                <img *ngIf="!isLoading" src="../../../../assets/img/delete-icon.png" alt="" />
                <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
            </div>
            <div class="link-agent__text-button-container">
                <div *ngIf="!showAgentMessage" class="">
                    Un-Link <strong>{{ agentName }}</strong> to <br />
                    Customer: <strong>{{ companyChildName }}</strong> ?
                </div>
                <div *ngIf="showAgentMessage" class="">
                    {{agentMessage}}
                </div>
                <div *ngIf="!showAgentMessage" class="link-agent__button-container">
                    <div class="link-agent__button-item link-agent__button-item--yes-unlink" (click)="unlinkAgent()">
                        Yes, Un-Link Them!
                    </div>
                    <div class="link-agent__button-item link-agent__button-item--cancel-unlink" (click)="closeDialog()">
                        X Cancel
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="errMessage">
        <div class="link-agent__body">
            <p class="text-danger text-center">{{ errMessage }}</p>
        </div>
    </ng-container>

</div>