import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupSendDataService {

  //This function passes the device params Id to the reboot video device function. 
  //This Listener service can be used to pass other data from parent panel to a popup panel

  private _popupSendDataListener= new Subject<any>();

  popupDataListen(): Observable<any> {
    return this._popupSendDataListener.asObservable();
  }

  sendPopupData(popupData: any) {
    console.log('this is the refresh service ' + popupData)
     this._popupSendDataListener.next(popupData);
  }
}
