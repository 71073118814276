<div class="dashboard-header-container">
  <div class="dashboard-header-container-child">
    <h5>{{ headerTitle }}</h5>
    <div>
      <button
        *ngIf="showUpdateCenter"
        (click)="openUpdateCenter()"
        class="update-center-btn"
        mat-raised-button
      >
        Update Center
      </button>
      <button mat-raised-button (click)="loginAsSuperAdmin()">{{ getLoginAsLabel() }}</button>
    </div>
  </div>
</div>
