import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayEmpty',
})
export class DisplayEmptyPipe implements PipeTransform {
  transform(value: any): any {
    if (value === null) {
      return '--';
    }

    if (value === 0) {
      return '--';
    }

    return value;
  }
}
