import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-collector-version-release-notes',
  templateUrl: './collector-version-release-notes.component.html',
  styleUrls: ['./collector-version-release-notes.component.scss'],
})
export class CollectorVersionReleaseNotesComponent implements OnInit {
  @Input() rNotes: any;
  @Output() closeReleaseNotesEvent: EventEmitter<any> = new EventEmitter<any>();

  showBtn: Boolean = false;
  swalIcon: String = 'success';
  swalMessage: String = 'Update Successful';
  releaseNotes: string;
  constructor(private Auth: AuthService) { }

  ngOnInit() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const company_id = decodedToken.company_id;
    if (environment.config.superAdminCompanyId === company_id) {
      this.showBtn = true;
    }

    this.releaseNotes = this.rNotes.release_notes;
  }

  closeReleaseNotesModal() {
    this.closeReleaseNotesEvent.emit('closeReleaseNotes');
  }

  updateReleaseNotes = () => {
    if (this.rNotes) {
      const { collector_version_id, version } = this.rNotes;
      this.Auth.updateCollecterReleaseNotes(
        collector_version_id,
        this.releaseNotes,
        version
      ).subscribe((data) => {
        if (data.message === 'Version not found') {
          this.swalIcon = 'warning';
          this.swalIcon = data.message;
        }
        this.closeReleaseNotesEvent.emit('getCollectorVersionList');
        this.closeReleaseNotesEvent.emit('closeReleaseNotes');
      });
    }
  };

  keyup = (keyValue) => {
    this.releaseNotes = keyValue;
  };
}
