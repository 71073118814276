<div class="collector-update-schedule">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Schedule Update( v{{ scheduleUpdateData.version }} )</h5>
        <button type="button" class="close" (click)="closeScheduleUpdateModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="email-content col-7">
            <p>All Admin users will receive an email informing them of the scheduled update.</p>
            <p>
              Optional: Please free to add any additional details or comments below, the text will
              be added to the email.
            </p>
            <textarea class="form-control" id="email-text" rows="5" placeholder="Email note:"
              (keyup)="onKeyEmailNote($event)" [(ngModel)]="emailNoteVal"></textarea>
            <br />
            <div class="row">
              <div class="col-6">
                <label for="email-add">Send test email</label>
                <input type="email" id="email-add" placeholder="Enter email" (keyup)="onKeyEmail($event)" />
                <button class="btn btn-primary ml-2" (click)="sendTestEmail()" [disabled]="testBtnDisabled">
                  TEST
                </button>
              </div>
              <div class="col-4">
                <label for="rNotes">Include release notes</label>
                <input type="checkbox" id="rNotes" (change)="includeReleaseNotes($event)" [checked]="checkBoxVal" />
              </div>
              <div class="col-2 text-right">
                <button class="btn btn-primary" (click)="saveNotes()">
                  SAVE
                </button>
              </div>
            </div>
            <div *ngIf="showEmailErrMsg" class="error-message">Error: {{ emailResponse }}</div>
            <div *ngIf="showSuccessMsg" class="success-message">Succes: {{ emailResponse }}</div>
            <div *ngIf="showNoteSuccessMsg" class="success-message">Succes: Saved Success!</div>
          </div>
          <div class="schedule-content col-5">
            <label for="schedule-date">Date / Time:</label>
            <input type="datetime-local" class="form-control" id="schedule-date" [(ngModel)]="dateVal" [min]="minDate | date:'yyyy-MM-ddTHH:mm'"/>
            <div class="col-12 text-right btn-ctn">
              <div>
                <div *ngIf="showDateErrMsg" class="error-message">Error: {{ dateMsg }}</div>
              </div>
              <button class="btn btn-success" (click)="updateCollectorSchedule()">Schedule</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>