<div class="collector-version-list">
  <app-collector-version-release-notes *ngIf="isOpenReleaseNotes" (closeReleaseNotesEvent)="ngOnChange($event)"
    [rNotes]="rNotes">
  </app-collector-version-release-notes>

  <app-collector-update-schedule *ngIf="isOpenScheduleUpdate" [scheduleUpdateData]="scheduleUpdateData"
    [systemType]="'windows'" (closeCollectorScheduleUpdateEvent)="ngOnChange($event)">
  </app-collector-update-schedule>

  <app-collector-list *ngIf="isOpenCollectorList" [collectorListData]="collectorListData"
    (collectorListEvent)="ngOnChange($event)">
  </app-collector-list>

  <div class="table-container">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">{{ dCollector }} Versions</th>
          <th scope="col">Release Date</th>
          <th scope="col">Size</th>
          <th scope="col">Release Notes</th>
          <th scope="col">Status</th>
          <th scope="col">Scheduled Date</th>
          <th scope="col">Installed</th>
          <th scope="col">Error</th>
          <th scope="col">Pending</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let c of collectorVersionList">
          <td><img src="{{ collectorImg }}" /> Version {{ c.version }}</td>
          <td>
            <span *ngIf="c.stamp">
              {{ c.stamp | displayDate }} (Local)<br>
              {{ c.stamp | date : 'MM-dd-yyyy hh:mm a' :'UTC' }} (UTC)
            </span>
          </td>
          <td>{{ c.file_size | displayEmpty }}</td>
          <td (click)="openReleaseNotes(c)">
            <span class="view-notes">
              View Notes
            </span>
          </td>
          <td>{{ c.update_status }}</td>
          <td>
            <span *ngIf="c.update_schedule">
              {{ c.update_schedule | displayDate }} (Local)<br>
              {{ c.update_schedule | date : 'MM-dd-yyyy hh:mm a' :'UTC' }} (UTC)
            </span>
          </td>
          <td (click)="openCollectorList(c.installed_count, c.version, 'Installed')">
            <span class="view-collectors">{{ c.installed_count | displayEmpty }}</span>
          </td>
          <td (click)="openCollectorList(c.c_error, c.version, 'Error')">
            <span class="view-collectors">{{ c.c_error | displayEmpty }}</span>
          </td>
          <td (click)="openCollectorList(c.c_pending, c.version, 'Pending')">
            <span class="view-collectors">{{ c.c_pending | displayEmpty }}</span>
          </td>
          <td *ngIf="currentUserInfo?.accesslevel=='ADMIN'">
            <button *ngIf="isShowUpdateCollectorBtn(c.update_status)" (click)="openScheduleUpdate(c)">
              {{ getUpdateScheduleBtnLabel(c.update_schedule) }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>