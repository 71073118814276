import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mainMenuFilter'
})
export class MainMenuFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, paramType: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = !searchText ? searchText : searchText.toLocaleLowerCase();

    return items.filter((e) => {
      return this.searchKeyPerColumn(e, searchText, paramType);
    });
  }

  searchKeyPerColumn = (items = {}, searchText, paramType) => {
    if (paramType === 'linkDialog') {
      return items['company_name'].toString().toLocaleLowerCase().includes(searchText);
    }
  };

}
