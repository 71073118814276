import apiServicePathBuilder from './apiServicePathBuilder';

export const environment = {
  production: true,
  dev_server: false,
  apiServicePathBuilder: apiServicePathBuilder({
    apiUrl: 'https://api.visibility.one',
  }),
  config: {
    tenantName: 'VisibilityOne',
    serverUrl: 'https://fe-krypton.visibility.one',
    mainUrl: 'https://dashboard.visibility.one',
    webhookUrl : null,
    showPoweredBy: false,
    twilioServiceId: "VA0984ed7bcdf80fb06fbdeb7b1f21c130",
    enable2FAFeature: false,
    domotzIntegration: false,
    isShowTeleportivity: false,
    superAdminCompanyId: 36,
    links: {
      terms: 'https://www.visibility.one/terms-of-service',
    },
    dynamicImage: {
      loginImage: '../assets/img/gbl_img/loginImage.png',
      dashboardLogo: '../assets/img/gbl_img/dashboardLogo.png',
      bgImage: '/assets/img/gbl_img/bgImage.jpg',
      loader: '../assets/img/gbl_img/loader.gif',
      collectorImg: '../assets/img/gbl_img/collector_icon.png',
      macCollector: '../assets/img/gbl_img/maccollector_icon.png',
      pluginImg: '../assets/img/gbl_img/plugin_icon.png',
      desktopPluginImg: '../assets/img/gbl_img/desktop_plugin_icon.png',
    },
    dynamicText: {
      dTerms: 'Visibilityone terms and conditions',
      dLoginTitle: 'global admin view of visibilityone',
      dashboardHeader: 'Super Admin Dashboard',
      dCollector: 'Collector',
      dPlugin: 'Plugin',
      desktopPlugin: 'Desktop Plugin',
    },
  },
};
