<div *ngIf="currentUserInfo?.accesslevel=='ADMIN'" class="info-integrator-container">
  <div class="info-integrator-child-container">
    <div>
      <h4>Integrator Group Accounts</h4>
    </div>

    <div class="info-integrator-group-name">Group Name: {{ siteGroupName }}</div>
    <div class="info-integrator-group-member">
      Group Members: <span *ngIf="companyMembersList.length === 0">None</span>
      <ul [ngClass]="{ 'info-integrator-ulist-ngclass': companyMembersList.length !== 0 }"
        class="info-integrator-ulist">
        <li *ngFor="let item of companyMembersList">
          <div class="info-integrator-li">
            <mat-icon (click)="onRemoveChild(item)">restore_from_trash</mat-icon>
            <span>{{ item.company_name }}</span>
          </div>
        </li>
      </ul>
    </div>

    <!-- <swal
      #deleteSwal
      text="Are you sure you want to delete this? {{ toBeDeletedCompany }}"
      icon="question"
      [showCancelButton]="true"
      [focusCancel]="true"
      (cancel)="deleteFile(false)"
      (confirm)="deleteFile(true)"
    >
    </swal> -->

    <div class="info-integrator-add-group" *ngIf="shouldShowIntegratorInfo">
      <!-- <div class="info-integrator-add-group"> -->
      <div class="info-integrator-add-group-title">Add Company To Group</div>
      <mat-form-field>
        <mat-select [(value)]="selected" (selectionChange)="setIfBtnWillShow($event)" placeholder="Groupname" multiple>
          <mat-option>
            <ngx-mat-select-search (ngModelChange)="filterMyOptions($event)" placeholderLabel="Find Company..."
              noEntriesFoundLabel="No Entries Found" [formControl]="siteDataCtrl">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let data of siteDataList" [value]="data.value">
            {{ data.company_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="shouldShowGroupMembersBtn" class="info-integrator-btn-container">
        <button mat-raised-button (click)="addGroupCompany()">Save</button>
        <button mat-raised-button color="primary" (click)="cancelChange()">Cancel</button>
      </div>
    </div>
  </div>
</div>