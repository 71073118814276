import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'displayDate',
})
export class DisplayDatePipe implements PipeTransform {
  transform(value: any): any {
    if (value === null) {
      return '--';
    }
    return moment(value)
      .utc()
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format('MM-DD-YYYY hh:mm A');
    // return new Date(value).toUTCString();
  }
}
