<div class="main-menu-container">
  <div class="main-menu-header-container">
    <a routerLink="/"><img [src]="dashboardLogo" alt="Title" alt="Visiblity One" /></a>
    <div class="main-menu-header-profile-logo">
      <img [src]="this.logo" alt="{{ topTitle }}" />
      <h5>{{ topTitle }}</h5>
    </div>
  </div>

  <div>
    <app-dashboard-header
      [headerTitle]="'Customers'"
      (mainMenuEvent)="onChangeEvent($event)"
    ></app-dashboard-header>

    <router-outlet></router-outlet>
  </div>

  <div class="main-menu-bottom-header" [@mainMenuPopup]="mainmenuState">
    <div class="main-menu-bottom-header-child">
      <div (click)="activeMenuSet('company')" [class.activeMenu]="activeMenu === 'company'">
        Customers
      </div>
      <div
        (click)="activeMenuSet('updatecenter')"
        [class.activeMenu]="activeMenu === 'updatecenter'"
      >
        Update Center
      </div>
      <div (click)="activeMenuSet('reports')" [class.activeMenu]="activeMenu === 'reports'">
        Reports
      </div>
      <div
        (click)="activeMenuSet(5)"
        (click)="membersPopupState = 'close'"
        (click)="Logout()"
      >
        Logout
      </div>
    </div>
  </div>
</div>

<!-- <div
  [class.fullscreen]="loginClass == 'fullscreen'"
  class="col float-right rt-panel"
  id="rt-panel"
  style="visibility: hidden;"
  [@scaleHideShow]="ScaleRouterOutletState"
>
  <router-outlet></router-outlet>
</div> -->
