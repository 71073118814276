import { Component, Inject, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss']
})
export class CompanyDialogComponent {

  loader: any = environment.config.dynamicImage.loader;
  isLoading: boolean = false;
  infoSiteEventEmitter: EventEmitter<any>;
  companyName: any;
  showSuccessMessage: boolean = false;
  beingDeleted: boolean = false;

  // removeCompany: Function;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CompanyDialogComponent>,
    private Auth: AuthService,
    private router: Router,

  ) {
    if (data) {
      const { infoSiteEventEmitter, company_name } = data;
      this.infoSiteEventEmitter = infoSiteEventEmitter;
      this.companyName = company_name;
      // this.removeCompany = data.removeCompany;


    }
  }

  closeDialog = () => {
    this.isLoading = false;
    this.dialogRef.close(true);
  };

  confirmDelete = () => {
    let company_name = this.data.company_name;
    let company_id = this.data.config.company_id;
    this.Auth.setCompanyBeingDeleted(company_id, company_name).subscribe((res) => {
      if (res.results) {
        this.deleteCompany(this.data.config);
        this.infoSiteEventEmitter.emit('refreshCompany');
      }
      else {
        return;
      }
    })
  };

  deleteCompany = (params) => {
    try {
      const { company_id } = params;
      this.isLoading = true;
      this.Auth.deleteCompany(company_id).subscribe((data) => {
        if (data['status'] == 200) {
          this.showSuccessMessage = true;
          this.router.navigate(['/']);
          this.infoSiteEventEmitter.emit('refreshCompany');
        }
      }, err => {
        console.log('deleteCompany: error', err)
      });
    } catch (error) {

    } finally {
      this.beingDeleted = true;
    }
  }
}
