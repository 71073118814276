import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import * as bytes from 'bytes';

@Pipe({
  name: 'documentFileSize',
})
export class DocumentFileSizePipe implements PipeTransform {
  transform(value: any): any {
    if (value === null || typeof value === 'undefined' || value === '' || value === 0) {
      return '--';
    }

    if (value >= 1048576 && value < 10485760) {
      return bytes(value, {
        unit: 'MB',
        unitSeparator: ' ',
      });
    } else if (value >= 10485760) {
      return bytes(value, {
        unit: 'GB',
        unitSeparator: ' ',
      });
    } else {
      return bytes(value, {
        unit: 'KB',
        unitSeparator: ' ',
      });
    }
  }
}
